import styled from 'styled-components'
import backGroundImage from '../../assets/hoomiz_band.png'


export const DashboardImg = styled('div')`
    background-image: url(${backGroundImage});
    background-size: repeat;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    `