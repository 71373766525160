import { ContractCreate } from "./ContractCreate";
import { ContractEdit } from "./ContractEdit";
import { ContractList } from "./ContractList";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

export const Contracts = {
    list: ContractList,
    create: ContractCreate,
    edit: ContractEdit,
    icon: HistoryEduIcon
};
