import { Box, Container, Typography } from "@mui/material";
import { ArrayInput, BooleanInput, DateInput, Edit, NumberInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";
import { PlaceAutoCompleteField } from "../../common/components/PlaceAutoCompleteField";
import { categoriesPrestation, contractTypes, days, gender, hours, minutes, PricingTypes, tristateBoolean, workExperienceRange } from "../../common/utils";

const transform = (data) => {
    const location = {
        type: "Point",
        coordinates: [data.lng, data.lat]
    }
    data.location = location
    delete data.id;
    delete data.lng;
    delete data.lat;
    delete data.providerApplicants;
    delete  data.createdAt;
    delete data.updatedAt;
    return data;
};

const validateForm = (values) => {

};

export const Status = [
    {
        id: "LISTED", name: "Listé",

    },
    {
        id: "ACCEPTED", name: "Accepté",
    },
    {
        id: "COMPLETED", name: "Terminé",
    },
    {
        id: "CANCELLED", name: "Annulé",
    },
    {
        id: "REFUSED", name: "Refusé",
    },
    {
        id: "DRAFT", name: "Brouillon",
    }
]

export const JobEdit = () => {

    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

        }}>
            <Edit title="Modifier une Demande" sx={{ width: "100%" }} transform={transform} 
            redirect='show'>
                <SimpleForm
                    validate={validateForm}
                    defaultValues={{
                        isEnabled: true,
                        isEmailVerified: true,

                    }}

                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"

                                fontWeight={"bold"}
                            >
                                Informations sur la demande
                            </Typography>


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientFullName" fullWidth={true} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="recipientGender" fullWidth={true}
                                choices={gender}

                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientAge" fullWidth={true} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientPhone" fullWidth={true} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientEmail" fullWidth={true} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <PlaceAutoCompleteField source="recipientAddress" fullWidth required
                                placeholder={"Adresse"}
                                city_key={"recipientCity"}
                                zipCode_key={"recipientZipCode"}
                                address_key="recipientAddress"
                                lat_key="lat"
                                lng_key="lng"
                                country_key="state"
                                place_key="placeId"
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientCity" fullWidth={true} />
                        </Box>
                        <Box flex={0.5} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="recipientZipCode" fullWidth={true} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobCategory" fullWidth={true}
                                choices={categoriesPrestation}
                                defaultValue="CONFORT" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobType" fullWidth={true}
                                choices={contractTypes}
                                defaultValue="RECURRENT" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <NumberInput source="jobPrice" fullWidth={true}
                                defaultValue={24.50} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobPriceType" fullWidth={true}
                                choices={PricingTypes}
                                defaultValue='TAUX_HORAIRE'
                            />
                        </Box>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <TextInput source="jobDescription" fullWidth={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobStatus" fullWidth={true}
                                choices={Status}
                                defaultValue={"DRAFT"}

                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="jobStartDate" fullWidth={true}
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="jobEndDate" fullWidth={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobStartHour" fullWidth={true}
                                choices={hours}
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="jobStartMinute" fullWidth={true}
                                choices={minutes}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                fontWeight={"bold"}
                            >
                                Genre du de l'auxiliaire
                            </Typography>


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <SelectInput source="providerGender" fullWidth={true} 
                            choices={
                            [
                                    { id: "MALE", name: "Homme" },
                                    { id: "FEMALE", name: "Femme" },
                                    { id: "ANY", name: "Indifférent" }
                            ]
                            }
                            />

                        </Box>
                    </Box>
                    
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                fontWeight={"bold"}

                            >
                                Personalité  de l'auxiliaire
                            </Typography>


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}

                        >
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectInput source="hasDinamycPersonality" 
                                 choices={
                                    tristateBoolean
                                 }
                                    fullWidth={true}  
                                    defaultValue={'ANY'}
  
                                />
                                <SelectInput source="likeToTakeInitiative" fullWidth={true}
                                    choices={
                                        tristateBoolean
                                    }
                                    defaultValue={'ANY'}

                                 />
                            </Box>
                            
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectInput source="isKind" fullWidth={true}
                                    choices={
                                        tristateBoolean
                                    }
                                    defaultValue={'ANY'}

                                 />
                                <SelectInput source="hasStrongTemperament" fullWidth={true} 
                                    choices={
                                        tristateBoolean
                                    }
                                    defaultValue={'ANY'}
                                />
                            </Box>
                           


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                            fontWeight={"bold"}
                            >
                                Services
                            </Typography>


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}

                        >
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <BooleanInput source="helpWithHousework" fullWidth={true} />
                                <BooleanInput source="helpWithLaundry" fullWidth={true} />
                                <BooleanInput source="helpWithGroceries" fullWidth={true} />
                            </Box>

                      
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <BooleanInput source="helpWithMeals" fullWidth={true} />
                                <BooleanInput source="helpWithWalkAndFunActivities" fullWidth={true} />
                                <BooleanInput source="helpWithToileting" fullWidth={true} />
                            </Box>
                            
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <BooleanInput source="companionship" fullWidth={true} />
                                <BooleanInput source="nightWatch" fullWidth={true} />
                                <BooleanInput source="dayWatch" fullWidth={true} />

                            </Box>
                           


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                fontWeight={"bold"}
                            >
                                Experience
                            </Typography>


                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <SelectInput source="workExperienceRange" fullWidth={true} choices={
                            workExperienceRange
                           }
                           allowEmpty={true}
                           />

                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                            fontWeight={"bold"}
                            >
                                Passages
                            </Typography>


                        </Box>
                    </Box>
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            
                            
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}

                        >
                           
                            <ArrayInput source="schedules" fullWidth>
                                
                                <SimpleFormIterator inline>
                                    <SelectInput source="day" fullWidth
                                        choices={days}
                                        required
                                    />
                                    <SelectInput source="startingHour" fullWidth
                                        choices={hours}
                                    />
                                    <SelectInput source="startingMinute" fullWidth
                                        choices={minutes}
                                        defaultValue="00"

                                    />
                                    <SelectInput source="endingHour" fullWidth
                                        choices={hours}
                                    />
                                    <SelectInput source="endingMinute" fullWidth
                                        choices={minutes}
                                        defaultValue="00"
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>

                </SimpleForm>
            </Edit>
        </Container>
    );
};