import * as React from 'react';
import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import styled from 'styled-components';

const EmailFieldLink = styled(Typography)`
    color: #37419A;
    text-decoration: none;
    cursor: pointer;
`;
const EmailField = () => {
    const record = useRecordContext();
    return record ? (
        <EmailFieldLink
            variant="p"
        >
            {record.email}
        </EmailFieldLink>
    ) : null;
}

export default EmailField;