import { Box, Container } from "@mui/material"
import { BooleanInput, DateInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin"

const transform = (data) => {
    if (!data.password) {
        delete data.password;

    }
    delete data.tokenDevice;
    delete data.password_confirmation;
    delete data.deleteRequired;
    const {id, createdAt, updatedAt,  ...rest } = data
    return rest
}

const validateForm = (values) => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = ['Le prénom est obligatoire']
    }
    if (!values.lastName) {
        errors.lastName = ['Le nom est obligatoire']
    }
    if (!values.email) {
        errors.email = ['L\'email est obligatoire']
    }
    if (!values.role) {
        errors.role = ['Le rôle est obligatoire']
    }
    if (!values.birthDate) {
        errors.birthDate = ['La date de naissance est obligatoire']
    }
    if (!values) {
        errors.global = ['Veuillez remplir tous les champs obligatoires']
    }
    if (!values.password) {
        errors.password = ['Le mot de passe est obligatoire']
    }
    if (!values.password_confirmation) {
        errors.password_confirmation = ['La confirmation du mot de passe est obligatoire']
    }
    if (values.password !== values.password_confirmation) {
        errors.password_confirmation = ['Les mots de passe ne correspondent pas']
    }
    return errors
}



export const UserEdit = () => {
    return (
        <Container style={
            {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }
        }>
            <Edit title="Modifier un utilisateur" sx={{ width: 600 }} 
            transform={transform}
            validateForm={validateForm}
            >
                <SimpleForm sx={{ maxWidth: 600 }}
>
                    <TextInput disabled label="Id" source="id"  fullWidth/>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="firstName" isRequired fullWidth />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="lastName" isRequired fullWidth />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput source="gender" isRequired fullWidth

                                choices={[{ id: "FEMALE", name: "Féminin" }, { id: "MALE", name: "Masculin" }]} />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <DateInput source="birthDate" isRequired fullWidth  />
                        </Box>
                        
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput type="email" source="email" isRequired fullWidth />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="mobilePhone" isRequired fullWidth />
                        </Box>
                    </Box>
                    <SelectInput source="role" choices={[
                        { id: 'admin', name: 'Administrateur' },
                        { id: 'userCareProvider', name: 'Auxiliaire de vie' },
                        { id: 'userCareRecipient', name: 'Client' },
                    ]} isRequired fullWidth />
                    <TextInput source="password" fullWidth type={"password"}/>
                    <TextInput source="password_confirmation" fullWidth type={"password"} />
                    <BooleanInput source="isEnabled" isRequired fullWidth />
                    <BooleanInput source="isEmailVerified" isRequired fullWidth />
            </SimpleForm>
        </Edit>
        </Container>
    )
}