import { Box, TextField, Typography,Button } from "@mui/material"
import { Container } from "@mui/system"
import React, { useEffect,useState } from "react"
import { PlaceAutoCompleteField } from "../../common/components/PlaceAutoCompleteField"
import { loadGMaps } from "../../utils/loadMaps"
import { formatUserFullName } from "../../utils/Stringhelps"
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrayInput, AutocompleteArrayInput, Edit, DateInput, NumberInput,useRecordContext, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, FunctionField ,DateTimeInput,NumberField, TextInput} from 'react-admin'
import { DeleteHmzButton, ValidateHmzButton } from '../../common/components/Button'
import { days } from "../../common/utils"
import moment from "moment"
import { diffTime, formatDateWithTime, getAgeFromDate, printEffectiveTime, signedDiffTimeReturnObject, timeOfIntervention } from "../../utils/DateFormat";
import { translateMonth } from "../../utils/DateFormat";
import { formatDate } from "../../utils/DateFormat";


const transform = (data) => {
    delete data.careProviderId
    delete data.careRecipientId
    delete data.monthNumber
    delete data.yearNumber
    // fonction qui parcoure la liste des data.presences et modifie interventionlength avec la fonction signedDiffTimeReturnObject
    data.presences.map((presence) => {
        presence.interventionLength = signedDiffTimeReturnObject( presence.checkOutDate,presence.checkInDate)
    })

    return {
        ...data,
    }
}

export const minutes = [
    { id: "0", name: "0" },
    { id: "15", name: "15" },
    { id: "30", name: "30" },
    { id: "45", name: "45" },
];
export const hours = [
    { id: "0", name: "0" },
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "13", name: "13" },
    { id: "14", name: "14" },
    { id: "15", name: "15" },
    { id: "16", name: "16" },
    { id: "17", name: "17" },
    { id: "18", name: "18" },
    { id: "19", name: "19" },
    { id: "20", name: "20" },
    { id: "21", name: "21" },
    { id: "22", name: "22" },
    { id: "23", name: "23" },
]

const validateForm = (values) => {
    const errors = {};
    errors.condition = {};
    for(let i = 0; i < values.presences.length; i++){
        console.log(values.presences[i].checkInDate, values.presences[i].checkOutDate)
        if(!values.presences[i].checkInDate){
            errors.presences = {};
            errors.presences[i] = {};
            errors.presences[i].checkInDate = "La date de début est obligatoire";
        }
        if(!values.presences[i].checkOutDate){
            errors.presences = {};
            errors.presences[i] = {};
            errors.presences[i].checkOutDate = "La date de fin est obligatoire";
        }
        if(new Date(values.presences[i].checkInDate) > new Date(values.presences[i].checkOutDate)){
            errors.presences = {};
            errors.presences[i] = {};
            errors.presences[i].checkInDate = "La date de début doit être antérieure à la date de fin";
            errors.presences[i].checkOutDate = "La date de fin doit être postérieure à la date de début";
        }
    }
    if(!values.status){
        errors.status = "Le statut est obligatoire";
    }
    if(values.recipientHasSigned === ""){
        errors.recipientHasSigned = "Le statut est obligatoire"
    }
    if(values.careProviderHasSigned === "" ){
        errors.careProviderHasSigned = "Le statut est obligatoire";
    }

    return errors;
}
const contactRole = [
    { id: 'referent', name: 'referent' },
    { id: 'aidant', name: 'aidant' },
    { id: 'professionnel', name: 'professionnel' },
];


export const PresenceSheetEdit = (props) => {
    const navigate = useNavigate();
    const timezone = "Europe/London"
    const formatWithTimezone = (date) => {
    if(date){
    const newDate = new Date(date)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return `${new Date(newDate).toISOString().slice(0, -1)}`;
    }
  };
  
  const parseWithTimezone = (value) => {
    const timezoneOffsetHours = parseInt(value.slice(-5, -3));
    const timezoneOffsetMinutes = parseInt(value.slice(-2));
    const timezoneOffset =
      timezoneOffsetHours * 60 + (timezoneOffsetHours >= 0 ? 1 : -1) * timezoneOffsetMinutes;
    const date = new Date(value.slice(0, -6));
    date.setTime(date.getTime() + timezoneOffset * 60 * 1000);
    return date;
  };
  const [interventionLength,setInterventionLength] = useState([])
  const [timeStamp,setTimeStamp] = useState(new Date());
  const statusList = [
    { id: 'pending', name: 'En attente' },
    { id: 'validated', name: 'Validée' },
    { id: 'dispute', name: 'En litige' },

    ];
    
   return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        }}
        >
<Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    width: '90%',
                    margin: 'auto',
                    marginTop: '20px',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Retour
                </Button>
            </Box>
            
            <Edit
                title={"Modifier l'intervention"}
                transform={transform}
                validateForm={validateForm}
                sx={{
                    width: '90%',
                    margin: 'auto',
                    marginBottom: '20px',
                    backgroundColor: '#fff',

                }}
            >
                 <Box
                    padding={2}
            >
                    <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <FunctionField
                                source="monthNumber"
                                render={record => `${translateMonth(record.monthNumber).toUpperCase()}`}
                                color={'primary'} fontSize="2rem"
                                marginRight={"30px"}

                            />
                            <FunctionField
                                source="yearNumber"
                                render={(record) => record.yearNumber}
                                color={'primary'} fontSize="2rem"
                            />
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                          
                           
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}
                            >
                                Indentité de l'auxiliaire
                            </Typography>
                        </Box>

                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="firstName"
                                    render={(record) => `${record.careProviderId.userId?.firstName} ${record.careProviderId.userId?.lastName}`}
                                    color={'primary'} fontSize="1rem"
                                />

                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="birthDate"
                                    render={(record) => formatDate(new Date(record.careProviderId.userId?.birthDate))}
                                    color={'primary'} fontSize="1rem"
                                />
                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="gender"
                                    render={(record) => {
                                        if (record.careProviderId.userId?.gender === "FEMALE") return "Femme"
                                        else return "Homme"
                                    }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="email"
                                    render={(record) => record.careProviderId.userId?.email}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="emaphoneNumberil"
                                    render={(record) => record.careProviderId.userId?.mobilePhone}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            paddingTop={2}

                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="name"
                                    render={(record) => record.careProviderId.address}
                                    color={'primary'} fontSize="1rem"


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="city"
                                    render={(record) => record.careProviderId.city}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="state"
                                    render={(record) => { return record.careProviderId.state }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="zip"
                                    render={(record) => record.careProviderId.zip}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="addressComplement"
                                    render={(record) => record.careProviderId.anotations}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}
                                marginTop={2}

                            >
                                Indentité du bénéficiaire
                            </Typography>
                            </Box>

                            <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="firstName"
                                    render={(record) => `${record.careRecipientId.firstName} ${record.careRecipientId.lastName}`}
                                    color={'primary'} fontSize="1rem"


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="birthDate"
                                    render={(record) => formatDate(new Date(record.careRecipientId.birthDate))}
                                    color={'primary'} fontSize="1rem"
                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="gender"
                                    render={(record) => {
                                        if (record.careRecipientId.gender === "FEMALE") return "Femme"
                                        else return "Homme"
                                    }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="email"
                                    render={(record) => record.careRecipientId.email}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="emaphoneNumberil"
                                    render={(record) => record.careRecipientId.phoneNumber}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            paddingTop={2}

                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="name"
                                    render={(record) => record.careRecipientId.address}
                                    color={'primary'} fontSize="1rem"


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="city"
                                    render={(record) => record.careRecipientId.city}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="state"
                                    render={(record) => record.careRecipientId.state}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="zip"
                                    render={(record) => record.careRecipientId.zip}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="addressComplement"
                                    render={(record) => record.careRecipientId.addressComplement}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        </Box>

                <SimpleForm
                    flex={1}
                    validate={validateForm}
                >
                        <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >Statut :</Typography>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} flex={1}>
                            <Box 
                            style={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:'50%'}}
                                flex={1}
                                marginRight={"30px"}
                            >
                            <SelectInput source="status" fullwidth choices={statusList} style={{width:'100%'}}/>
                            </Box>
                        </Box>
                        
                        <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >Signée par :</Typography>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} flex={1}>
                            <Box 
                            style={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:'50%'}}
                                flex={1}
                                marginRight={"30px"}
                            >
                            <SelectInput source="recipientHasSigned" choices={[{id:true,name:"oui"},{id:false,name:"non"}]} style={{width:'100%'}}/>
                            </Box>
                            <Box 
                            style={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:'50%'}}
                            flex={1}

                            >
                            <SelectInput source="careProviderHasSigned" choices={[{id:true,name:"oui"},{id:false,name:"non"}]} style={{width:'100%'}}/>
                            </Box>
                        </Box>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Présences

                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}
                                flex={1}
                            >
                                <ArrayInput source='presences' >
                                    <SimpleFormIterator inline
                                        removeButton={<DeleteHmzButton label="Supprimer" />}
                                        addButton={<ValidateHmzButton label="Ajouter" />}
                                                                                
                                    >
                                    
                                        <DateTimeInput  source="checkInDate" format={(date) => formatWithTimezone(date)} parse={parseWithTimezone} 
                                           onChange={(e) => {
                                            setTimeStamp(e.target.value)
                                            const index = e.target.name.replace('.checkInDate', '')
                                            const checkInDate = e.target.value
                                            const newInterventionLength = interventionLength;
                                            newInterventionLength[index] = {...newInterventionLength[index],chekckInDate:checkInDate}
                                            setInterventionLength(newInterventionLength)
                                            }
                                            }
                                            style={{width:'25%'}}
                                            
                                        />
                                        <DateTimeInput  source="checkOutDate" format={(date) => formatWithTimezone(date)} parse={parseWithTimezone}
                                            onChange={(e) => {
                                            const index = e.target.name.replace('.checkOutDate', '')
                                            const checkOutDate = e.target.value
                                            const newInterventionLength = interventionLength;
                                            setTimeStamp(e.target.value)
                                            newInterventionLength[index] = {...newInterventionLength[index],chekckOutDate:checkOutDate}
                                            setInterventionLength(newInterventionLength);
                                            
                                            }
                                            }
                                            style={{width:'25%'}}

                                        
                                        />
                                        <TextInput source="comment" style={{width:'30%'}}/>
                                        <Typography fullWidth variant="p" gutterBottom component="div"
                                        style={
                                        {
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                        marginTop: '1rem',
                                        }
                                }
                            >
                                Durée :

                            </Typography>
                                       <FunctionField fullWidth
                                            render={(record,index) => {
                                                const nexIndex = index.replace("presences.", "").replace("]", "")

                                                if(interventionLength[index]?.chekckInDate && interventionLength[index]?.chekckOutDate){
                                                    return  <Typography variant="p" gutterBottom component="div"
                                                    style={
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem',
                                                            color: '#3f51b5',
                                                            marginBottom: '0.8rem',
                                                            marginTop: '0.8rem',
                    
                                                        }
                                                    }
                                                >
                                                {`${signedDiffTimeReturnObject(interventionLength[index]?.chekckOutDate,interventionLength[index]?.chekckInDate).hours} h ${signedDiffTimeReturnObject(interventionLength[index]?.chekckOutDate,interventionLength[index]?.chekckInDate).minutes} min`}
                                                </Typography>
                                                }else if(interventionLength[index]?.chekckInDate ){
                                                    return   <Typography variant="p" gutterBottom component="div"
                                                    style={
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem',
                                                            color: '#3f51b5',
                                                            marginBottom: '0.8rem',
                                                            marginTop: '0.8rem',
                    
                                                        }
                                                    }
                                                >
                                                {`${signedDiffTimeReturnObject(formatWithTimezone(record.presences[nexIndex]?.checkOutDate),interventionLength[index]?.chekckInDate).hours} h ${signedDiffTimeReturnObject(formatWithTimezone(record.presences[nexIndex]?.checkOutDate),interventionLength[index]?.chekckInDate).minutes} min`}
                                                </Typography>
                                                }else if(interventionLength[index]?.chekckOutDate){
                                                    return <Typography variant="p" gutterBottom component="div"
                                                    style={
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem',
                                                            color: '#3f51b5',
                                                            marginBottom: '0.8rem',
                                                            marginTop: '0.8rem',
                    
                                                        }
                                                    }
                                                >{`${signedDiffTimeReturnObject(interventionLength[index]?.chekckOutDate,formatWithTimezone(record.presences[nexIndex]?.checkInDate)).hours} h ${signedDiffTimeReturnObject(interventionLength[index]?.chekckOutDate,formatWithTimezone(record.presences[nexIndex]?.checkInDate)).minutes} min`}</Typography>
                                                }
                                                else
                                                {
                                                    return <Typography variant="p" gutterBottom component="div"
                                                    style={
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem',
                                                            color: '#3f51b5',
                                                            marginBottom: '0.8rem',
                                                            marginTop: '0.8rem',
                    
                                                        }
                                                    }
                                                >{`${signedDiffTimeReturnObject(record.presences[nexIndex]?.checkOutDate,record.presences[nexIndex]?.checkInDate).hours} h ${signedDiffTimeReturnObject(record.presences[nexIndex]?.checkOutDate,record.presences[nexIndex]?.checkInDate).minutes} min`}</Typography>
                                                }
                                                }}
                                        />
                                    </SimpleFormIterator>

                                </ArrayInput>
                            </Box>
                        </Box>


                    </Box>
                
                </SimpleForm>
            </Edit>
        </Container>
    )
}