import { Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { ArrayInput, AutocompleteArrayInput, Edit, DateInput, NumberInput, ReferenceArrayInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'
import { DeleteHmzButton, ValidateHmzButton } from '../../common/components/Button'
import { PlaceAutoCompleteField } from '../../common/components/PlaceAutoCompleteField'
import { formatUserFullName } from '../../utils/Stringhelps'


const categories = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    }, {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }]

const validateForm = (values) => {
    const errors = {};
    errors.condition = {};
    if (!values.firstName) {
        errors.firstName = ['Le nom est obligatoire'];
    }
    if (!values.lastName) {
        errors.lastName = ['Le prénom est obligatoire'];
    }

    if (!values.phoneNumber) {
        errors.phoneNumber = ['Le numéro de téléphone est obligatoire'];
    }

    if (!values.birthDate) {
        errors.birthDate = ['La date de naissance est obligatoire'];
    }

    if (!values.rating) {
        errors.rating = ['La note est obligatoire'];
    }

    if (!values.category) {
        errors.category = ['La catégorie est obligatoire'];
    }

    if (!values.address) {
        errors.address = ['L\'adresse est obligatoire'];
    }

    if (!values.condition.mobility) {
        errors.condition.mobility = ['La latitude est obligatoire'];
    }


    return errors;
}


const transform = (data) => {
    const location = {
        type: "Point",
        coordinates: [data.lng, data.lat]
    }
    data.location = location;
    delete data.lat;
    delete data.lng;
    delete data.place_id;
    return data;
}

const PathologieTable = [
    { id: 'Alzheimer', name: 'Alzheimer' },
    { id: 'Parkinson', name: 'Parkinson' },
    { id: 'Démence', name: 'Démence' },
    { id: 'Arthrose', name: 'Arthrose' },
    { id: 'Diabète', name: 'Diabète' },
    { id: 'Asthme', name: 'Asthme' },
    { id: 'Problème cardiaque', name: 'Problème cardiaque' },
    { id: 'Tétraplégique', name: 'Tétraplégique' },
    { id: 'Hémiplégie', name: 'Hémiplégie' },

];

const contactRole = [
    { id: 'referent', name: 'referent' },
    { id: 'aidant', name: 'aidant' },
    { id: 'professionnel', name: 'professionnel' },
];


export const CareRecipientEdit = () => {
    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

        }
        }
        >
            <Edit
                title={"Modifier le profil d'un Beneficiaire"}
                transform={transform}
                validateForm={validateForm}
                defaultValue={(record) => { console.log(record) }}
                sx={{ width: '100%' }}
            > 
                <SimpleForm
                    validate={validateForm}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >

                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >
                            <Box flex={0.5} mr={{ xs: 0, sm: '1em' }}>
                                <Typography variant="p" gutterBottom component="div"
                                    style={
                                        {
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                            color: '#000000',
                                            marginBottom: '1rem',
                                        }
                                    }
                                >

                                    Identité du bénéficiaire
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Box flex={1}>
                                        <TextInput source="lastName" isRequired fullWidth />
                                    </Box>
                                    <Box flex={1} sx={{ paddingLeft: '1rem' }}>
                                        <TextInput source="firstName" isRequired fullWidth />
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Box flex={1}>
                                        <DateInput source="birthDate" isRequired fullWidth />
                                    </Box>
                                    <Box flex={1} sx={{ paddingLeft: '1rem', }}>
                                        <SelectInput source="gender" isRequired fullWidth

                                            choices={[{ id: "FEMALE", name: "Féminin" }, { id: "MALE", name: "Masculin" }]} />

                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                                        <PlaceAutoCompleteField source="address"

                                            city_key="city"
                                            zipCode_key="zip"
                                            country_key="state"
                                            address_key="address"
                                            lat_key="lat"
                                            lng_key="lng"
                                            place_key="placeId"
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Box flex={1}>
                                        <TextInput source="zip" isRequired fullWidth />
                                    </Box>
                                    <Box flex={1} sx={{ paddingLeft: '1rem', }}>
                                        <TextInput source="city" isRequired fullWidth />
                                    </Box>
                                    <Box flex={1} sx={{ paddingLeft: '1rem', }}>
                                        <TextInput source="state" isRequired fullWidth />
                                    </Box>

                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                                    <TextInput source="addressComplement"
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Box flex={1}>
                                        <TextInput source="email" fullWidth />
                                    </Box>
                                    <Box flex={1} sx={{ paddingLeft: '1rem', }}>
                                        <TextInput source="phoneNumber" isRequired fullWidth />
                                    </Box>

                                </Box>
                            </Box>
                            <Box flex={0.5} mr={{ xs: 0, sm: '1em' }} sx={{}}>
                                <Typography variant="p" gutterBottom component="div"
                                    style={
                                        {
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                            color: '#000000',
                                            marginBottom: '1rem',
                                        }
                                    }
                                >
                                    Bien être du bénéficiaire
                                </Typography>

                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                                    <SelectInput source="condition.mobility" isRequired fullWidth

                                        choices={[
                                            { id: "ALT", name: "Alité" },
                                            { id: "NALT", name: "Non alité" },
                                        ]} />

                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Box flex={1}>
                                        <SelectArrayInput source="condition.particularConditions" fullWidth
                                            onCreate={() => {
                                                const newTagName = prompt('Entrer une pathologie');
                                                const newTag = { id: newTagName.toLowerCase(), name: newTagName };
                                                PathologieTable.push(newTag);
                                                return newTag;
                                            }}

                                            choices={PathologieTable} />

                                    </Box>


                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <SelectInput source="category" isRequired fullWidth

                                        choices={categories}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <TextInput source="condition.specialIndications" fullWidth />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant="p" gutterBottom component="div"
                                        style={
                                            {
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                                color: '#000000',
                                                marginBottom: '1rem',
                                            }
                                        }
                                    >
                                        Application Hoomiz
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                    <Box flex={1}>

                                        <ReferenceArrayInput
                                            label="users"
                                            reference="users"
                                            source="users"
                                            filter={{ role: "userCareRecipient" }}
                                        >
                                            <AutocompleteArrayInput optionText={(record) => `${formatUserFullName(record).toUpperCase()}`} />
                                        </ReferenceArrayInput>

                                    </Box>


                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                    <Box flex={1}>
                                        <NumberInput source="rating" fullWidth isRequired
                                            max={5}
                                            min={1}
                                        />
                                    </Box>
                                </Box>


                            </Box>

                        </Box>

                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                            <Typography variant="p" gutterBottom component="div"
                                style={
                                    {
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                    }
                                }
                            >

                                Contacts associés au bénéficiaire
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <ArrayInput source='emergencyContacts'>
                                    <SimpleFormIterator inline
                                        removeButton={<DeleteHmzButton label="Supprimer" />}
                                        addButton={<ValidateHmzButton label="Ajouer" />}
                                    >
                                        <TextInput source="lastName" />
                                        <TextInput source="firstName" />
                                        <TextInput source="phoneNumber" />
                                        <TextInput source="email" />
                                        <TextInput source="relationship" />
                                        <SelectInput source="role" isRequired
                                            onCreate={() => {
                                                const newTagName = prompt('Entrer un role');
                                                const newTag = { id: newTagName.toLowerCase(), name: newTagName };
                                                contactRole.push(newTag);
                                                return newTag;
                                            }}

                                            choices={contactRole}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>

                    </Box>
                </SimpleForm>
            </Edit>

        </Container>
    )
}

