import { useRecordContext } from "react-admin";
import TagsList from "../../common/components/TagsList";

const TagsField = () => {
    const record = useRecordContext();
    return (
        <span>
            <TagsList table={record.zipCodes} />
        </span>
    )
}

export default TagsField