import { CareProviderList } from "./CareProviderList";
import { CareProviderCreate } from "./CareProviderCreate";
import { CareProviderEdit } from "./CareProviderEdit";
import { CareProvidersShow } from "./CareProvidersShow";
import FavoriteIcon from '@mui/icons-material/Favorite';
export const CareProviders = {
    list: CareProviderList,
    create: CareProviderCreate,
    show: CareProvidersShow,
    edit: CareProviderEdit,
    icon: FavoriteIcon

};

