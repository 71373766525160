import { Box, Typography } from "@mui/material"
import { Container } from "@mui/system"
import React, { useEffect } from "react"
import { AutocompleteInput, Create, DateTimeInput, NumberInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, useDataProvider } from "react-admin"
import { PlaceAutoCompleteField } from "../../common/components/PlaceAutoCompleteField"
import { loadGMaps } from "../../utils/loadMaps"
import { formatUserFullName } from "../../utils/Stringhelps"
import { useFormContext } from 'react-hook-form';

const categories = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    },
    {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }]
const PricingTypes = [
    {
        id: "FORFAIT", name: "Forfait",

    }, {
        id: "TAUX_HORAIRE", name: "Taux horaire",
    }]

const Status = [
    {
        id: "pending", name: "En attente",

    }
    , {
        id: "inProgress", name: "En cours",
    },
    {
        id: "completed", name: "Terminée",
    },
    {
        id: "cancelled", name: "Annulée",
    },
]

const tasks = [
    {
        id: "laundryCare", name: "Entretien du linge",

    },
    {
        id: "helpWithShopping", name: "Aide aux courses",
    },
    {
        id: "helpGettingUp", name: "Aide au lever",
    },
    {
        id: "helpWithMeals", name: "Aide aux repas",
    },
    {
        id: "toiletAid", name: "Aide aux toilettes",
    },
    {
        id: "bedTimeHelp", name: "Aide au coucher",
    }
]

const validateForm = (values) => {
    //const errors = {};
}




export const InterventionCreate = () => {
    const dataProvider = useDataProvider();
    const [careRecipient, setCareRecipient] = React.useState(null);

    const transform = (data) => {
        data.careProvider = data.careProvider.id;
        data.careRecipient = data.careRecipient.id;
        return {
            ...data,
        }
    }
    useEffect(
        () => {
            loadGMaps()
        },
        []
    )

    const InputManager = ({ careRecipient }) => {
        const { setValue } = useFormContext();
        if (careRecipient) {
            setValue("address", careRecipient.address)
            setValue("zip", careRecipient.zip)
            setValue("city", careRecipient.city)
            setValue("state", careRecipient.state)
            setValue("addressComplement", careRecipient.addressComplement)
            setValue("location", careRecipient.location)

        }
        return (<></>)
    }

    const handleRecipentChange = async (value) => {
        const { data } = await dataProvider.getOne("care-recipient-profiles", { id: value })
        setCareRecipient(data);
    }
    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        }}
        >
            <Create
                title={"Creer une intervention"}
                transform={transform}
                validateForm={validateForm}
                sx={{ width: '80%' }}

            >
                <SimpleForm
                    validate={validateForm}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <InputManager careRecipient={careRecipient} />
                        <Typography variant="p" gutterBottom component="div"
                            style={
                                {
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                    color: '#000000',
                                    marginBottom: '1rem',
                                }
                            }
                        >

                            Informations générales
                        </Typography>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >

                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectInput source="category" fullWidth required
                                    choices={categories}
                                />
                            </Box>


                        </Box>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <DateTimeInput source="startingDate" fullWidth required />
                            </Box>


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <DateTimeInput source="endingDate" fullWidth required />
                            </Box>


                        </Box>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <NumberInput source="price" fullWidth required />
                            </Box>


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectInput source="interventionType" fullWidth required
                                    choices={PricingTypes}
                                />
                            </Box>

                        </Box>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <ReferenceInput source="careRecipient.id" fullWidth
                                    reference="care-recipient-profiles"


                                >
                                    <AutocompleteInput
                                        optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
                                        key={record => record.id}
                                        onChange={handleRecipentChange}
                                    />

                                </ReferenceInput>
                            </Box>


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <ReferenceInput source="careProvider.id" fullWidth
                                    reference="care-provider-profiles"

                                >
                                    <AutocompleteInput
                                        optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
                                        key={record => record.id}

                                    />

                                </ReferenceInput>
                            </Box>




                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectInput source="status" fullWidth required
                                    choices={Status}
                                />
                            </Box>


                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <SelectArrayInput source="tasks" fullWidth
                                    choices={tasks}
                                />
                            </Box>


                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                        >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <PlaceAutoCompleteField source="address" fullWidth required 
                                    city_key="city"
                                    zipCode_key="zip"
                                    country_key="state"
                                    address_key="address"
                                    lat_key="lat"
                                    lng_key="lng"
                                    place_key="placeId"
                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput source="zip" fullWidth required />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput source="city" fullWidth required />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput source="state" fullWidth required />
                            </Box>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="addressComplement" fullWidth />
                        </Box>
                    </Box>
                </SimpleForm>
            </Create>
        </Container>
    )
}