import { Box, Chip, Typography, Button } from "@mui/material";

export const capitalizeWord = (str) => {
    if(!str) return '';
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
}


export const formatUserFullName = (user) => {
    if (!user) {
        return '';
    }
    const firstName = user.firstName ? user.firstName : '';
    const lastName = user.lastName ? user.lastName : '';
    return `${lastName.toUpperCase()} ${capitalizeWord(firstName)}`;
}

export const ratingToString = (rating) => {
    switch(rating){
        case "happy":
            return `😀`;
        case "neutral":
            return `😐`;
        case "sad":
            return `🙁`;


    }
}

export const translateStatus = (status) => {
    switch(status){
        case "pending":
            return <Chip label="En attente" color="primary" style={{fontSize:30, padding:20}} />;
        case "validated":
            return <Chip label="Validé" color="success" style={{fontSize:30, padding:20}} />;
        case "dispute":
            return <Chip label="En litige" color="warning" style={{fontSize:30, padding:20}} />;
    }
}

export const translateStatusByText = (status) => {
    switch(status){
        case "pending":
            return "En attente";
        case "validated":
            return "Validé";
        case "dispute":
            return "En litige";
    }
}