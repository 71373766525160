import { TableRow } from '@mui/material'
import React from 'react'
import { CreateButton, Datagrid, FilterButton, FunctionField, List, SearchInput, SortButton, TextField, TopToolbar } from 'react-admin'
import StarsField from '../../common/components/StartsField'
import TableCellCustom from '../../common/components/TableCellCustom'
import { CustomeTableHead } from '../../common/style/style'
import { getAgeFromDate } from '../../utils/DateFormat'
import RecipientField from './RecipientField'
import { UserListField } from './UserListField'


const DatagridHeader = () => {
    return (
        <CustomeTableHead>
            <TableRow >
                <TableCellCustom />
                <TableCellCustom name="Code client" />
                <TableCellCustom name="Beneficiaire" />
                <TableCellCustom name="Age" />
                <TableCellCustom name="category" />
                <TableCellCustom name="Adresse" />
                <TableCellCustom name="code postal" />
                <TableCellCustom name="Ville" />
                <TableCellCustom name="Utilisateurs" />
                <TableCellCustom name="Note" />
            </TableRow>
        </CustomeTableHead>
    )
} 

const CareRecipientProfileFilters = [
    <SearchInput source="q" alwaysOn />,
];
const ListActions = () => {
    return (
        <TopToolbar sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterButton />
            <SortButton fields={['createdAt', 'city', 'zip','rating',"firstName","lastName"]} />
            <CreateButton />
        </TopToolbar>
    )
}
export const CareRecipientList = () => {
    return (
        <List

            exporter={false}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={CareRecipientProfileFilters}
            actions={<ListActions />}
        >
            <Datagrid
                rowClick="show"
                header={<DatagridHeader />}
            >
                <TextField source="recipientCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <RecipientField />
                <FunctionField source="birthDate" render={record => getAgeFromDate(record.birthDate)+ " ans"} color={'#37419A'} />
                <TextField source="category" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="address" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="zip" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="city" color={'#37419A'} style={{ fontWeight: 500 }} />
                <UserListField source={"users"} />
                <StarsField ratingKey={"rating"} source="rating" />
                </Datagrid>
            </List>
    )
}
