import { useEffect, useState } from "react"
import { Box } from "@mui/system"
import {  Rating, Typography } from "@mui/material"
import {  FunctionField, Show, Tab, TabbedShowLayout, Title, useRecordContext } from "react-admin"
import { useMutation } from 'react-query';
import { useDataProvider } from 'react-admin';
import { API_URL } from "../../utils/constants"
import ApiConstants from "../../api/ApiConstants"




export const NumbersOfInterventions = ({careRecipientId,careProviderId,month,years}) => {
const record = useRecordContext()
const dataProvider = useDataProvider();
const [interventionsPendingLength, setInterventionsPendingLength] = useState(null);
const [interventionsDoneLength, setInterventionsDoneLength] = useState(null);
const [interventionInProgressLength, setInterventionInProgressLength] = useState(null);
const [interventionsCancelledLength, setinterventionsCancelledLength] = useState(null);

console.log(record)
const { mutate: getStatusInterventions } = useMutation(

    () => dataProvider.getOnApi(API_URL +  ApiConstants.GET_NUMBERS_STATUS_INTERVENTION , {careRecipientId:record.careRecipientId.id,careProviderId:record.careProviderId.id,monthNumber:record.monthNumber, yearNumber:record.yearNumber} ),
    {
        onSuccess: (data) => {
            setInterventionsPendingLength(data.data.interventionPending)
            setInterventionInProgressLength(data.data.interventionInProgress)
            setInterventionsDoneLength(data.data.interventionsCompleted)
            setinterventionsCancelledLength(data.data.interventionCancelled)
        },
        onError: (error) => {
            console.log(error);
        },
    }
);
useEffect(() => {
    getStatusInterventions()
},[record]);

return(
    <Box marginTop={2} marginBottom={2}>
    <Box
        flex={1}
        mr={{ xs: 0, sm: '1em' }}
    >
        <Typography variant="h6" gutterBottom component="div"
            color="#3f51b5"
            fontWeight={600}

        >
            Progressions de la feuille de présence
        </Typography>
    </Box>
    <Box
    
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >

                      
                        <Box
                            mr={{ xs: 10, sm: '5em' }}
                        >
                            <Typography variant="h" color={'primary'} fontSize="1.5rem" marginRight={"10px"}   >
                            En attentes :
                            </Typography>
                            <Typography
                                color={'primary'}
                                fontSize="1.5rem"
                            >
                            {interventionsPendingLength}
                            </Typography>
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '5em' }}
                        >
                            <Typography variant="h" color={'#FFC627'} fontWeigth={600} fontSize="1.5rem" marginRight={"10px"}   >
                            En cours :
                            </Typography>
                            <Typography
                                color={'#FFC627'}
                                fontSize="1.5rem"
                            >
                            {interventionInProgressLength}
                            </Typography>
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '5em' }}
                        >
                            <Typography variant="h" color={'green'} fontSize="1.5rem" marginRight={"10px"}   >
                            Complétées :
                            </Typography>
                            <Typography
                                color={'green'}
                                fontSize="1.5rem"
                            >
                            {interventionsDoneLength}
                            </Typography>
                        
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '5em' }}
                        >
                        <Typography variant="h" color={'red'} fontSize="1.5rem" marginRight={"10px"}   >
                            Anulées :
                            </Typography>
                            <Typography
                                color={'red'}
                                fontSize="1.5rem"
                            >
                            {interventionsCancelledLength}
                        </Typography>
                        </Box>
                        </Box>
    </Box>
)
}