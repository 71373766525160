import { Box, Chip, Typography, Button } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react"
import { Show, TextField, DateField, FunctionField, useRecordContext } from 'react-admin';
import { LineMarkedMap } from "../../common/components/MapComp";
import { diffTime, formatDateWithTime, getAgeFromDate, printEffectiveTime, signedDiffTime, timeOfIntervention } from "../../utils/DateFormat";
import { haversineDistance, locationToLatLng } from "../../utils/loadMaps";
import { formatUserFullName, ratingToString } from "../../utils/Stringhelps";
import { NotificationField } from "./notificationField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { CheckField } from "./checkField";


const InterventionJourney = (record) => {
    const { location } = record.record;
    const { careRecipient } = record.record;
    const { careProvider } = record.record;



    return (
        <Box>

            <LineMarkedMap
                origin={{ ...locationToLatLng(location), text: formatUserFullName(careRecipient) }}
                destination={{ ...locationToLatLng(careProvider.location), text: formatUserFullName(careProvider.userId) }}
            />

        </Box>
    );
}

const NotificationIntervention = (record) => {
    const { status, id, startingDate, endingDate } = record.record;
    const [actionNotification, setActionNotification] = useState(`${status === 'inProgress' ? "Pensez à valider votre départ !" : "Pensez à valider votre arrivée !"}`);
    const [actionCheck, setActionCheck] = useState(record);
    const [openNotify, setOpenNotify] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);
    const [hours, setHours] = useState(status === "pending" ? new Date(startingDate).getUTCHours() : new Date(endingDate).getUTCHours());
    const [minutes, setMinutes] = useState(status === "pending" ? new Date(startingDate).getUTCMinutes() : new Date(endingDate).getUTCMinutes());



    const notifyCareProvider = () => {
        setOpenNotify(true)
    }
    const checkProvider = () => {
        setHours(status === "pending" ? new Date(startingDate).getUTCHours() : new Date(endingDate).getUTCHours())
        setMinutes(status === "pending" ? new Date(startingDate).getUTCMinutes() : new Date(endingDate).getUTCMinutes())
        setActionCheck(record)
        setOpenCheck(true)
    }

    return (
        (status === "inProgress" || status === "pending") && <Box
            sx={{
                display: 'flex',
                flex: 1,
                justifyContent: "center",
                padding: 3
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: "center",
                    padding: 3
                }}
            />
            {(status != "completed" || status != "cancelled") && <Button
                sx={{
                    color: 'white',
                    marginRight: 3
                }
                }
                variant='contained'
                onClick={checkProvider}
            > {`${status === "pending" ? "Pointer l'arrivée de l'auxiliaire" : "Pointer le départ de l'auxiliaire"}`}</Button>}
            <CheckField open={openCheck} visible={setOpenCheck} action={actionCheck} setAction={setActionCheck} hoursValue={hours} setHours={setHours} minutesValue={minutes} setMinutes={setMinutes} />
            <Button
                sx={{
                    color: 'white',
                    marginLeft: 3

                }
                }
                variant='contained'
                onClick={notifyCareProvider}
            > Notifier l'intervenant</Button>
            <NotificationField open={openNotify} visible={setOpenNotify} action={actionNotification} setAction={setActionNotification} id={id} />
        </Box>


    )



}

const InterventionDonecard = (record) => {
    const { status } = record.record;



    return (
        status === 'inProgress' || status === 'completed' ?
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                    borderTop: "50px solid #e8e8e8"

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        backgroundColor: 'white',
                        padding: '1rem'


                    }}
                >

                    <Box

                        flex={1}
                        mr={{ xs: 0, sm: '1em' }}

                    >
                        <Box>
                            <FunctionField

                                color={'primary'}
                                fontSize="0.8rem"
                                fontWeight={'bold'}
                                render={(record) => {
                                    return `DEBUT DE L'INTERVENTION (CHECKIN)`
                                }}
                            />
                        </Box>
                        <Box>
                            <TextField source="checkinAddress"

                                color={'primary'} fontSize="0.8rem"
                            />

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem"    >
                                Début:
                            </Typography>

                            <FunctionField source="checkInDate" render={record => {
                                if (!record.checkInDate) return "";
                                return formatDateWithTime(
                                    record.checkInDate
                                );

                            }}
                                marginRight={"10px"}
                                color={'secondary'} fontSize="0.8rem"
                            />
                            <FunctionField source="checkInDate" render={record => {
                                if (!record.checkInDate) return "";
                                return signedDiffTime(record.startingDate, record.checkInDate);

                            }}

                                color={'secondary'} fontSize="0.8rem"
                            />

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                        }}>
                            <FunctionField source="effectiveTimeCheckInLate" render={record => {
                                if (!record.effectiveTimeCheckInLate) return "";
                                return `Début : ${formatDateWithTime(
                                    record.effectiveTimeCheckInLate.dateCheck
                                )}`

                            }}
                                marginRight={"10px"}
                                color={'secondary'} fontSize="0.8rem"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem" marginRight={"10px"}    >
                                Distance du lieu de l'intervention:
                            </Typography>
                            <FunctionField source="checkInLocation" render={record => {
                                if (!record.checkinLocation) return "";
                                const dist = haversineDistance(record.checkinLocation, record.location);
                                return dist ? dist.toFixed(2) + " km" : "";

                            }}
                                fontWeight={'bold'}
                                color={'secondary'} fontSize="0.8rem"
                            />


                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >


                        </Box>
                    </Box>

                    <Box
                        flex={1}
                        mr={{ xs: 0, sm: '1em' }}
                    >
                        <Box>
                            <FunctionField

                                color={'primary'} fontSize="0.8rem"
                                fontWeight={'bold'}
                                render={(record) => {
                                    return `NOTES`
                                }}
                            />
                        </Box>
                        <Box>
                            <FunctionField source="" render={record => {
                                return `Intervenant: ${record.careProviderRating === undefined ? `Pas de note` : ratingToString(record.careProviderRating)}`;
                            }}

                                color={'primary'} fontSize="0.8rem"

                            />


                        </Box>
                        <Box>
                            <FunctionField source="" render={record => {
                                return `Commentaire: ${(record.careProviderRatingComment === undefined || record.careProviderRatingComment === " ") ? `Pas de commentaire` : record.careProviderRatingComment}`;

                            }}
                                color={'primary'} fontSize="0.8rem"
                            />
                        </Box>
                        <Box>
                            <FunctionField source="" render={record => {

                                return `Client: ${record.careRecipientRating === undefined ? `Pas de note` : ratingToString(record.careRecipientRating)}`

                            }}

                                color={'primary'} fontSize="0.8rem"

                            />

                        </Box>




                    </Box>
                    {status == "completed" && <Box

                        flex={1.3}
                        mr={{ xs: 0, sm: '1em' }}

                    >
                        <Box>
                            <FunctionField

                                color={'primary'}
                                fontSize="0.8rem"
                                fontWeight={'bold'}
                                render={(record) => {
                                    return `FIN DE L'INTERVENTION (CHECKOUT)`
                                }}
                            />
                        </Box>
                        <Box>
                            <TextField source="checkoutAddress"

                                color={'primary'} fontSize="0.8rem"
                            />

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem"    >
                                Fin:
                            </Typography>

                            <FunctionField source="checkInDate" render={record => {
                                if (!record.checkOutDate) return "";
                                return formatDateWithTime(
                                    record.checkOutDate
                                );

                            }}
                                marginRight={"10px"}
                                color={'secondary'} fontSize="0.8rem"
                            />
                            <FunctionField source="checkInDate" render={record => {
                                if (!record.checkOutDate) return "";
                                return signedDiffTime(record.checkOutDate, record.endingDate);

                            }}

                                color={'secondary'} fontSize="0.8rem"
                            />

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                        }}>
                            <FunctionField source="effectiveTimeCheckOutLate" render={record => {
                                if (!record.effectiveTimeCheckOutLate) return "";
                                return `Fin : ${formatDateWithTime(
                                    record.effectiveTimeCheckOutLate.dateCheck
                                )}`

                            }}
                                marginRight={"10px"}
                                color={'secondary'} fontSize="0.8rem"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem" marginRight={"10px"}    >
                                Distance du lieu de l'intervention:
                            </Typography>
                            <FunctionField source="checkInLocation" render={record => {
                                if (!record.checkoutLocation) return "";
                                const dist = haversineDistance(record.checkoutLocation, record.location);
                                return dist ? dist.toFixed(2) + " km" : "";

                            }}
                                fontWeight={'bold'}
                                color={'secondary'} fontSize="0.8rem"
                            />


                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem" marginRight={"10px"}    >
                                Temps d'intervention:
                            </Typography>
                            <FunctionField source="checkInLocation" render={record => {
                                if (!record.checkOutDate && !record.checkInDate) return "";
                                const dif = timeOfIntervention(record.checkInDate, record.checkOutDate);
                                return ` ${(dif.day === 0 || isNaN(dif.day)) ? "" : `${dif.day}j`} ${(dif.hour === 0 || isNaN(dif.hour)) ? "" : `${dif.hour}h`} ${(dif.min === 0 || isNaN(dif.min)) ? "00min" : `${dif.min}min`} ${(dif.sec === 0 || isNaN(dif.sec)) ? "" : `${dif.sec}sec`}`

                            }}
                                fontWeight={'bold'}
                                color={'secondary'} fontSize="0.8rem"
                            />
                        </Box>

                    </Box>}
                    <Box

                        flex={0.7}
                        mr={{ xs: 0, sm: '1em' }}

                    >

                        <Box>
                            <Typography variant="h8" color={'primary'} fontSize="0.8rem" marginRight={"10px"}    >
                                Temps effectif:
                            </Typography>
                            <FunctionField source="Temps effectif" render={record => {
                                return printEffectiveTime(record.effectiveTime)

                            }}
                                color={'#37419A'}
                                style={{ fontSize: "2rem" }}
                            />
                        </Box>


                    </Box>

                </Box>


            </Box> : null
    )

}



export const InterventionShow = () => {
    const navigate = useNavigate();





    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    width: '90%',
                    margin: 'auto',
                    marginTop: '20px',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Retour
                </Button>
            </Box>
            <Show
                title="Intervention"
                sx={{
                    width: '90%',
                    margin: 'auto',
                    marginBottom: '20px',
                    backgroundColor: '#fff',

                }}
            >

                <Box>

                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                            borderTop: "50px solid #e8e8e8"

                        }}
                    >

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                padding: '1em',
                                backgroundColor: 'white',

                            }}
                        >

                            <Box

                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}

                            >
                                <Box>
                                    <FunctionField

                                        color={'primary'}
                                        fontSize="0.8rem"
                                        fontWeight={'bold'}
                                        render={(record) => {
                                            return `#` + record.interventionCode
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField source="title"

                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Typography variant="h8" color={'primary'} fontSize="0.8rem"    >
                                        Date de création:
                                    </Typography>
                                    <DateField source="createdAt"

                                        color={'primary'} fontSize="0.8rem"
                                        sx={{
                                            marginLeft: '1em',
                                        }}
                                    />

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Typography variant="h8" color={'primary'} fontSize="0.8rem"   >
                                        Créé par:
                                    </Typography>
                                    <FunctionField source="" render={record => {
                                        if (!record.createdBy) return "";
                                        return formatUserFullName(
                                            {
                                                firstName: record.createdBy.firstName,
                                                lastName: record.createdBy.lastName
                                            }
                                        );

                                    }}

                                        color={'primary'} fontSize="0.8rem"
                                        sx={{
                                            marginLeft: '1em',
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <Box>
                                    <FunctionField

                                        color={'primary'} fontSize="0.8rem"
                                        fontWeight={'bold'}
                                        render={(record) => {
                                            return `CLIENT`
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return formatUserFullName(
                                            record.careRecipient
                                        );

                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return getAgeFromDate(
                                            record.careRecipient.birthDate
                                        );

                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return record.careRecipient.address


                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return record.careRecipient.city + ` ` + record.careRecipient.zip


                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return record.careRecipient.addressComplement


                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return record.careRecipient.email


                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careRecipient) return "";
                                        return record.careRecipient.phoneNumber


                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>
                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <Box>
                                    <FunctionField

                                        color={'primary'} fontSize="0.8rem"
                                        fontWeight={'bold'}
                                        render={(record) => {
                                            return `INTERVENANT`
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <FunctionField source="" render={record => {
                                        if (!record.careProvider) return "";
                                        return formatUserFullName(
                                            record.careProvider.userId
                                        );

                                    }}

                                        color={'primary'} fontSize="0.8rem"

                                    />

                                </Box>

                                <Box>

                                    <FunctionField source="" render={record => {
                                        if (!record.careProvider) return "";
                                        return getAgeFromDate(record.careProvider.userId.birthDate) + " ans"

                                    }

                                    }


                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box>

                                    <FunctionField source="" render={record => record.careProvider.address}


                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box>

                                    <FunctionField source="" render={record => record.careProvider.city + ` ` + record.careProvider.zip}


                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box>

                                    <FunctionField source="" render={record => {
                                        if (!record.careProvider.userId) return "";
                                        return record.careProvider.userId.email
                                    }


                                    }


                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box>

                                    <FunctionField source="mobile" render={record => {
                                        if (record.careProvider.userId) return record.careProvider.userId.mobilePhone
                                        return ""

                                    }
                                    }

                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>

                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                padding: '1em',
                                backgroundColor: 'white',

                            }}
                        >

                            <Box

                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}

                            >
                                <Box>
                                    <FunctionField

                                        color={'primary'} fontSize="0.8rem"
                                        fontWeight={'bold'}
                                        render={(record) => {
                                            return `INTERVENTION`
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <FunctionField source="status" render={record => {

                                        if (!record.status) return "";
                                        switch (record.status) {
                                            case "pending":
                                                return <Chip label="En attente" color="primary" fontSize="0.8rem" />;
                                            case "inProgress":
                                                return "En cours";
                                            case "completed":
                                                return "Terminé";
                                            case "cancelled":
                                                return "Annulé";
                                            default:
                                                return "";
                                        }


                                    }}

                                        color={'primary'}
                                        fontWeight={'bold'}

                                    />
                                </Box>
                                <Box

                                >



                                    <FunctionField source="status" render={record => {
                                        if (record.status !== "cancelled") { return ""; }
                                        else { return `Motif d'annulation: ${record.reasonOfCancel ? record.reasonOfCancel : "Pas de raison"}` }

                                    }}

                                        color={'primary'}
                                        fontWeight={'bold'}

                                    />

                                </Box>
                                <Box>
                                    <FunctionField render={record => {
                                        // console.log(record)

                                    }}

                                        color={'primary'}
                                        fontWeight={'bold'}

                                    />

                                </Box>
                                <Box>
                                    <TextField source="category"

                                        color={'primary'} fontSize="0.8rem"
                                    />

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <TextField source="address"

                                        color={'primary'} fontSize="0.8rem"
                                    />
                                    <Box flexDirection={'row'}>
                                        <TextField source="city"

                                            color={'primary'} fontSize="0.8rem"
                                            marginRight={"0.2rem"}
                                        />
                                        <TextField source="zip"

                                            color={'primary'} fontSize="0.8rem"
                                        />
                                    </Box>


                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <FunctionField source="startingDate" render={record => {
                                        const d = new Date(record.startingDate);
                                        return formatDateWithTime(d) + ` `;
                                    }}
                                        color={'#37419A'}

                                        marginRight={
                                            "0.4rem"
                                        }

                                    />

                                    <FunctionField source="endingDate" render={record => {
                                        const d = new Date(record.endingDate);
                                        return formatDateWithTime(d);
                                    }}
                                        color={'#37419A'}

                                        marginLeft={
                                            "0.4rem"
                                        }

                                    />

                                </Box>
                            </Box>

                            <Box

                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}

                            >
                                <Box>
                                    <Box

                                        flex={1}
                                        mr={{ xs: 0, sm: '1em' }}

                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <FunctionField render={record => {
                                                const d2 = new Date(record.endingDate);
                                                const d1 = new Date(record.startingDate);
                                                return diffTime(d2, d1);




                                            }}

                                                fontSize="1.6rem"
                                                color={"primary"}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>

                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <Box>
                                    <FunctionField render={record => {
                                        return haversineDistance(record.location, record.careProvider.location) + ' km';
                                    }}
                                        fontSize="1.6rem"
                                        color={"primary"}
                                    />
                                </Box>
                            </Box>



                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: "flex-end",
                            }}
                        >
                            <FunctionField render={record => { return <NotificationIntervention record={record} /> }}

                                fontSize="1.6rem"
                                color={"primary"}
                            />
                        </Box>



                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: "flex-end",
                        }}
                    >
                    </Box>
                    <FunctionField render={record => { return <InterventionDonecard record={record} /> }}

fontSize="1.6rem"
color={"primary"}
/>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '1em',

                        }}
                    >

                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}

                        >

                            <FunctionField render={record => { return <InterventionJourney record={record} /> }}

                                fontSize="1.6rem"
                                color={"primary"}
                            />
                        </Box>
                    </Box>

                </Box>

            </Show>
        </Box>

    );
}
