import { List, SearchInput, TextField, Datagrid, FunctionField, SelectInput, DateInput, ReferenceInput, AutocompleteInput } from "react-admin"
import { formatDateWithTime, printEffectiveTime } from "../../utils/DateFormat"
import { formatUserFullName } from "../../utils/Stringhelps"
import { ratingToString } from "../../utils/Stringhelps";
import { Chip, } from '@mui/material';


const dateTommorow = new Date();
dateTommorow.setDate(dateTommorow.getDate() + 1);
const dateYesterday = new Date();
dateYesterday.setDate(dateYesterday.getDate() - 1);

const InterveentionFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput source="status" choices={[
        { id: 'pending', name: 'En attente' },
        { id: 'inProgress', name: 'En cours' },
        { id: 'completed', name: 'Terminé' },
        { id: 'cancelled', name: 'Annulé' },
    ]} />,
    <DateInput source="dateNow" defaultValue={new Date()} />,
    <DateInput source="dateYesterday" defaultValue={dateYesterday} />,
    <DateInput source="dateFrom" />,
    <DateInput source="dateTo" />,
    <DateInput source="dateMonth" defaultValue={new Date()}/>,

    <Chip source="interventionLate" defaultValue={new Date()} label="Intervention en retard" style={{backgroundColor:"#37419A" , color:"white",alignSelf:"center",marginBottom:10}} />,
    <DateInput source="dateTommorow" defaultValue={dateTommorow} />,

    <ReferenceInput source="recipientId" reference="care-recipient-profiles"
        allowEmpty>
        <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
            key={record => record.id}
            fullWidth


        />
    </ReferenceInput>
    ,

    <ReferenceInput source="providerId" reference="care-provider-profiles" allowEmpty>
        <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
            key={record => record.id}
            fullWidth
        />
    </ReferenceInput>
    ,



]



export const InterventionList = () => {
    return (
        <List
            exporter={false}

            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={InterveentionFilters}


        >

            <Datagrid
                rowClick="show"

            >
                <TextField source="interventionCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="title" color={'#37419A'} style={{ fontWeight: 500, fontSize: "0.8rem" }} />
                <FunctionField source="startingDate" render={record => {
                    const d = new Date(record.startingDate);
                    return formatDateWithTime(d);
                }}
                    color={'#37419A'}
                />
                <FunctionField source="endingDate" render={record => {
                    const d = new Date(record.endingDate);
                    return formatDateWithTime(d);
                }}
                    color={'#37419A'}
                />
                <FunctionField source="Temps effectif" render={record => {
                    return printEffectiveTime(record.effectiveTime)
                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />
                <FunctionField source="address" render={record => {
                    return record.address + " " + record.zip + " " + record.city;
                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />

                <FunctionField source="intervenant" render={record => {
                    if (!record.careProvider) return "";
                    return record.careProvider.user ? formatUserFullName(record.careProvider.user) : "";

                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />
                <FunctionField source="Note de l'intervenant" render={record => {
                    if (!record.careProviderRating) return "-";
                    return record.careProviderRating ? ratingToString(record.careProviderRating) : "Pas de note";

                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "1.5rem", alignItems: "center" }}
                />
                <FunctionField source="" render={record => {
                    if (!record.careRecipient) return "";
                    return formatUserFullName(
                        {
                            firstName: record.careRecipient.firstName,
                            lastName: record.careRecipient.lastName
                        }
                    );

                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />
                <TextField source="price" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="careProviderRatingComment" render={record => {
                    if (!record.careProviderRatingComment || record.careProviderRatingComment ===" ") return "-";
                    return record.careProviderRatingComment;

                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />
                <FunctionField source="status" render={record => {
                    if (!record.status) return "";
                    switch (record.status) {
                        case "pending":
                            return "En attente";
                        case "inProgress":
                            return "En cours";
                        case "completed":
                            return "Terminé";
                        case "cancelled":
                            return "Annulé";
                        default:
                            return "";
                    }


                }}
                    color={'#37419A'}
                    style={{ fontWeight: 500, fontSize: "0.8rem" }}
                />

            </Datagrid>
        </List>
    )
}