import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AutocompleteInput, Button, DateInput, Datagrid, Filter, FilterButton, FunctionField, Link, List, ListContextProvider, Pagination, ReferenceInput, SearchInput, SelectInput, SortButton, TextField, TextInput, TopToolbar, useGetList, useList, useRecordContext } from "react-admin";
import { useDeleteMany, useNotify, useUnselectAll } from 'react-admin';
import BinIcon from '@mui/icons-material/Delete';
import { formatDateWithTime, printEffectiveTime } from "../../utils/DateFormat";
import { formatUserFullName } from "../../utils/Stringhelps";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCreatePath } from 'react-admin';

import { ratingToString, translateStatusByText } from "../../utils/Stringhelps";
import { Chip, } from '@mui/material';
import { translateMonth,minutestoHourMin } from "../../utils/DateFormat";

const ActionGroup = (props) => {
    const { record } = props;
    const createPath = useCreatePath();
    return (
        <Box>
            <Link to={createPath({ resource: 'presence-sheets', type: 'show', id: record.id })}
            >
                <VisibilityIcon
                    variant="body2"
                    color="text.secondary"
                    style={{ fontWeight: 500, fontSize: "1.5rem" }}
                    cursor="pointer"

                />
            </Link>

        </Box>
    )
}


const ConstTableLabel = (props) => {
    const { label } = props;

    return (
        <Box>
            <Typography
                variant="body2"
                color="text.secondary"
                style={{ fontWeight: 500, fontSize: "1rem" }}
            >
                {label}
            </Typography>
        </Box>
    )

}

const InterventionBulkActionButtons = props => (
    <CustomDeleteManyButton {...props} />
);

const CustomDeleteManyButton = ({ selectedIds, unselectAll }) => {
    const notify = useNotify();
    // Call the useDeleteMany hook
    const [deleteMany, { loading }] = useDeleteMany('interventions', {
        ids: selectedIds
    },
        {
            onSuccess: () => {
                // Notify the user
                unselectAll();
                notify('Feuille de présence supprimées', 'info', {}, false);
                // Unselect all items
            }, onFailure: () => {
                // Notify the user
                unselectAll();
                notify('Erreur lors de la suppression', 'warning');
            }
        }
    );

    const handleClick = () => {
        // Call the deleteMany action
        deleteMany()
    };
    // This is the custom delete button
    return (
        <Button
            variant="outlined"
            color="error"
            disabled={loading}
            onClick={handleClick}
            label="Supprimer"
            startIcon={<BinIcon />}

        />
    );
};


export const CareRecipientPresenceSheets = () => {
    const record = useRecordContext();
    const [recipientId, setRecipientId] = useState(record.id);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const { data, isLoading, total, totalPages
    } = useGetList(
        'presence-sheets',
        {
            filter: {
                careRecipientId: recipientId,
            },
            pagination: {
                page: page,
                perPage: perPage,
            },
            sort: {
                field: 'createdAt',
                order: 'DESC',
            },
        }
    );

    useEffect(() => {
        setRecipientId(record.id);
    }, [record.id]);

    const unselectAll = useUnselectAll('presence-sheets');


    const listContext = useList({
        data, isLoading, total, totalPages, page, perPage,
        setPage,
        setPerPage,
        resource: 'presence-sheets',
    })


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}

        >
            <ListContextProvider
                value={listContext}


            >
                <Datagrid
                    bulkActionButtons={<InterventionBulkActionButtons
                        unselectAll={() => {
                            unselectAll();
                        }}
                    />}
                    style={{ width: '100%' }}



                >


                    <TextField source="presenceSheetCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                    <TextField source="provider.fullName" color={'#37419A'} style={{ fontWeight: 500 }} />
                    <TextField source="recipient.fullName" color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField source="monthNumber" render={record => `${translateMonth(record.monthNumber).toUpperCase()}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                    <TextField source="yearNumber" color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField source="status" render={record => `${translateStatusByText(record.status)}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField
                                source="totalMinutes"
                                render={record => ` ${minutestoHourMin(record.totalMinutes)}` }
                                color={'#37419A'} style={{ fontWeight: 500 }}
                            />   
                    <FunctionField source="recipientHasSigned" render={record => `${record.recipientHasSigned ? "Oui" : "Non"}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField source="careProviderHasSigned" render={record => `${record.careProviderHasSigned ? "Oui" : "Non"}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField source="" render={record => {

                        return <ActionGroup record={record} />

                    }}
                        color={'#37419A'}
                        style={{ fontWeight: 500, fontSize: "1rem" }}
                        label={
                            <ConstTableLabel label="actions" />
                        }
                    />
                </Datagrid>
                <Pagination page={page} setPage={setPage}
                    perPage={perPage} setPerPage={setPerPage}
                    total={total} totalPages={totalPages}
                    rowsPerPageOptions={[25, 50, 100]}

                />
            </ListContextProvider>
        </Box>
    )

}