import frenchMessages from 'ra-language-french';

export const customFrenchMessages = {
    ...frenchMessages,
    auth: {
        sign_in: 'Connexion',
        sign_in_error: "Identifiants incorrects",
        logout: 'Déconnexion',
    },
    resources: {
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                id: 'ID',
                email: 'Email',
                password: 'Mot de passe',
                password_confirmation: 'Confirmation du mot de passe',
                createdAt: 'Créé le',
                updated_at: 'Mis à jour le',
                role: 'Rôles',
                mobilePhone: 'Téléphone',
                firstName: 'Prenom',
                lastName: 'Nom',
                dateOfBirth: 'Date de naissance',
                gender: 'Genre',
                isEnabled: 'Activé',
                isEmailVerified: 'Email vérifié',
            }
        },
        "care-recipient-profiles": {
            fields: {
                lastName: 'Nom',
                firstName: 'Prénom',
                birthDate: 'Date de naissance',
                gender: 'Genre',
                address: 'Adresse',
                zip: 'Code postal',
                city: 'Ville',
                phoneNumber: 'Téléphone',
                state: 'pays',
                addressComplement: 'Complément d\'adresse',
                "condition.mobility": "Mobilité",
                "condition.particularConditions" : "Conditions particulières",
                category: "Catégorie",
                "condition.specialIndications": "Indications spéciales",
                "users": "Utilisateurs",
                "emergencyContacts": "Contacts d'urgence",
                "emergencyContacts.firstName": "Prénom",
                "emergencyContacts.lastName": "Nom",
                "emergencyContacts.phoneNumber": "Téléphone",
                "emergencyContacts.relationship": "Relation",
                "emergencyContacts.professionalRelationship": "Professionnel",
                "emergencyContacts.email": "email",
                "relationship": "Relation",
                "professionalRelationship": "Professionnel",
                "rating": "Note",
                "presenceSheetCode":"Code de présence",
                "provider.fullName":"Prestataire",
                "recipient.fullName":"Bénéficiaire",
                "month":"Mois",
                'year': 'Année',
                "Status":"Statut",
                "totalMinutes":"Temps total",
                "recipientHasSigned":"Bénéficiaire a signé",
                "careProviderHasSigned":"Prestataire a signé",
            },

        },
        "care-provider-profiles":{
            fields: {
                city: 'Ville',
                endingDate: 'Date de fin',
                "dateFrom": "de",
                "recipientId": "Bénéficiaire",
                "dateTo": "à",
                "recipient": "Bénéficiaire",
                careRecipient: 'Bénéficiaire',
                zip: 'Code postal',
                createdAt: 'Créé le',
                address: 'Adresse',
                user: 'Utilisateur',
                street: 'Rue',
                country: 'Pays',
                zipCodes: "Codes postaux de prestations", 
                spokenLanguages: "Langues parlées",
                hasACriminalRecord: "A un casier judiciaire",
                IsAllowedToWork: "Autorisé à travailler",
                scholarLevel: "Niveau d'étude",
                workExperienceSince: "Expérience professionnelle depuis",
                enabledByAdmin: "Activé par l'organisme",
                rating: "Note",
                wishedAvegrageHourlyWeeklyActivity: "Heures hebdomadaires souhaitées",
                toiletAid: "Aide aux toilettes", 
                helpGettingUp: "Aide au lever",
                bedTimeHelp: "Aide au coucher",
                helpWithShopping: "Aide aux courses",
                helpWithMobility: "Promenades",
                laundryCare: "Entretien du linge",
                place: "Adresse de résidence",
                state: "Pays",
                category: "Catégorie",
                anotations: "Remarques",
                "startingDate": "Date de début",
                "emergencyContact.name": "Nom du contact d'urgence",
                "emergencyContact.phone": "Téléphone du contact d'urgence",
                "emergencyContact.email": "Email du contact d'urgence",
                status: "statut",
                "helpToStandUp": "Aide au lever",
                "helpWithMeals": "Aide aux repas",
                "helpWithHousework": "Aide au ménage",
                "helpWithGroceries": "Aide aux courses",
                "helpWithToileting": "Aide à la toilette",
                "helpWithWalkAndFunActivities": "Aide à la marche et aux activités",
                "helpWithLaundry": "Entretien du linge",
                "helpLayingDown": "Aide au coucher",
                "dayWatch": "Journée",
                "nightWatch": "Nuit",
                "companionship": "Compagnie",
                "isVip": "Auxiliaire favorite",
                "providerGender": "Genre",
                "hasDinamycPersonality": 'Personnalité dynamique',
                'likeToTakeInitiative': 'Aime prendre des initiatives',
                'isKind': 'Est douce',
                "hasStrongTemperament": 'fort caractère',                
            }
        },
        interventions: {
            fields: {
                "careRecipientProfile": "Profil bénéficiaire",
                "careProviderProfile": "Profil d'auxiliaire",
                "interventionType": "Type d'intervention",
                "interventionStatus": "Statut",
                "status": "Statut",
                "startingDate": "Date de début",
                "intervalDate": "Date d'intervention",
                "dateFrom": "de",
                "dateTo": "à",
                "dateNow": "Aujourd'hui",
                "dateYesterday" : "Hier",
                "dateMonth": "Ce mois-ci",
                "dateTommorow": "Demain",
                "category": "Catégorie",
                "interventionLate": "Intervention en retard",
                "title": "Titre",
                "endingDate": "Date de fin",
                "price": "Prix",
                "careRecipient": "Bénéficiaire",
                "recipientId": "Bénéficiaire",
                "careProvider": "Auxiliaire",
                "providerId": "Auxiliaire",
                "tasks": "Tâches",
                "bedTimeHelp": "Aide au coucher",
                "toiletAid": "Aide aux toilettes",
                "helpWithMeals": "Aide aux repas",
                "helpGettingUp": "Aide au lever",
                "helpWithHousework": "Aide au ménage",
                "laundryCare": "Entretien du linge",
                "helpWithShopping": "Aide aux courses",
                "careRecipient.id": "Bénéficiaire",
                "careProvider.id": "Auxiliaire",
                "city": "Ville",
                "zip": "Code postal",
                "createdAt": "Créé le",
                "address": "Adresse",
                "street": "Rue",
                "state": "Pays",
                "effectiveTime.hours": "Heures Temps effectif",
                "effectiveTime.minutes": "Minutes Temps effectif",
                "careProviderRatingComment": "Commentaire",
                addressComplement: 'Complément d\'adresse',
            }
        },
        contracts: {
            fields: {
                "createdAt": "Date de création",
                "category": "Catégorie",
                "careRecipientProfile": "Bénéficiaire",
                "careProviderProfile": "Auxiliaire",
                "careRecipient.id": "Bénéficiaire",
                "careProvider.id": "Auxiliaire",
                "startingDate": "Date de début de contrat",
                "endingDate": "Date de fin de contrat",
                "tasks": "Tâches",
                "bedTimeHelp": "Aide au coucher",
                "toiletAid": "Aide aux toilettes",
                "helpWithMeals": "Aide aux repas",
                "helpWithMobility": "Aide à la mobilité",
                "helpWithShopping": "Aide aux courses",
                "helpWithHousework": "Aide au ménage",
                "laundryCare": "Entretien du linge",
                "typeOfPrice": "Type de prix",
                "price": "Prix",
                "contractType": "Type de contrat",
                "schedules": "Passages",
                "schedules.day": "Jour",
                "schedules.startingHour": "Heure de début",
                "schedules.startingMinute": "Minute de début",
                "schedules.endingHour": "Heure de fin",
                "schedules.endingMinute": "Minute de fin",
                "status": "Statut",
                "renewalContract":"Renouvellement de contrat",
            },

        },
        jobs: {
            fields: {
                "createdAt": "Date de création",
                "jobCode": "Code",
                "recipientFullName": "Bénéficiaire",
                "recipientAddress": "Adresse du bénéficiaire",
                "recipientZipCode": "Code postal",
                "recipientCity": "Ville du bénéficiaire",
                "jobStatus": "Statut",
                "jobType": "Type",
                "recipientPhone": "Téléphone du bénéficiaire",
                "jobCategory": "Catégorie",
                "jobDescription": "Description",
                "recipientEmail": "Email du bénéficiaire",
                "recipientGender": "Genre du bénéficiaire",
                "recipientBirthDate": "Date de naissance du bénéficiaire",
                "jobStartDate": "Date de début",
                "jobStartHour": "Heure de début",
                "jobStartMinute": "Minute de début",
                "jobEndDate": "Date de fin",
                "schedules": "Passages",
                "providerApplicants":'Candidats',
                'jobPrice': 'Prix',
                "jobPriceType": "Type de prix",
                "recipientAge": "Age du bénéficiaire",
                "schedules.day": "Jour",
                "schedules.startingHour": "Heure de début",
                "schedules.startingMinute": "Minute de début",
                "schedules.endingHour": "Heure de fin",
                "schedules.endingMinute": "Minute de fin",
                "adre": "Adresse",
                "schedule.day": "Jour",
                "helpToStandUp": "Aide au lever",
                "helpWithMeals": "Aide aux repas",
                "helpWithHousework": "Aide au ménage",
                "helpWithGroceries": "Aide aux courses",
                "helpWithToileting": "Aide à la toilette",
                "helpWithWalkAndFunActivities": "Promenade et sorties",
                "helpWithLaundry": "Entretien du linge",
                "helpLayingDown": "Aide au coucher",
                "companionship": "Compagnonnage",
                "nightWatch'": "garde de nuit",
                "providerGender": "Genre de l'intervenant",
                "isVip": "VIP",
                "hasDinamycPersonality": 'Personnalité dynamique',
                'likeToTakeInitiative': 'Aime prendre des initiatives',
                'isKind': 'Est douce',
                "hasStrongTemperament": 'fort caractère',
                "dayWatch": "Garde de jour",
                "nightWatch": "Garde de nuit",
                "workExperienceRange": "Expérience",

            }
        },
        'presence-sheets': {
            fields: {
                "presenceSheetCode": "Code",
                "provider.fullName": "Auxiliaire",
                "recipient.fullName": "Bénéficiaire",
                "monthNumber": "Mois",
                "yearNumber": "Année",
                "checkInDate":  "Date d'arrivée",
                "checkOutDate": "Date de départ",
                "presences.checkInDate":  "Date d'arrivée",
                "presences.checkOutDate": "Date de départ",
                "interventionLength": "Durée de l'intervention",
                "recipientHasSigned":"Bénéficiaire a signé",
                "careProviderHasSigned":"Auxiliaire a signé",
                "careRecipient.id":  "Bénéficiaire",
                "careProvider.id": "Auxiliaire",
                "presences.comment":"commentaire",
                "status": "Statut",
                "dateMonth": "Mois",
                "careProviderId": "Auxiliaire",
                "careRecipientId": "Bénéficiaire",
            }
        },
                
    },
};

export default customFrenchMessages;