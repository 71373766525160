import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { SelectInput } from "react-admin"
import { useDataProvider } from 'react-admin';
import ApiConstants from "../../api/ApiConstants";
import { API_URL } from "../../utils/constants";


export const SelectUnlinkedUserFirld = ({ userRole,...props}) => {
    const dataProvider = useDataProvider();
    const [careUserChoices, setCareUserChoices] = useState([]);
    const { data } = useQuery(
        'choices',
        () =>  userRole && dataProvider.getOnApi(
            API_URL+ApiConstants.GET_UNLINK_USERS
                .replace("{userRole}", userRole),null),
        {
            onSuccess: (data) => {
                if (data) {
                const choices = data.data.map((choice) => ({
                    id: choice.id,
                    name: choice.fullName,
                }));
                setCareUserChoices(choices);
            }
            }
        }
    );

        useEffect(() => {
            if (data) {
                const choices = data.data.map((choice) => ({
                    id: choice.id,
                    name: choice.fullName,
                }));
                setCareUserChoices(choices);
            }
        }, [data]);

  return (
      <SelectInput source="user" isRequired fullWidth
          {...props}
          choices={careUserChoices} 
          />
  )
}
