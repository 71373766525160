import { Container } from "@mui/material"
import { Box } from "@mui/system";
import React from "react";
import { ArrayInput, AutocompleteInput, BooleanInput, Create, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator } from "react-admin"
import { formatUserFullName } from "../../utils/Stringhelps";
import { days, hours, minutes } from "../../common/utils";

const validateForm = (values) => {
  
}

const transform = (data) => {
    return {
        ...data,
    }

}

const contractTypes = [
    {
        id: "RECURRENT", name: "récurrent",

    },
    {
        id: "ONE_TIME", name: "Pontuel",
    }
]

const typeOfPrices = [
    {
        id: "TAUX_HORAIRE", name: "Taux horaire",

    },
    {
        id: "FORFAIT", name: "Forfait",
    }
]

const contractStatus = [
    {
        id: "ACTIVE", name: "actif",
    }
    , {
        id: "INACTIVE", name: "inactif",
    }
]

const categories = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    }, {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }]
   
export const ContractCreate = () => {
   // const dataProvider = useDataProvider();
    //const [careRecipient, setCareRecipient] = React.useState(null);
    const [hoursStartingDefault, setHoursStartingDefault] = React.useState();
    const [minutesStartingDefault, setMinutesStartingDefault] = React.useState();

    const [hoursEndingDefault,setHoursEndingDefault] = React.useState();

    const [minutesEndingDefault,setMinutesEndingDefault] = React.useState();

    const handleRecipentChange = async (value) => {
       // const { data } = await dataProvider.getOne("care-recipient-profiles", { id: value })
    }
    const changeSelectedHour = (value) => {
        if((value.target.value === "00" && minutesEndingDefault == "00") || (value.target.value === "00" && hoursEndingDefault == "00")){
            setHoursEndingDefault("23");
            setMinutesEndingDefault("59");
        }
        else if(value.target.name.includes("startingHour")){
            setHoursStartingDefault(value.target.value)
        }else if(value.target.name.includes("endingHour")){
            setHoursEndingDefault(value.target.value)
        }else if(value.target.name.includes("startingMinute")){
            setMinutesStartingDefault(value.target.value)
        }else if(value.target.name.includes("endingMinute")){
            setMinutesEndingDefault(value.target.value)
        }
    }

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '100%',
            }}
        >
            <Create
                title={"Creer un contrat"}
                transform={transform}
                sx={{ width: '80%' }}
            >
                <SimpleForm
                    validateForm={validateForm}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                            <ReferenceInput
                                source="careRecipientProfile"
                                reference="care-recipient-profiles"
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
                                    key={record => record.id}
                                    onChange={handleRecipentChange}

                                />

                            </ReferenceInput>

                        </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <ReferenceInput source="careProviderProfile" fullWidth
                                reference="care-provider-profiles"

                            >
                                <AutocompleteInput
                                    optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
                                    key={record => record.id}

                                />

                            </ReferenceInput>
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="startingDate" fullWidth required

                            />
                        </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="endingDate" fullWidth required />
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="bedTimeHelp" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="toiletAid" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithMeals" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithHousework" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithMobility" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithShopping" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="laundryCare" />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="category" fullWidth required
                                choices={categories}
                            />                  </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="contractType" fullWidth
                                choices={contractTypes}
                                defaultValue="RECURRENT"
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="typeOfPrice" fullWidth required
                                choices={typeOfPrices}
                                defaultValue="TAUX_HORAIRE"
                            />                  </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <NumberInput source="price" fullWidth required
                                defaultValue="24.50"
                            />                  </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <ArrayInput source="schedules" fullWidth>
                            <SimpleFormIterator inline>
                                <SelectInput source="day" fullWidth
                                choices={days}
                                required
                                />
                                <SelectInput source="startingHour" fullWidth 
                                choices={hours}
                                onChange={changeSelectedHour}
                                defaultValue={hoursStartingDefault}
                                />
                                <SelectInput source="startingMinute" fullWidth 
                                choices={minutes}
                                onChange={changeSelectedHour}
                                defaultValue={minutesStartingDefault}
                                />
                                <SelectInput source="endingHour" fullWidth
                                choices={hours}
                                onChange={changeSelectedHour}
                                defaultValue={hoursEndingDefault}
                    
                                />
                                <SelectInput source="endingMinute" fullWidth
                                choices={minutes}
                                onChange={changeSelectedHour}
                                defaultValue={minutesEndingDefault}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}

                    >
                            <SelectInput source="status" fullWidth
                                choices={contractStatus}
                                defaultValue="ACTIVE"
                                />
                           
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}

                    >
                    <SelectInput source="renewalContract" fullWidth
                            choices={[{ id: 'true', name: 'Oui' }, { id: 'false', name: 'Non' }]}
                            defaultValue={true}
                            />
                            
                            </Box>
                </SimpleForm>

            </Create>
        </Container>
    )

}