import {  Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react"
import { ArrayInput, Button, FunctionField, SaveButton, SelectInput, SimpleForm, SimpleFormIterator, Toolbar, useDataProvider, useRecordContext } from "react-admin";
import { useMutation } from 'react-query';
import ApiConstants from "../../api/ApiConstants"
import { API_URL } from "../../utils/constants"
import { formatDateWithTime } from "../../utils/DateFormat"
import { days, hours, minutes } from "../../common/utils"

const AvailabilityEditFormToolBar = (props) => {
    return (
         <Toolbar 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '99%',
                }}
            >

                <SaveButton
                    variant="contained"

                >
                    Enregistrer
            </SaveButton>

                <Button
                    onClick={props.onCancel}
                    variant="contained"
                    sx={{
                        backgroundColor: '#f44336',
                       padding:"8px"
                    }}
                >
                    Annuler
                </Button>
            </Toolbar>
    )
}
            

const AvailabilityEditForm = (props) => {

    const { availability, onSave } = props;

     return (
        
        <Modal
        open={props.open}
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <SimpleForm
            record={availability}
            onSubmit={onSave}
            redirect={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: 'white',
            }}
                 toolbar={<AvailabilityEditFormToolBar onCancel={props.onCancel} 
            />}
           
        >
                 <Typography variant="h6" gutterBottom component="div"
                     color="#3f51b5"
                     fontWeight={600}

                 >
                     Edition des disponibilités
                 </Typography>
                     <ArrayInput source='schedules'

                     >
                         <SimpleFormIterator inline
                         
                         >
                             <SelectInput source="day"  fullWidth
                                 choices={days}
                             />
                             <SelectInput 
                                    source="startingHour"

                                    choices={
                                        hours
                                    }
                             />
                                <SelectInput
                                    source="startingMinute"
                                    choices={minutes}
                                     defaultValue={"00"}

                                />
                         <SelectInput
                             source="endingHour"

                             choices={
                                 hours
                             }
                             
                         />
                         <SelectInput
                             source="endingMinute"

                             choices={
                                 minutes
                             }
                             defaultValue={"00"}
                         />
                         </SimpleFormIterator>
                     </ArrayInput>
           
        </SimpleForm>  
         </Modal>  
        )
}

const CareProviderAvailability = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const [availability, setAvailability] = useState(null);
    const [editIsOpen, setEditIsOpen] = useState(false);
    const { mutate: getAvailabilityById } = useMutation(
        () => dataProvider.getOnApi(API_URL + ApiConstants.GET_PROVIDER_AVAILABILITY.replace("{availabilityId}", record.availability), null),
        {
            onSuccess: (result) => {
                console.log(result.data);
                setAvailability(result.data)
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const { mutate: getAvailabilityByProviderId } = useMutation(
        () => dataProvider.getOnApi(API_URL + ApiConstants.GET_PROVIDER_AVAILABILITY_BY_PROVIDER_ID.replace("{providerId}", record.id), null),
        {
            onSuccess: (result) => {
                console.log(result.data);
                setAvailability(result.data)
                record.availability = result.data.id;
            },
            onError: (error) => {
                console.log(error);
            },  
        }
    );

    const {mutate: updateAvailability} = useMutation(
        (data) => dataProvider.patchOnApi(API_URL + ApiConstants.UPDATE_PROVIDER_AVAILABILITY.replace("{availabilityId}", record.availability), data),
        {
            onSuccess: (result) => {
                setAvailability(result.data)
                setEditIsOpen(false);

            },
            onError: (error) => {
                console.log(error);
            },

        }
    )
  

    const handleSave = (data) => {
        updateAvailability(data);
        handleCancel();

    }
    const handleCancel = () => {
        setEditIsOpen(false);
    }
    useEffect(() => {
        if (record.availability) getAvailabilityById(record.availability);
    }, [record, getAvailabilityById])

    useEffect(() => {
        if (!record.availability) getAvailabilityByProviderId(record.id);
    }, [record, getAvailabilityByProviderId])

    if (!availability) return (<Box color={"#37419A"} fontWeight={600}> <h1 >Disponibilité permanente</h1></Box>)

    return (
        <Box>

    
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >

        <Box 
           
        >
            <Box

                flex={1}
                mr={{ xs: 0, sm: '1em' }}

            >
                <Typography variant="h6" gutterBottom component="div"
                    color="#3f51b5"
                    fontWeight={600}

                >
                    Date de mise à jour par l'Auxiliaire
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
                    rowClick="show"

                >
                    <Box flex={1} mr={{ xs: 0, sm: '1em' }} >

                        <FunctionField 
                            render={(record) => {
                                return `${ formatDateWithTime(availability.lastUpdateDateByProvider)}`
                            }}
                            color={'primary'} fontSize="1rem"
                        />
                    </Box> 
            </Box>
            </Box>
        </Box>

        <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}

        >
            
            <Box

                flex={1}
                mr={{ xs: 0, sm: '1em' }}

            >
                  <Typography variant="h6" gutterBottom component="div"
                    color="#3f51b5"
                    fontWeight={600}

                >
                    Disponibilités
                </Typography>
              
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
            </Box>
            </Box>
            <Box

                flex={1}
                mr={{ xs: 0, sm: '1em' }}

            >
                <Box>

               
               
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}

                > 
               {availability.schedules.map((schedule, index) => {
                        return (
                            <Box
                                key={index}
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                                
                            >
                                <Typography variant="h6" gutterBottom component="div"
                                    color="#3f51b5"

                                >
                                    {schedule.day}
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                                    rowClick="show"

                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '1em' }} >

                                        <FunctionField
                                            render={() => {
                                                return `${schedule.startingHour} : ${schedule.startingMinute} - ${schedule.endingHour} : ${schedule.endingMinute}`
                                            }}
                                            color={'primary'} 
                                            fontSize="1rem"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                    }
                    
           
            </Box>
                </Box>
            </Box>
                <Box

                    flex={1}
                    mr={{ xs: 0, sm: '1em' }}
                    paddingTop={2}

                >
                    
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                      <Box>
                        <Button
                            variant="contained"
                                onClick={() => {
                                    console.log("click click")
                                    setEditIsOpen(true)
                                }}
                        >
                            Modifier les disponibilités
                        </Button>
                      </Box>
                        
                    </Box>
                </Box>
        </Box>
            </Box>
            <AvailabilityEditForm
                availability={availability}
                open={editIsOpen}
                onCancel={handleCancel}
                onSave={handleSave}

            />
        </Box>
    )
}

export default CareProviderAvailability