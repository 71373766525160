import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AutocompleteInput, Button, DateInput, Datagrid,Filter, FilterButton, FunctionField, Link, List, ListContextProvider, Pagination, ReferenceInput, SearchInput, SelectInput, SortButton, TextField, TextInput, TopToolbar, useGetList, useList, useRecordContext } from "react-admin";
import { useDeleteMany, useNotify, useUnselectAll } from 'react-admin';
import BinIcon from '@mui/icons-material/Delete';
import { formatDateWithTime, printEffectiveTime } from "../../utils/DateFormat";
import { formatUserFullName } from "../../utils/Stringhelps";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCreatePath } from 'react-admin';



const ActionGroup = (props) => {
    const { record } = props;
    const createPath = useCreatePath();
    return (
        <Box>
            <Link to={createPath({ resource: 'interventions', type: 'show', id: record.id })}
            >
                <VisibilityIcon
                    variant="body2"
                    color="text.secondary"
                    style={{ fontWeight: 500, fontSize: "1.5rem" }}
                    cursor="pointer"

                />
            </Link>

        </Box>
    )
}


const ConstTableLabel = (props) => {
    const { label } = props;

    return (
        <Box>
            <Typography
                variant="body2"
                color="text.secondary"
                style={{ fontWeight: 500, fontSize: "1rem" }}
            >
                {label}
            </Typography>
        </Box>
    )

}

const InterventionBulkActionButtons = props => (
    <CustomDeleteManyButton {...props} />
);

const CustomDeleteManyButton = ({ selectedIds, unselectAll }) => {
    const notify = useNotify();
    // Call the useDeleteMany hook
    const [deleteMany, { loading }] = useDeleteMany('interventions', {
        ids: selectedIds
    },
        {
            onSuccess: () => {
                // Notify the user
                unselectAll();
                notify('Interventions supprimées', 'info', {}, false);
                // Unselect all items
            }, onFailure: () => {
                // Notify the user
                unselectAll();
                notify('Erreur lors de la suppression', 'warning');
            }
        }
    );

    const handleClick = () => {
        // Call the deleteMany action
        deleteMany()
    };
    // This is the custom delete button
    return (
        <Button
            variant="outlined"
            color="error"
            disabled={loading}
            onClick={handleClick}
            label="Supprimer"
            startIcon={<BinIcon />}

        />
    );
}; 


export const CareRecipientInterventionList = ()  => {
    const record = useRecordContext();
    const [recipientId, setRecipientId] = useState(record.id);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const { data, isLoading, total, totalPages
    } = useGetList(
    'interventions',
    {
        filter: {
            recipientId: recipientId,
        },
        pagination: {
            page: page,
            perPage: perPage,
        },
        sort: {
            field: 'createdAt',
            order: 'DESC',
        },
    }
);

useEffect(() => {
    setRecipientId(record.id);
}, [record.id]);

const unselectAll = useUnselectAll('interventions'); 


const listContext = useList( { data,isLoading,total,totalPages,page,perPage,
    setPage,
    setPerPage,
    resource: 'interventions',
})


return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        }}

    >                  
        <ListContextProvider
            value={listContext}


        >
        <Datagrid
            bulkActionButtons={<InterventionBulkActionButtons
                unselectAll={() => {
                    unselectAll();
                }}
            />}
            style={{ width: '100%' }}



        >

            <TextField source="interventionCode" color={'#37419A'}
                fontSize="1rem"
                label={
                    <ConstTableLabel label="Code" />
                }
            />
                <FunctionField source="intervenant" render={record => {
                    if (!record.careProvider) return "";
                    return record.careProvider.user ? formatUserFullName(record.careProvider.user) : "";

                }}
                color={'#37419A'}
                style={{ fontWeight: 500, fontSize: "1rem" }}
                label={
                    <ConstTableLabel label="Auxiliaire" />
                }
            />
            <FunctionField source="address" render={record => {
                return record.address + " " + record.zip + " " + record.city;
            }}
                color={'#37419A'}
                style={{ fontSize: "1rem" }}
                label={
                    <ConstTableLabel label="adresse" />
                }
            />

            <FunctionField source="startingDate" render={record => {
                const d = new Date(record.startingDate);
                return formatDateWithTime(d);
            }}
                color={'#37419A'}
                label={
                    <ConstTableLabel label="début" />
                }
            />
            <FunctionField source="endingDate" render={record => {
                const d = new Date(record.endingDate);
                return formatDateWithTime(d);
            }}
                color={'#37419A'}
                label={
                    <ConstTableLabel label="fin" />
                }
            />
            <FunctionField source="Temps effectif" render={record => {
                return printEffectiveTime(record.effectiveTime)

            }}
                color={'#37419A'}
                style={{ fontWeight: 500, fontSize: "1rem" }}
                label={

                    <ConstTableLabel label="Temps effectif" />
                }
            />
            <FunctionField source="careProviderRatingComment" render={record => {
                if (!record.careProviderRatingComment || record.careProviderRatingComment === " ") return "-";
                return record.careProviderRatingComment;

            }}
                color={'#37419A'}
                style={{ fontWeight: 500, fontSize: "1rem" }}
                label={
                    <ConstTableLabel label="Commentaire" />
                }
            />
            <FunctionField source="status" render={record => {
                if (!record.status) return "";
                switch (record.status) {
                    case "pending":
                        return "En attente";
                    case "inProgress":
                        return "En cours";
                    case "completed":
                        return "Terminé";
                    case "cancelled":
                        return "Annulé";
                    default:
                        return "";
                }


            }}
                color={'#37419A'}
                style={{ fontWeight: 500, fontSize: "1rem" }}
                label={
                    <ConstTableLabel label="Statut" />
                }
            />
            <FunctionField source="" render={record => {

                return <ActionGroup record={record} />

            }}
                color={'#37419A'}
                style={{ fontWeight: 500, fontSize: "1rem" }}
                label={
                    <ConstTableLabel label="actions" />
                }
            />
        </Datagrid>
        <Pagination page={page} setPage={setPage}
            perPage={perPage} setPerPage={setPerPage}
            total={total} totalPages={totalPages}
            rowsPerPageOptions={[25, 50, 100]}

        />
        </ListContextProvider>
    </Box>
)

}