import * as React from 'react';
import { BooleanField, useRecordContext } from 'react-admin';
import styled from 'styled-components';

const Field = styled(BooleanField)`
    font-weight: bold;
    font-size: 1.2em;
    color:  ${props => props.value ? "#79cc79" : "#ff3369"};
`;
const ActivetedBooleanField = () => {
    const record = useRecordContext();
    return record ? (
        <Field
            source="isEnabled"
            value={record.isEnabled}
        />
    ) : null;
}

export default ActivetedBooleanField;