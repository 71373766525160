import Rating from '@mui/material/Rating';
import { useRecordContext } from 'react-admin';


const StarsField = ({ ratingKey }) => {
    const record = useRecordContext();

    return <Rating value={record[ratingKey]} style={{
        fontSize: '1.8rem',
        fontWeight: 700,
        margin: '0 0.5rem'
    }} readOnly />


}

export default StarsField