import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Name } from "../../common/style/style";
import { formatUserFullName } from '../../utils/Stringhelps';

const RecipientField = () => {
    const record = useRecordContext();
    const getFullName = () => {
        if (!record) {
            return '';
        }
        return `${formatUserFullName(record)}`;
    };

    const getEmail = () => {
        if (!record) {
            return '';
        }
        return `${record.email}`;
    };

    const getMobilePhone = () => {
        if (!record) {
            return '';
        }
        return `${record.phoneNumber}`;
    };
    
        
    return record ? (
        <>
            <Name
                variant="p"
            >
                {getFullName()}
            </Name>

            <Name
                variant="p"
            >
                {getEmail()}
            </Name>

            <Name
                variant="p"
            >
                {getMobilePhone()}
            </Name>
        </>
       
    ) : null;
}

export default RecipientField;