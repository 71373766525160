import {TableRow } from '@mui/material'
import React from 'react'
import { List, Datagrid, TextField, FunctionField, SearchInput, TopToolbar, FilterButton, SortButton, CreateButton,  } from 'react-admin'
import TableCellCustom from '../../common/components/TableCellCustom'
import { CustomeTableHead } from '../../common/style/style'
import { formatWorkExperience } from '../../utils/DateFormat'
import StarsField from './StarsField'
import TagsField from './TagsField'
import UserField from './UserField'

const DatagridHeader = () => {
    return (
        <CustomeTableHead>
            <TableRow >
                <TableCellCustom />
                <TableCellCustom name="Code client" />
                <TableCellCustom name="Utilisateur" />
                <TableCellCustom name="category" />
                <TableCellCustom name="Adresse" />
                <TableCellCustom name="Code postal" />
                <TableCellCustom name="Ville" />
                <TableCellCustom name="Experience" />
                <TableCellCustom name="Diplome" />
                <TableCellCustom name="zone d'intervention" />
                <TableCellCustom name="Note" />
                <TableCellCustom name="Status" />

            </TableRow>
        </CustomeTableHead>
    )
}

const ListActions = () => {
    return (
        <TopToolbar sx={{display: 'flex',alignItems: 'center'}}>
            <FilterButton />
            <SortButton fields={['createdAt','city', 'zip','user.fisrtName','user.lastName']} />
            <CreateButton />
        </TopToolbar>
    )
}
const CareProviderProfileFilters = [
    <SearchInput source="q" alwaysOn/>,
];
export const CareProviderList = () => {
    return (
        <List
        
            exporter={false}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={CareProviderProfileFilters}
            actions={<ListActions />}
        >
            <Datagrid
                rowClick="show"
                header={<DatagridHeader />}
            >
                <TextField source="providerCode" color={'#37419A'} style={{fontWeight: 500}}/>
                <UserField />
                <TextField source="category" color={'#37419A'}  style={{fontWeight: 500}}/>
                <TextField source="address" color={'#37419A'} />
                <TextField source="zip" color={'#37419A'} />
                <TextField source="city" color={'#37419A'} />

                <FunctionField  color={'#37419A'} fontSize={14}
                render={(record) => {
                   return (!record.workExperienceSince )? 'Non renseigné' :  formatWorkExperience(record.workExperienceSince)
                }} />
                <TextField source="scholarLevel" color={'#37419A'} />

                <TagsField />
                <StarsField />
                <TextField source="status" color={'#37419A'} />
            </Datagrid>
        </List>
    )
}
