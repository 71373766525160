import { TableRow } from "@mui/material"
import { AutocompleteInput, CreateButton, Datagrid, FilterButton, FunctionField, List, ReferenceInput, SearchInput, SelectInput, SortButton, TextField, TopToolbar } from "react-admin"
import TableCellCustom from "../../common/components/TableCellCustom"
import { CustomeTableHead } from "../../common/style/style"
import { formatDate } from "../../utils/DateFormat"
import { formatUserFullName } from "../../utils/Stringhelps"

const DatagridHeader = () => {
    return (
        <CustomeTableHead>
            <TableRow >
                <TableCellCustom />
                <TableCellCustom name="Code" />
                <TableCellCustom name="Date de création" />
                <TableCellCustom name="Client" />
                <TableCellCustom name="Prestataire" />
                <TableCellCustom name="statut" />
                <TableCellCustom name="Debut" />
                <TableCellCustom name="Type" />
                <TableCellCustom name="Cetegory" />
             </TableRow>   
        </CustomeTableHead>
    )
  }

const ListActions = () => {

    return (
        <TopToolbar sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterButton /> 
            <SortButton fields={['createdAt', 'category']} />
            <CreateButton />
        </TopToolbar>
    )
}

const contractFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput source="status" choices={[
        { id: 'ACTIVE', name: 'actif' },
        { id: 'INACTIVE', name: 'inactif' },
    ]} />,
    <ReferenceInput source="careRecipientProfile" reference="care-recipient-profiles" allowEmpty>
        <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
            key={record => record.id}
            fullWidth
        />
    </ReferenceInput>,
    <ReferenceInput source="careProviderProfile" reference="care-provider-profiles" allowEmpty>
        <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
            key={record => record.id}
            fullWidth
        />
    </ReferenceInput>,

];

export const ContractList = () => {
    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            actions={<ListActions />}
            filters={contractFilters}
        >
            <Datagrid
                rowClick="edit"
                header={<DatagridHeader />}
            >
                <TextField source="contractCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="createdAt" 
                render={record => {
                    const d = new Date(record.createdAt);
                    return formatDate(d);
                }}
                    color={'#37419A'}
                />
                <FunctionField source="Client" color={'#37419A'} style={{ fontWeight: 700 }}
                    render={record => {
                        return formatUserFullName(record.careRecipient);
                    }}
                />
                <FunctionField source="Prestataire" color={'#37419A'} style={{ fontWeight: 700 }}
                    render={record => {
                        if(!record.careProvider) return '';
                        return formatUserFullName(record.careProvider.user);
                    }}
                />
                <FunctionField source="status" color={'#37419A'} style={{ fontWeight: 500 }} 
                
                    render={record => {
                        if (record.status === "ACTIVE") return "Actif";
                        if (record.status === "INACTIVE") return "Inactif";
                        return "";
                    }}
                />
                <FunctionField source="startingDate" render={record => {
                    const d = new Date(record.startingDate);
                    return formatDate(d);
                }}
                    color={'#37419A'}
                />
            
                <TextField source="contractType" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="category" color={'#37419A'} style={{ fontWeight: 500 }} />

            </Datagrid>
            
        </List>
    )

}