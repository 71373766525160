import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Name } from '../../common/style/style';
import { capitalizeWord } from '../../utils/Stringhelps';


const FullNameField = () => {
    const record = useRecordContext();
    return record ? (
        <Name
            variant="p" 
        >
            {record.lastName.toUpperCase()}  {capitalizeWord(record.firstName)} 
        </Name>
    ) : null;
}

export default FullNameField;