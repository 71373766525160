import React, { useEffect, useState } from 'react'
import { DashboardImg } from './Style'
import backGroundImage from '../../assets/hoomiz_band.png'
import CardWithIcon from './CardWithIcon'
import FavoriteIcon from '@mui/icons-material/Favorite';
import Elderly from '@mui/icons-material/Elderly';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import { useQuery } from "react-query";
import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button, useDataProvider } from 'react-admin';
import { API_URL } from '../../utils/constants';
import ApiConstants from '../../api/ApiConstants';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';


const Dashboard = () => {
    const [providerNumbers, setProviderNumbers] = useState({});
    const [recipientNumbers, setecipientNumbers] = useState({});
    const [interventionNumbers, setInterventionNumbers] = useState({});
    const [interventionLateNumbers, setInterventionLateNumbers] = useState({});

    const dataProvider = useDataProvider();
    useQuery(
        'interventionNumbers',
        () => dataProvider.getOnApi(
            API_URL + ApiConstants.GET_INTERVENTIONS_NUMBERS, null),
        {
            onSuccess: (data) => {
                if (data) {
                    setInterventionNumbers(data.data);
                }
            }
        }
    );

    useQuery(
        'providerNumbers',
        () => dataProvider.getOnApi(
            API_URL + ApiConstants.GET_PROVIDER_NUMBERS, null),
        {
            onSuccess: (data) => {
                if (data) {
                    setProviderNumbers(data.data);
                }
            }
        }
    );
    useQuery(
        'recipientNumbers',
        () => dataProvider.getOnApi(
            API_URL + ApiConstants.GET_RECIPIENT_NUMBERS, null),
        {
            onSuccess: (data) => {
                if (data) {
                    setecipientNumbers(data.data);
                }
            }
        }
    );
    useQuery(
        'interventionLateNumber',
        () => dataProvider.getOnApi(
            API_URL + ApiConstants.INTERVENTION_LATE, null),
        {
            onSuccess: (data) => {
                if (data) {
                    setInterventionLateNumbers(data.data);
                }
            }
        }
    );

    useEffect(() => {
        document.title = 'Hoomiz - Dashboard'

    }, [])
  
    const handleRedirectNewBackOffice = () => {
        if (window.location.href.includes('https://admin.hoomiz.care')) {
            window.location.href = 'https://v2-admin.hoomiz.care/'
        } 

        if (window.location.href.includes('https://preprod-admin.hoomiz.care/')) {
            window.location.href = 'https://v2-preprod-admin.hoomiz.care/'
        }

       
    }

    return (
        <DashboardImg src={backGroundImage} alt="image" >

            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden', padding: '200px' }}>
                <Button  variant="contained" color="primary" sx={{ margin: '10px' }}
                    onClick={handleRedirectNewBackOffice}
                >
                    <Typography variant="h6" sx={{ color: 'white' }}>
                        Nouveau back office
                    </Typography>
                </Button>
            </Box>
        </DashboardImg>
    )
}

export default Dashboard