import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Datagrid, FunctionField, ListContextProvider, useGetList, useList, useRecordContext, TextField, ChipField } from "react-admin";
import { CustomeTableHead } from "../../common/style/style";
import { TableRow, Typography } from "@mui/material";
import TableCellCustom from "../../common/components/TableCellCustom";
import { formatDate } from "../../utils/DateFormat";
import { formatUserFullName } from "../../utils/Stringhelps";
import TagsField from "./TagsField";



const DatagridHeader = () => {
    return (
        <CustomeTableHead>
            <TableRow >
                <TableCellCustom />
                <TableCellCustom name="Code" />
                <TableCellCustom name="Date de création" />
                <TableCellCustom name="Client" />
                <TableCellCustom name="Prestataire" />
                <TableCellCustom name="statut" />
                <TableCellCustom name="Debut" />
                <TableCellCustom name="Passages" />
                <TableCellCustom name="Amplitude" />

            </TableRow>
        </CustomeTableHead>
    )
}


const ChedulesField = ({ schedules }) => {

    const getSchedulesDays = (schedules) => {
        let days = [];
        schedules.forEach((schedule) => {
            days.push(schedule.day);
        })

        return [...new Set(days)];;
       
    }

    return (
        <Box>
            {getSchedulesDays(schedules).map((day) => {
                return (
                    <span>
                       <ChipField 
                            record={{name:day}}
                            source="name" 
                            sx={{
                                marginRight:1,
                                marginBottom:1,
                                backgroundColor: '#37419A',
                                color: '#fff',
                                fontSize: '12px',
                            }}
                       />

                    </span>
                )
            })}
        </Box>
    )

}
const ContractDuration = ({ schedules }) => {

    const [duration, setDuration] = useState({
        hours: 0,
        minutes: 0
    });

  useEffect(() => {
        setDuration(getSchedulesDuration(schedules))
    }, [schedules])

    const getSchedulesDuration = (schedules) => {
        let hours = 0;
        let minutes = 0;

        schedules.forEach((schedule) => {
            hours = hours + (schedule.endingHour - schedule.startingHour);
            minutes = minutes + (schedule.endingMinute - schedule.startingMinute);
            if (minutes < 0) {
                minutes = minutes + 60;
                hours = hours - 1;
            }
            if (minutes > 60) {
                minutes = minutes - 60;
                hours = hours + 1;
            }
          


        })
       
    // double digit
      



    return {
        hours: hours,
        minutes: minutes
    }
       
    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom component="div"
                color="#3f51b5"
                fontWeight={600}
            >  
                {duration.hours + "h" + duration.minutes + "min"} / semaine
            </Typography>
        </Box>
    )

}

export const CareRecipientContracts = () => {
    const record = useRecordContext();
    const [recipientId] = useState(record.id);
    const [page] = useState(1);
    const [perPage] = useState(50);

   
   const { data, isLoading, total, totalPages
    } = useGetList(
        'contracts',
        {
            filter: {
                careRecipientProfile: recipientId,
            },
            pagination: {
                page: page,
                perPage: perPage,
            },
            sort: {
                field: 'createdAt',
                order: 'DESC',
            },
        }
    );
    

  

    const listContext = useList( { data,isLoading,total,totalPages,page,perPage})

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: 2,
               paddingBottom: 5

            }}

        >   
            <ListContextProvider
                value={listContext}
            >
                <Datagrid
                    header={<DatagridHeader />}
                    isRowSelectable={record => false}
                    rowClick={
                        (id, basePath, record) => {
                            return `/contracts/${id}`
                        }
                    }
                >
                    <TextField source="contractCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                    <FunctionField source="createdAt" render={record => {
                        const d = new Date(record.createdAt);
                        return formatDate(d);
                    }}
                        color={'#37419A'}
                    />
                    <FunctionField source="Prestataire" color={'#37419A'} style={{ fontWeight: 700 }}
                        render={record => {
                            if (!record.careProvider) return '';
                            return formatUserFullName(record.careProvider.user);
                        }}
                    />
                    <FunctionField source="Client" color={'#37419A'} style={{ fontWeight: 700 }}
                        render={record => {
                            return formatUserFullName(record.careRecipient);
                        }}
                    />
                    
                    <FunctionField source="status" color={'#37419A'} style={{ fontWeight: 500 }}

                        render={record => {
                            if (record.status === "ACTIVE") return "Actif";
                            if (record.status === "INACTIVE") return "Inactif";
                            return "";
                        }}
                    />
                    <FunctionField source="startingDate" render={record => {
                        const d = new Date(record.startingDate);
                        return formatDate(d);
                    }}
                        color={'#37419A'}
                    />

                    <FunctionField source="schedules" 
                    render={record => {
                        return <ChedulesField schedules={record.schedules} />
                    }
                    }
                    color={'#37419A'}
                    />

                    <FunctionField source="duration" 
                    render={record => {
                        return <ContractDuration schedules={record.schedules} />
                    }
                    }
                    color={'#37419A'}
                    />

                </Datagrid>
            </ListContextProvider>
        </Box>
    )
}

