import { PresenceSheetList } from "./presencesheetList";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { PresenceSheetShow } from "./presenceSheetShow";
import { PresenceSheetEdit } from "./presencesheetEdit";
import { PresenceSheetCreate } from "./presencesheetCreate";


export const Presencesheet = {
    list: PresenceSheetList,
    create: PresenceSheetCreate,
    edit: PresenceSheetEdit,
    show: PresenceSheetShow,
    icon: AssignmentIcon
};