import { UserList } from "./UserList";
import { UserCreate } from "./UserCreate";
import { UserEdit } from "./UserEdit";
import userIcon from '@mui/icons-material/People';


export const Users =  {
     list: UserList, 
     create: UserCreate,
     edit: UserEdit,
     icon: userIcon
 };