import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { API_URL } from "../utils/constants";

const apiUrl = API_URL;
const httpClient = fetchUtils.fetchJson;


export const  DataProvider = {
    getList: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sortBy: JSON.stringify( `${[field]}:${order.toLowerCase()}`),
            limit: JSON.stringify(perPage),
            filter: JSON.stringify(params.filter),
            page: JSON.stringify(page),
        };
        const url = `${apiUrl}${resource}?${stringify(query)}`;
        return httpClient(
            url,
            {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                }),
            }
            ).then(({ headers, json }) => ({
            data: json.results ? json.results : [],
            total: parseInt(json.totalResults),
            totalPages: parseInt(json.totalPages), 
        }));
    },
    create: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        return httpClient(`${apiUrl}${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    getOne: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        return httpClient(`${apiUrl}${resource}/${params.id}`, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    update: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        return httpClient(`${apiUrl}${resource}/${params.id}`, {
            method: "PATCH",
            body: JSON.stringify(params.data),
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    delete: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        return httpClient(`${apiUrl}${resource}/${params.id}`, {
            method: "DELETE",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    deleteMany: (resource, params) => {
        console.log("deleteMany", params, resource);
        const { token } = JSON.parse(localStorage.getItem("token"));
        return httpClient(`${apiUrl}${resource}`, {
            method: "DELETE",
            body: JSON.stringify({ ids: params.ids }),
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    getMany: (resource, params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${apiUrl}${resource}?${stringify(query)}`;
        return httpClient(url, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    getOnApi: (url,queryParams) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        const query = queryParams ? `?${stringify(queryParams)}` : null;
        query ? url = `${url}${query}` : url = `${url}`;
        return httpClient(`${url}`, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    deleteOnApi: (url,queryParams) => {
        const { token } = JSON.parse(localStorage.getItem("token"));
        const query = queryParams ? `?${stringify(queryParams)}` : null;
        query ? url = `${url}${query}` : url = `${url}`;
        return httpClient(`${url}`, {
            method: "DELETE",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    postOnApi: (url,params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));

        return httpClient(`${url}`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    },
    patchOnApi: (url,params) => {
        const { token } = JSON.parse(localStorage.getItem("token"));

        return httpClient(`${url}`, {
            method: "PATCH",
            body: JSON.stringify(params),
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then(({ json }) => ({ data: json }));
    }
};

export default DataProvider;