import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ArrayField, Datagrid, FunctionField, ListContextProvider, Show, Tab, TabbedShowLayout, TextField, useDataProvider, useList, useRecordContext } from "react-admin";
import TableCellCustom from "../../common/components/TableCellCustom";
import { CustomeTableHead } from "../../common/style/style";
import { formatDate, formatWorkExperience, formatWorkExperienceRange } from "../../utils/DateFormat";
import { Status } from "./JobEdit";
import { useQuery } from "react-query";
import { API_URL } from "../../utils/constants";
import ApiConstants from "../../api/ApiConstants";
import React from "react";
import StarsField from "../careprovider/StarsField";
import UserField from "../careprovider/UserField";
import { tristateBoolean } from "../../common/utils";

const countHours = (record) => {
    if (!record) return "";
    if (record.schedules.length === 0) {
        return 0
    }
    let totalHour = 0;
    let totalMinute = 0;
    record.schedules.forEach((item) => {
        totalHour = totalHour + Math.abs(item.endingHour) - Math.abs(item.startingHour);
        totalMinute = totalMinute + Math.abs(item.endingMinute) - Math.abs(item.startingMinute);

    }

    )
    return `${totalHour} h ${totalMinute} min`
}

const showWorkExperienceRange = (workExperienceRange) => {
    if (!workExperienceRange) return "";
    return formatWorkExperienceRange(workExperienceRange)
}

const DatagridHeaderMatch = () => {
    return (
        <CustomeTableHead>
            <TableCellCustom name="Code Auxi" />
            <TableCellCustom name="Utilisateur" />
            <TableCellCustom name="category" />
            <TableCellCustom name="Adresse" />
            <TableCellCustom name="Ville" />
            <TableCellCustom name="code postal" />
            <TableCellCustom name="Experience" />
            <TableCellCustom name="Formation" />
            <TableCellCustom name="Note" />
            <TableCellCustom name="Score" />
            <TableCellCustom name="Distance du lieu" />
        </CustomeTableHead>
    )
}
const countLineByDay = (item) => {
    if (!item) return "";
    let totalHour = Math.abs(item.endingHour) - Math.abs(item.startingHour);
    let totalMinute = Math.abs(item.endingMinute) - Math.abs(item.startingMinute);
    if (totalMinute < 0) {
        totalMinute = Math.abs(totalMinute);
    }
    return `${totalHour} h ${totalMinute} min`
}
const HeaderInfo = () => {
    const record = useRecordContext();
    return (
        <span>{countHours(record)}</span>
    )
}

const getTreeStateBooleanName = (value) => {

    if (value === undefined) return "indifférent";
    return tristateBoolean.find(s => s.id === value).name

} 

const showDistanceFrom = (dist) => {
    if (!dist) return "";
    const { calculated } = dist;
    if (!calculated) return "";
    return (calculated / 1000).toFixed(2) + " km"
}


const MatchingCareProviders = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [data, setData] = React.useState([]);
    useQuery("matchingCareProviders",
        () => dataProvider.getOnApi(
            API_URL + ApiConstants.GET_PROVIDER_JOBS.replace("{jobId}", record.id)
            ,
            null),
        {
            onSuccess: (data) => {
                if (data) {
                    setData(data.data.results);
                }
            },
            onError: (error) => {
                console.log(error)
            }
        }
    )

    const listContext = useList({ data });


    return (
        <ListContextProvider value={listContext}>
            <Datagrid
                header={<DatagridHeaderMatch />}
                bulkActionButtons={false}
                redirect='show'
            >
                <TextField source="providerCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <UserField />
                <TextField source="category" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="address" color={'#37419A'} />
                <TextField source="city" color={'#37419A'} />
                <TextField source="zip" color={'#37419A'} />
                <FunctionField color={'#37419A'} fontSize={14}
                    render={(record) => {
                        return (!record.workExperienceSince) ? 'Non renseigné' : formatWorkExperience(record.workExperienceSince)
                    }} />
                <TextField source="scholarLevel" color={'#37419A'} />
                <StarsField />
                <TextField source="score" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField color={'#37419A'} style={{ fontWeight: 700 }}
                    fontSize={"1rem"}
                    render={(record) => {
                        console.log(record)
                        return showDistanceFrom(record.dist)
                    }} /> 
                </Datagrid>
        </ListContextProvider>
    )
}


const JobSchedule = () => {
    const record = useRecordContext();
    if (!record.schedules.length === 0) return "";
    return (
        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

            <ArrayField source="schedules">
                <Datagrid
                    header={<DatagridHeader />}
                    bulkActionButtons={false}


                >
                    <FunctionField source='day'
                        label="Jour"
                        render={record => `${record.day}`}
                        fontSize="1rem"
                        color="#3f51b5" />
                    <FunctionField source='startingHour'
                        label="Heure de début"
                        render={record => `${record.startingHour}`}
                        fontSize="1rem"
                        color="#3f51b5" />

                    <FunctionField

                        render={record => ` `}
                        fontSize="1rem"
                        color="#3f51b5" />

                    <FunctionField source='startingMinute'
                        label="minute de début"
                        render={record => `${record.startingMinute}`}
                        fontSize="1rem"
                        color="#3f51b5" />


                    <FunctionField source='endingHour'
                        label="Heure de début"
                        render={record => `${record.endingHour}`}
                        fontSize="1rem"
                        color="#3f51b5" />
                    <FunctionField source='endingMinute'
                        label="minute de début"
                        render={record => `${record.endingMinute}`}
                        fontSize="1rem"
                        color="#3f51b5" />
                    <FunctionField source='endingMinute'
                        label="minute de début"
                        render={record => `${countLineByDay(record)}`}
                        fontSize="1rem"
                        color="#3f51b5" />
                </Datagrid>
            </ArrayField>
        </Box>)

}

const DatagridHeader = () => {

    return (
        <CustomeTableHead>
            <TableCellCustom name="Jour de passage"

            />

            <TableCellCustom name="Heure de passage" />
            <TableCellCustom />
            <TableCellCustom name='Minute de passage' />
            <TableCellCustom name='Heure de fin de passage' />
            <TableCellCustom name='Minute de fin de passage ' />
            <TableCellCustom name='durée' />
        </CustomeTableHead>
    )
}
export const JobShow = () => {

    return (
        
        <Show title="Afficher une Demande" sx={{ width: "100%" }}>
                <TabbedShowLayout
                >
                    <Tab label="Informations">
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}

                        >
                            Informations générales
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>

                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientFullName'
                                    render={record => `${record.recipientFullName}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientGender'
                                    render={record => `${record.recipientGender === 'MALE' ? "homme" : "femme"}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientAge'
                                    render={record => `${record.recipientAge} ans`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientPhone'
                                    render={record => `${record.recipientPhone}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientEmail'
                                    render={record => `${record.recipientEmail}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>


                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>

                            <Box flex={3} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientAddress'
                                    render={record => `${record.recipientAddress}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientCity'
                                    render={record => `${record.recipientCity}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='recipientZipCode'
                                    render={record => `${record.recipientZipCode}`}
                                    fontSize="1rem"
                                    color="#3f51b5"

                                />
                            </Box>



                        </Box>
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}

                        >
                            Mission
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>

                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobCategory'
                                    render={record => `${record.jobCategory}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobType'
                                    render={record => `${record.jobType}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobPrice'
                                    render={record => `${record.jobPrice} €`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobPriceType'
                                    render={record => `${record.jobPriceType}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobStartDate'
                                    render={record => `${formatDate(new Date(record.jobStartDate))} -  ${record.jobStartHour} : ${record.jobStartMinute}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>

                            <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobDescription'
                                    render={record => `${record.jobDescription}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <FunctionField source='jobStatus'
                                    render={record => `${Status.find(s => s.id === record.jobStatus).name}`}
                                    fontSize="1rem"
                                    color="#3f51b5" />

                            </Box>


                        </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}
                    >
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}
                        >
                            Genre de l'auxiliaire
                        </Typography>

                    </Box>
                    <Box

                        flex={1}
                        mr={{ xs: 0, sm: '1em' }}

                    >
                        <FunctionField
                            render={(record) => `${record.providerGender === "ANY" ? "Indifférent" : record.providerGender    }`}
                            color={'primary'} fontSize="1rem"
                        />
                    </Box>
                
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}
                    >
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}
                        >
                            Personalité de l'auxiliaire
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',

                        }}
                    >
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                              Dynamique
                            </Typography>
                            <FunctionField
                                render={(record) => {
                                    return getTreeStateBooleanName(record.hasDinamycPersonality)
                                }}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Aime prendre des initiatives
                            </Typography>
                            <FunctionField
                                render={(record) => {

                                    return getTreeStateBooleanName(record.likeToTakeInitiative)
                                }}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Douce
                            </Typography>
                            <FunctionField
                                render={(record) => {

                                    return getTreeStateBooleanName(record.isKind)
                                }}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Fort caractère
                            </Typography>
                            <FunctionField
                                render={(record) => {

                                    return getTreeStateBooleanName(record.hasStrongTemperament)
                                }}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}
                    >
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}
                        >
                            Experience demandée
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}
                    >
                        <FunctionField
                            render={(record) => {
                                
                                return showWorkExperienceRange(record.workExperienceRange)
                            }}
                            color={'primary'} fontSize="1rem"

                         />

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}
                    >
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}
                        >
                            Services
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',

                        }}
                    >
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Entretien du logement
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithHousework ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Entretien du linge
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithLaundry ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Aide aux courses
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithGroceries ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Aide aux repas
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithMeals ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Promenade et sorties
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithWalkAndFunActivities ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Aide à la toilette
                            </Typography>
                            <FunctionField
                                render={(record) => `${record.helpWithToileting ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Compagnie

                            </Typography>
                            <FunctionField
                                render={(record) => `${record.companionship ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                       
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Garde de nuit 

                            </Typography>
                            <FunctionField
                                render={(record) => `${record.nightWatch ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                            >
                                Garde de jour

                            </Typography>
                            <FunctionField
                                render={(record) => `${record.dayWatch ? "Oui" : "Non"}`}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        
                    </Box>
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}

                        >
                            Passages (<HeaderInfo />)
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            <JobSchedule />
                        </Box>
                    </Tab>
                    <Tab label="Matchs">
                        <MatchingCareProviders />
                    </Tab>
                </TabbedShowLayout>
            </Show>
    );
};