import React from "react";
import GoogleMapReact from 'google-map-react';
import { Box, Typography } from "@mui/material";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
const Marker= ({ text }) => {
    return (
        
        <Box>
            <FmdGoodIcon
                color="primary"
                fontSize={"large"}
            />
            <Typography variant="caption" color="primary"
                sx={{ textAlign: "center",fontSize: "1rem",fontWeight: "bold" }}
            >
             {text}
             </Typography>
        </Box>
          
          
    );
}
  

export const MapComp = (props) => {
    const defaultProps = {
        center: {
            lat: 48.866667,
            lng: 2.333333
        },
        zoom: 6
    };

    return (
        // Important! Always set the container height explicitly
        <Box
            sx={{
                height: window.innerHeight * 0.4,
                width: window.innerWidth*0.8,
            }}
         >
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <Marker
                    lat={48.866667}
                    lng={2.333333}
                    text="My Marker"
                />
            </GoogleMapReact>
        </Box>
    );
}

export const LineMarkedMap = (props) => {
   
    const defaultProps = {
        center: {
            lat: 48.866667,
            lng: 2.333333
        },
        zoom: 13
    };

    return (
        <Box
            sx={{
                height: window.innerHeight * 0.4,
                width: window.innerWidth*0.8,
            }}
         >
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                polyline={props.polyline}
            >
                <Marker
                    lat={props.origin.lat}
                    lng={props.origin.lng}
                    text={props.origin.text}
                />
                <Marker
                    lat={props.destination.lat}
                    lng={props.destination.lng}
                    text={props.destination.text}
                />
            </GoogleMapReact>
        </Box>
    );
}