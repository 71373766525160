import {  Rating, Typography } from "@mui/material"
import { Box } from "@mui/system"
import {  FunctionField, Show, Tab, TabbedShowLayout, Title, useRecordContext } from "react-admin"
import { formatWorkExperience } from "../../utils/DateFormat"
import { formatUserFullName } from "../../utils/Stringhelps"
import CareProviderAvailability from "./CareProviderAvailability"
import { CareProvidersInterventionList } from "./CareProvidersInterventionList"
import { useEffect, useState } from "react"
import { tristateBoolean } from "../../common/utils"
import { CareProviderPresenceSheets } from "./CareProviderPresenceSheet"

const ShowTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Title title={formatUserFullName(record.userId)} />;
};


const getTreeStateBooleanName = (value) => {

    if (value === undefined) return "indifférent";
    return tristateBoolean.find(s => s.id === value).name

}

export const CareProvidersShow = () => {
    const record = useRecordContext();


    return (
       
        <Show
            title={<ShowTitle />}
                sx={{
                     width: "100%",
                    justifyContent: 'center',
                    alignItems: 'center',
             }}
        >

            <TabbedShowLayout
            >
                <Tab label="Informations">
                    <Typography variant="h6" gutterBottom component="div"
                        color="#3f51b5"
                        fontWeight={600}

                    >
                    </Typography>
            <Box>
                <Box
                    sx={{
                        borderRadius: '5px',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: '1em',

                        }}
                    >
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Informations générales
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                                rowClick="show"

                            >

                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}
                                
                                >

                                    <FunctionField disabled
                                        source="providerCode"
                                        render={(record) => `${record.providerCode}`}
                                        color={'primary'} fontSize="1rem"


                                    />
                                </Box>
                                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                                            <FunctionField disabled label="Utilisateur" fullWidth
                                                render={(record) => `${formatUserFullName(record.userId)}`}
                                                color={'primary'} fontSize="1rem"
                                            />
                                        </Box>
                                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                                            <FunctionField disabled label="Utilisateur" fullWidth
                                                render={(record) => `${record.providerGender === 'FEMALE' ?
                                                    "Femme" : "Homme" }`}
                                                color={'primary'} fontSize="1rem"
                                            />
                                        </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                                    <FunctionField disabled label="Utilisateur" fullWidth
                                        render={(record) => `${"" + record.userId.mobilePhone}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }} >
                                    <FunctionField disabled label="Utilisateur" fullWidth
                                        render={(record) => `${"" + record.userId.email}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }} gutterBottom>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.address}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.zip + " " + record.city}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.spokenLanguages}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.category}`}
                                        color={'primary'} fontSize="1rem"
                                        fontWeight={"bold"}
                                    />
                                </Box>
                            </Box>

                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Localisation
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                                rowClick="show"

                            >

                                <Box flex={1} mr={{ xs: 0, sm: '1em' }} gutterBottom>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.address}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.zip + " " + record.city}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                            </Box>
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Zone d'intervention
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                                rowClick="show"

                            >
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.zipCodes}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                            </Box>
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Remarque
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                                rowClick="show"

                            >
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.anotations}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box

                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Statut
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                                rowClick="show"

                            >

                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.status === "ACTIVE"? "Actif" : "Inactif"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${record.scholarLevel}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => `${formatWorkExperience(record.workExperienceSince)}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <FunctionField disabled fullWidth
                                        render={(record) => {
                                            return (
                                                <Rating value={record ? record.rating : ""} style={{
                                                    fontSize: '1.8rem',
                                                    fontWeight: 700,
                                                }} readOnly />
                                            )
                                        }}
                                        color={'primary'} fontSize="1rem"
                                    />
                                    

                                </Box>

                                
                            </Box>
                            
                        </Box>

                    </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '1em',

                                }}
                            >
                                <Typography variant="h6" gutterBottom component="div"
                                    color="#3f51b5"
                                    fontWeight={600}
                                >
                                    Personalité de l'auxiliaire
                                </Typography>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    padding: '1em',


                                }}
                            >
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Dynamique
                                    </Typography>
                                    <FunctionField
                                        render={(record) => {
                                            return getTreeStateBooleanName(record.hasDinamycPersonality)
                                        }}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Aime prendre des initiatives
                                    </Typography>
                                    <FunctionField
                                        render={(record) => {

                                            return getTreeStateBooleanName(record.likeToTakeInitiative)
                                        }}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Douce
                                    </Typography>
                                    <FunctionField
                                        render={(record) => {

                                            return getTreeStateBooleanName(record.isKind)
                                        }}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Fort caractère
                                    </Typography>
                                    <FunctionField
                                        render={(record) => {

                                            return getTreeStateBooleanName(record.hasStrongTemperament)
                                        }}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '1em',

                                }}
                            >
                                <Typography variant="h6" gutterBottom component="div"
                                    color="#3f51b5"
                                    fontWeight={600}
                                >
                                    Auxiliaire favorite
                                </Typography>

                            </Box>
                            <Box

                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                                padding={"1em"}

                            >
                                <FunctionField
                                    render={(record) => `${record.isVip ? "Oui" : "Non"}`}
                                    color={'primary'} fontSize="1rem"
                                />
                            </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '1em',

                        }}
                    >
                        <Typography variant="h6" gutterBottom component="div"
                            color="#3f51b5"
                            fontWeight={600}
                        >
                            Services
                        </Typography>
                        
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: '1em',

                        }}
                    >
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Entretien du logement
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithHousework ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Entretien du linge
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithLaundry ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Aide aux courses
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithGroceries ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Aide aux repas
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithMeals ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Promenade et sorties
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithWalkAndFunActivities ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Aide à la toilette
                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.helpWithToileting ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Compagnie

                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.companionship ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>

                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Garde de nuit

                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.nightWatch ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                                <Box

                                    flex={1}
                                    mr={{ xs: 0, sm: '1em' }}

                                >
                                    <Typography variant="h6" gutterBottom component="div"
                                        color="#3f51b5"
                                    >
                                        Garde de jour

                                    </Typography>
                                    <FunctionField
                                        render={(record) => `${record.dayWatch ? "Oui" : "Non"}`}
                                        color={'primary'} fontSize="1rem"
                                    />
                                </Box>
                       
                    </Box>
                </Box>
            </Box>
            </Tab>
                <Tab label="Disponibilités" path='availability'>

                <Box>
                    <Box
                        sx={{
                            borderRadius: '5px',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',

                        }}
                    >
                    <Typography variant="h6" gutterBottom component="div"
                        color="#3f51b5"
                        fontWeight={600}

                    >
                    </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    padding: '1em',

                                }}
                            >
                                <CareProviderAvailability
                                    availabilityId={record ? record.availability : ""}
                                />

                            </Box>
                    </Box>
                    </Box>
            </Tab>
            <Tab label="Interventions" path='interventions'>
                    <Box
                        sx={{
                            borderRadius: '5px',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',

                        }}
                    >                    
                    <Box>

                    </Box>
                        <Box>
                            <CareProvidersInterventionList providerId={
                            record ? record.id : ""
                        } 

                        />
                        </Box>          
                </Box>
            </Tab>
            <Tab label="Feuilles de presence"
                  sx={{
                      width: "100%",
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}

                  path="presencesheets"
              >
                    <CareProviderPresenceSheets
                        careProviderId={
                            record ? record.id : ""
                        }
                    />
              </Tab>   
            </TabbedShowLayout>

        </Show>
    )
}