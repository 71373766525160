import { Box, Typography } from "@mui/material"
import { Container } from "@mui/system"
import React, { useEffect } from "react"
import { AutocompleteInput, Create, DateTimeInput, NumberInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, useDataProvider } from "react-admin"
import { PlaceAutoCompleteField } from "../../common/components/PlaceAutoCompleteField"
import { loadGMaps } from "../../utils/loadMaps"
import { formatUserFullName } from "../../utils/Stringhelps"
import { useFormContext } from 'react-hook-form';
import { months,years } from "../../common/utils"

const categories = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    },
    {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }]
const PricingTypes = [
    {
        id: "FORFAIT", name: "Forfait",

    }, {
        id: "TAUX_HORAIRE", name: "Taux horaire",
    }]

const Status = [
    {
        id: "pending", name: "En attente",

    }
    , {
        id: "inProgress", name: "En cours",
    },
    {
        id: "completed", name: "Terminée",
    },
    {
        id: "cancelled", name: "Annulée",
    },
]

const tasks = [
    {
        id: "laundryCare", name: "Entretien du linge",

    },
    {
        id: "helpWithShopping", name: "Aide aux courses",
    },
    {
        id: "helpGettingUp", name: "Aide au lever",
    },
    {
        id: "helpWithMeals", name: "Aide aux repas",
    },
    {
        id: "toiletAid", name: "Aide aux toilettes",
    },
    {
        id: "bedTimeHelp", name: "Aide au coucher",
    }
]

const validateForm = (values) => {
    //const errors = {};
}




export const PresenceSheetCreate = () => {
    const dataProvider = useDataProvider();
    const [careRecipient, setCareRecipient] = React.useState(null);

    const transform = (data) => {
        data.careProviderId = data.careProvider.id;
        data.careRecipientId = data.careRecipient.id;
        delete data.careProvider;
        delete data.careRecipient;
        return {
            ...data,
        }
    }


    const handleRecipentChange = async (value) => {
        const { data } = await dataProvider.getOne("care-recipient-profiles", { id: value })
        setCareRecipient(data);
    }
    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        }}
        >
            <Create
                title={"Creer une feuille de présence"}
                transform={transform}
                validateForm={validateForm}
                sx={{ width: '80%' }}

            >
                <SimpleForm
                    validate={validateForm}
                >
                         <Typography variant="p" gutterBottom component="div"
                                style={
                                    {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                    }
                                }
                            >
                                Ajouter l'auxiliaire et le bénéficiaires :
                            </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row',width: '100%'  }}>
                         <Box flex={1} mr={{ xs: 1, sm: '1em' }}>
                                <ReferenceInput source="careRecipient.id" fullWidth
                                    reference="care-recipient-profiles"


                                >
                                    <AutocompleteInput
                                        optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
                                        key={record => record.id}
                                        onChange={handleRecipentChange}
                                    />

                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                <ReferenceInput source="careProvider.id" fullWidth
                                    reference="care-provider-profiles"

                                >
                                    <AutocompleteInput
                                        optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
                                        key={record => record.id}

                                    />

                                </ReferenceInput>
                            </Box>
                    </Box>
                    <Typography variant="p" gutterBottom component="div"
                                style={
                                    {
                                        fontWeight: 'italic',
                                        fontSize: '1rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                    }
                                }
                            >
                                Ajouter le mois et l'année de la feuille de présence :

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' ,width: '100%' }}>
                
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                            <SelectInput source="monthNumber" isRequired choices={months} />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                            <SelectInput source="yearNumber" isRequired choices={years} />
                            </Box>

                    </Box>
                </SimpleForm>
            </Create>
        </Container>
    )
}