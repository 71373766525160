import {  Button } from "@mui/material";

export const DeleteHmzButton = ({ label, onClick, disabled, ...rest }) => {
    return (
       
            <Button
                onClick={onClick}
                disabled={disabled}
                {...rest}
                style={{
                    backgroundColor: '#ff3369',
                    color: 'white',
                    borderRadius: '0.5rem',
                    borderWidth: '1rem',
                    fontSize: '0.8rem',
                    padding: '0.7rem 1rem',
                    marginLeft: '0.5rem',
                    ...rest.style
                }}
            >
                {label}
            </Button>
    );
};



export const ValidateHmzButton = ({ label, onClick, disabled, ...rest }) => {
    return (
       
            <Button
                onClick={onClick}
                disabled={disabled}
                {...rest}
                style={{
                    background: '#00bfa6',
                    color: 'white',
                    fontSize: '0.8rem',
                    borderRadius: '0.5rem',
                    padding: '0.7rem 1rem',
                    marginLeft: '0.5rem',
                    ...rest.style
                }}
            >
                {label}
            </Button>

    );
};