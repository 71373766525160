import { Box, Typography, Button, Modal, TextField, Select, MenuItem } from "@mui/material";
import { useState,useEffect} from "react";
import { useDataProvider } from 'react-admin';
import * as React from "react";
import { useMutation } from 'react-query';
import { ArrayInput, AutocompleteInput, BooleanInput, Create, DateInput, NumberInput, ReferenceInput, SimpleForm, SimpleFormIterator } from "react-admin"
import ApiConstants from "../../api/ApiConstants"
import { API_URL } from "../../utils/constants"
import { hours, minutes } from "../../common/utils";
import { useNavigate } from 'react-router-dom';

export const CheckField = ({ open, visible, setAction, action, hoursValue,minutesValue,setHours,setMinutes}) => {
    const dataProvider = useDataProvider();
    const { status, id,startingDate,endingDate } = action.record;
    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 2

    };

    const handleChange = (event) => {
        setAction(event.target.value)
    };

    const checkBody = {
        interventionId: id,
        interventionDate: status === "pending" ? new Date(startingDate).setUTCHours(hoursValue, minutesValue, 0, 0) : new Date(endingDate).setUTCHours(hoursValue, minutesValue, 0, 0),

    }

    const { mutate: postCheck } = useMutation(

        () => dataProvider.postOnApi(API_URL + `${status === "pending" ? ApiConstants.CHECK_IN_INTERVENTION :  ApiConstants.CHECK_OUT_INTERVENTION}`, {  interventionId:id, interventionDate: status === "pending" ? new Date(startingDate).setUTCHours(hoursValue, minutesValue
            , 0, 0) : new Date(endingDate).setUTCHours(hoursValue, minutesValue, 0, 0) }),
        {
            onSuccess: () => {
                visible(false);
                navigate(-1)
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    return (
        <Modal
            open={open}
            onClose={() => visible(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {status === "pending" ? "Voulez-vous pointer l'arrivée de l'auxiliaire ?" : "Voulez-vous pointer le départ de l'auxiliaire ?"}
                </Typography>
                <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                    <Select source="hours" fullWidth defaultValue={hoursValue}  onChange={(e)=>setHours(e.target.value)}>
                        {hours.map((hour) => (
                            <MenuItem key={hour.id} value={hour.id}>
                                {hour.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography variant="h7" color={'primary'} fontSize="1.5rem"  alignSelf={"center"} marginRight={1} marginLeft={1} >H</Typography>
                    <Select source="hours" fullWidth defaultValue={minutesValue} onChange={(e)=>setMinutes(e.target.value)} >
                        {minutes.map((minute) => (
                            <MenuItem key={minute.id} value={minute.id}>
                                {minute.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography variant="h7" color={'primary'} fontSize="1.5rem"  alignSelf={"center"} marginRight={1} marginLeft={1} >Min</Typography>

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        paddingTop: 3
                    }}
                >
                    <Button
                        sx={{
                            color: 'white',
                        }
                        }
                        variant='contained'
                        onClick={() => postCheck()}
                    > Confirmer le pointage</Button>
                    <Button
                        sx={{
                            color: 'white',
                            backgroundColor: '#d32f2f',
                            '&:hover': {
                                backgroundColor: '#c62828',
                            },
                        }
                        }
                        variant='contained'
                        onClick={() => visible(false)}
                    > Annuler le pointage</Button>
                </Box>
            </Box>

        </Modal>




    )
}