export const hours = [
    { id: "00", name: "00" },
    { id: "01", name: "01" },
    { id: "02", name: "02" },
    { id: "03", name: "03" },
    { id: "04", name: "04" },
    { id: "05", name: "05" },
    { id: "06", name: "06" },
    { id: "07", name: "07" },
    { id: "08", name: "08" },
    { id: "09", name: "09" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "13", name: "13" },
    { id: "14", name: "14" },
    { id: "15", name: "15" },
    { id: "16", name: "16" },
    { id: "17", name: "17" },
    { id: "18", name: "18" },
    { id: "19", name: "19" },
    { id: "20", name: "20" },
    { id: "21", name: "21" },
    { id: "22", name: "22" },
    { id: "23", name: "23" },
]

export const months = [
    { id: "01", name: "Janvier" },
    { id: "02", name: "Février" },
    { id: "03", name: "Mars" },
    { id: "04", name: "Avril" },
    { id: "05", name: "Mai" },
    { id: "06", name: "Juin" },
    { id: "07", name: "Juillet" },
    { id: "08", name: "Aout" },
    { id: "09", name: "Septembre" },
    { id: "10", name: "Octobre" },
    { id: "11", name: "Novembre" },
    { id: "12", name: "Décembre" },
]

export const years = [
    { id: "2020", name: "2020" },
    { id: "2021", name: "2021" },
    { id: "2022", name: "2022" },
    { id: "2023", name: "2023" },
    { id: "2024", name: "2024" },
    { id: "2025", name: "2025" },
    { id: "2026", name: "2026" },
    { id: "2027", name: "2027" },
    { id: "2028", name: "2028" },
    { id: "2029", name: "2029" },
    { id: "2030", name: "2030" },
];

export const days = [
    { id: "LUNDI", name: "Lundi" },
    { id: "MARDI", name: "Mardi" },
    { id: "MERCREDI", name: "Mercredi" },
    { id: "JEUDI", name: "Jeudi" },
    { id: "VENDREDI", name: "Vendredi" },
    { id: "SAMEDI", name: "Samedi" },
    { id: "DIMANCHE", name: "Dimanche" },
];

export const minutes = [
    { id: "00", name: "00" },
    { id: "15", name: "15" },
    { id: "30", name: "30" },
    { id: "45", name: "45" },
    { id: "59", name: "59" },
];
    
export const gender = [
    { id: "MALE", name: "Homme" },
    { id: "FEMALE", name: "Femme" },
];

export  const threeGender = [
    { id: "Male", name: "Homme" },
    { id: "FEMALE", name: "Femme" },
    { id: "ANY", name: "Indifférent" },
];

export const categoriesPrestation = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    }, {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }
]
export const PricingTypes = [
    {
        id: "FORFAIT", name: "Forfait",

    }, {
        id: "TAUX_HORAIRE", name: "Taux horaire",
    }]
    
export const contractTypes = [
    {
        id: "RECURRENT", name: "récurrent",

    },
    {
        id: "ONE_TIME", name: "Pontuel",
    }
]

export const tristateBoolean = [
    { id: "YES", name: "Oui" },
    { id: "NO", name: "Non" },
    {id: "ANY" , name: "indifférent"}
]

export const workExperienceRange = [
    { id: "0-3", name: "Moins de 3 ans " },
    { id: "3-10", name: "Entre 3 et 10 ans" },
    { id: "10-", name: "Plus de 10 ans" },
]

