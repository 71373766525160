import { List, SearchInput, TextField, Datagrid, FunctionField, SelectInput, DateInput, ReferenceInput, AutocompleteInput } from "react-admin"
import { formatDateWithTime, printEffectiveTime } from "../../utils/DateFormat"
import { formatUserFullName } from "../../utils/Stringhelps"
import { ratingToString,translateStatusByText } from "../../utils/Stringhelps";
import { Chip, } from '@mui/material';
import { translateMonth,minutestoHourMin } from "../../utils/DateFormat";

const dateTommorow = new Date();
dateTommorow.setDate(dateTommorow.getDate() + 1);
const presenceSheetFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput source="status" choices={[
        { id: 'pending', name: 'En attente' },
        { id: 'validated', name: 'Terminé' },
        { id: 'dispute', name: 'Litige' },
    ]} />,
    <SelectInput source="dateMonth" choices={[
        { id: '1', name: 'Janvier' },
        { id: '2', name: 'Février' },
        { id: '3', name: 'Mars' },
        { id: '4', name: 'Avril' },
        { id: '5', name: 'Mai' },
        { id: '6', name: 'Juin' },
        { id: '7', name: 'Juillet' },
        { id: '8', name: 'Août' },
        { id: '9', name: 'Septembre' },
        { id: '10', name: 'Octobre' },
        { id: '11', name: 'Novembre' },
        { id: '12', name: 'Décembre' },

    ]} />,
    <ReferenceInput source="careRecipientId" reference="care-recipient-profiles"
        allowEmpty>
    <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
            key={record => record.id}
            fullWidth


    />
    </ReferenceInput>,

    <ReferenceInput source="careProviderId" reference="care-provider-profiles" allowEmpty>
        <AutocompleteInput
            optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
            key={record => record.id}
            fullWidth
        />
    </ReferenceInput>,



]



export const PresenceSheetList = () => {
    return (
        <List
            exporter={false}

            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={presenceSheetFilters}


        >

            <Datagrid
                rowClick="show"
            >
                <TextField source="presenceSheetCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="provider.fullName" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="recipient.fullName" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="monthNumber" render={record => `${translateMonth(record.monthNumber).toUpperCase()}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="yearNumber" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="status" render={record => `${translateStatusByText(record.status)}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField
                                source="totalMinutes"
                                render={record => ` ${minutestoHourMin(record.totalMinutes)}` }
                                color={'#37419A'} style={{ fontWeight: 500 }}
                            />                
                <FunctionField source="recipientHasSigned" render={record => `${record.recipientHasSigned ? "Oui" : "Non"}`} color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="careProviderHasSigned" render={record => `${record.careProviderHasSigned ? "Oui" : "Non"}`} color={'#37419A'} style={{ fontWeight: 500 }} />
            </Datagrid>
        </List>
    )
}