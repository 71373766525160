import { Chip } from "@mui/material";

const TagsList = ({table}) => {
  return (
      <span>
          {table.map((tag) => (
              <Chip label={tag} style={{ margin: 2, color: "white", background: '#37419A' }} 
              key={tag} />
          ))}
      </span>
  )
};

export default TagsList;