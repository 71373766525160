import { TableHead, Typography } from "@mui/material";
import styled from "styled-components";

export const Name = styled(Typography)`
    font-weight: bold;
    color: #37419A;
    display: block;
    padding: 0.1em;
    margin: 0.1em;

    `;
export const CustomeTableHead = styled(TableHead)`
    background-color: #F5F5F5;
    color: #37419A;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    `
