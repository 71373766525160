import { Box, Container, Typography } from "@mui/material"
import { useEffect } from "react"
import { ArrayInput, BooleanInput, Create, DateInput, NumberInput, SelectArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin"
import { PlaceAutoCompleteField } from "../../common/components/PlaceAutoCompleteField"
import { loadGMaps } from "../../utils/loadMaps"
import { capitalizeWord } from "../../utils/Stringhelps"
import { SelectUnlinkedUserFirld } from "./SelectUnlinkedUserField"
import { categoriesPrestation, contractTypes, days, gender, hours, minutes, PricingTypes, threeGender, tristateBoolean } from "../../common/utils";

export const validateForm = (values) => {
    const errors = {}
    if (!values.user) {
        errors.user = ['Le patient est obligatoire']
    }
    if (!values.zipCodes) {
        errors.zipCodes = ['Le code postal est obligatoire']
    }
    if (!values.address) {
        errors.address = ['L\'adresse est obligatoire']
    }
    if (!values.city) {
        errors.city = ['La ville est obligatoire']
    }
    if (!values.state) {
        errors.country = ['Le pays est obligatoire']
    }
    if (!values.zip) {
        errors.phone = ['Le numéro de téléphone est obligatoire']
    }

    if(!values.status){
        errors.status = ['Le statut est obligatoire']
    }
    if(!values.spokenLanguages){
        errors.spokenLanguages = ['La langue est obligatoire']
    }
    if(!values.category){
        errors.category = ['La catégorie est obligatoire']
    }

    if (!values) {
        errors.global = ['Veuillez remplir tous les champs obligatoires']
    }
    return errors
     
}


const transform = (data) => {
   
    const location = {
        type: "Point",
        coordinates: [data.lng, data.lat]
    }
    data.location = location
    data.userId = data.user
    data.scholarLevel = data.scholarLevel ? data.scholarLevel.toUpperCase() : null
    data.workExperienceSince =  new Date(data.workExperienceSince)
    delete data.lat;
    delete data.lng;
    delete data.place_id;
    return data
}

export const CareProviderCreate = () => {
     useEffect(
        () => {
           loadGMaps()
        },
        []
     )

    const zipTags = [
        { id: "75000", name:"75000"},
        { id: "92000", name: "92000" }

    ];
    const spokenLanguagesTags = [
        { id: 'Français', name: 'Français' },
    ];
    const categories = [
        {
            id: "CONFORT", name: "Confort",

        },{
        id : "ACCOMPAGNEMENT" , name : "Accompagnement",
    },
    {
        id: 'CONFORT_ACCOMPAGNEMENT', name: 'Confort et accompagnement'
    }
]
    const statusTable = [
        { id: 'ACTIVE', name: 'Active' },
        { id: 'INACTIVE', name: 'Inactive' },
        { id: 'SUSPENDED', name: 'Suspendue' },
    ]
    const setDefaultValues = () => {
        return {
            zipCodes: ["75000", "92000"],
            spokenLanguages:["Français"],
            hasACriminalRecord: false,
            isAllowedToWork: false,
            enabledByAdmin: true,
            rating: 3,
            wishedAvegrageHourlyWeeklyActivity: 30,
            workExperienceSince: (new Date()).setFullYear((new Date()).getFullYear() - 2), 
            status: "ACTIVE",
        }
    }

    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
            <Create
                title="Creer un profil d'auxiliaire de vie"
                transform={transform}
            >
                <SimpleForm

                    validate={validateForm}
                    defaultValues={setDefaultValues()}
                >


                    <Box display={{ 
                        xs: 'block', sm: 'flex', width: '100%',
                        flexDirection: 'column',
                        pading: '1rem',

                 }}>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                            <Typography variant="p" gutterBottom component="div"
                                style={
                                    {
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                    }
                                }
                            >
                                Choisissez l'utilisateur du compte auxiliaire
                            </Typography>
                            <SelectUnlinkedUserFirld source="user" isRequired fullWidth
                                userRole="provider" />
                           
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <PlaceAutoCompleteField source="address" 

                                        city_key="city"
                                        zipCode_key="zip"
                                        country_key="state"
                                        address_key="address"
                                        lat_key="lat"
                                        lng_key="lng"
                                        place_key="placeId"
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="zip"
                                        isRequired fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="city" isRequired fullWidth />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="state" isRequired fullWidth />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <SelectInput source="providerGender"
                                    choices={[
                                        { id: 'FEMALE', name: 'Femme' },
                                        { id: 'MALE', name: 'Homme' },
                                    ]}
                                    isRequired fullWidth
                                />
                            </Box>
                            <SelectArrayInput source="zipCodes" isRequired fullWidth
                                onCreate={() => {
                                    const newTagName = prompt('Entrer un code postal');
                                    const newTag = { id: newTagName.toLowerCase(), name: newTagName };
                                    zipTags.push(newTag);
                                    return newTag;
                                }}
                                choices={zipTags}
                            />
                            <TextInput source="anotations"  fullWidth multiline/>
                           
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="emergencyContact.name"  fullWidth />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="emergencyContact.phone"  fullWidth />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="emergencyContact.email"  fullWidth />
                                </Box>
                            </Box>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                            <Typography variant="p"  gutterBottom component="div"
                                style={
                                    {
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        color: '#000000',
                                        marginBottom: '1rem',
                                    }
                                }
                            >

                                Profile de l'auxiliaire
                            </Typography>
                            <SelectArrayInput source="spokenLanguages" isRequired fullWidth
                                onCreate={() => {
                                    const newTagName = prompt('Entrer une langue');
                                    const newTag = { id: capitalizeWord(newTagName), name: capitalizeWord(newTagName) };
                                    spokenLanguagesTags.push(newTag);
                                    return newTag;
                                }}
                                choices={spokenLanguagesTags}
                            />
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source="hasACriminalRecord" isRequired fullWidth />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source="IsAllowedToWork" isRequired fullWidth />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source="enabledByAdmin" isRequired fullWidth />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="scholarLevel" fullWidth 
                                    
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <DateInput source="workExperienceSince" isRequired fullWidth />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput source="rating" fullWidth max={5} min={1} isRequired />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput source="wishedAvegrageHourlyWeeklyActivity" isRequired fullWidth max={50} />
                                </Box>
                            </Box>
                            <SelectInput source="category" isRequired fullWidth
                    
                                choices={categories}
                            />
                            <SelectInput source="status" fullWidth multiline
                                choices={statusTable}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}
                            >
                                <Typography variant="h6" gutterBottom component="div"
                                    fontWeight={"bold"}
                                >
                                    Personalité  de l'auxiliaire
                                </Typography>


                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}

                            >
                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <SelectInput source="hasDinamycPersonality"
                                        choices={
                                            tristateBoolean
                                        }
                                        fullWidth={true}
                                        defaultValue={'ANY'}

                                    />
                                    <SelectInput source="likeToTakeInitiative" fullWidth={true}
                                        choices={
                                            tristateBoolean
                                        }
                                        defaultValue={'ANY'}

                                    />
                                </Box>

                                <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                    <SelectInput source="isKind" fullWidth={true}
                                        choices={
                                            tristateBoolean
                                        }
                                        defaultValue={'ANY'}

                                    />
                                    <SelectInput source="hasStrongTemperament" fullWidth={true}
                                        choices={
                                            tristateBoolean
                                        }
                                        defaultValue={'ANY'}
                                    />
                                </Box>



                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}
                            >
                                <BooleanInput source="isVip" fullWidth={true} />

                            </Box>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}
                            >
                                <Typography variant="h6" gutterBottom component="div"

                                        style={
                                            {
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                                color: '#000000',
                                                marginBottom: '1rem',
                                            }
                                        }
                                >
                                    Services
                                </Typography>


                            </Box>
                        </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h6" gutterBottom component="div"

                                            style={
                                                {
                                                    fontWeight: 'bold',
                                                    fontSize: '1.2rem',
                                                    color: '#000000',
                                                    marginBottom: '1rem',
                                                }
                                            }
                                        >
                                            Services
                                        </Typography>


                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                    }}>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}

                                    >
                                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                            <BooleanInput source="helpWithHousework" fullWidth={true} />
                                            <BooleanInput source="helpWithLaundry" fullWidth={true} />
                                            <BooleanInput source="helpWithGroceries" fullWidth={true} />
                                        </Box>


                                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                            <BooleanInput source="helpWithMeals" fullWidth={true} />
                                            <BooleanInput source="helpWithWalkAndFunActivities" fullWidth={true} />
                                            <BooleanInput source="helpWithToileting" fullWidth={true} />
                                        </Box>

                                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                                            <BooleanInput source="companionship" fullWidth={true} />
                                            <BooleanInput source="nightWatch" fullWidth={true} />
                                            <BooleanInput source="dayWatch" fullWidth={true} />

                                        </Box>
                                    </Box>


                                </Box>
                            </Box>
                        </Box>
                        
                        </Box>
                </SimpleForm>

            </Create>
        </Container>
    )
}
