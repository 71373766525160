import { CareRecipientList } from './CareRecipientList';
import { CareRecipientCreate } from './CareRecipientCreate';
import { CareRecipientEdit }   from './CareRecipientEdit';
import ElderlyIcon from '@mui/icons-material/Elderly';
import { CareRecipientShow } from './CareRecipientShow';


export const CareRecepients = {
    list: CareRecipientList,
    create: CareRecipientCreate,
    edit: CareRecipientEdit,
    show: CareRecipientShow,
    icon: ElderlyIcon
};