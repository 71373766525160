import { Box, Typography } from "@mui/material";
import { ArrayField, Datagrid, FunctionField, ReferenceArrayField, Show, SingleFieldList, Tab, TabbedShowLayout, Title, useRecordContext } from "react-admin"
import { formatDate } from "../../utils/DateFormat";
import { categoriesPrestation } from "../../common/utils";
import TableCellCustom from "../../common/components/TableCellCustom";
import { CustomeTableHead } from "../../common/style/style";
import { CareRecipientContracts } from "../careprovider/CareRecipientContracts";
import { CareRecipientInterventionList } from "./CareRecipientInterventions";
import { CareRecipientPresenceSheets } from "./CareRecipientPresenceSheets";
const ShowTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Title title={
        record.firstName + " " + record.lastName
    } />;
};

const DatagridHeader = () => {

    return (
        <CustomeTableHead>
            <TableCellCustom />
            <TableCellCustom name="Nom"
            />
            <TableCellCustom name="Prenom" />
            <TableCellCustom name='Téléphone' />
            <TableCellCustom name='Email' />
            <TableCellCustom name='Role' />
            <TableCellCustom name='Relation' />
            <TableCellCustom name='Professionnel' />

        </CustomeTableHead>
    )
}
export const CareRecipientShow = () => {
    const record = useRecordContext();
  
    
  return (

      <Show
          title={<ShowTitle />}
          sx={{
              width: "100%",
              justifyContent: 'center',
              alignItems: 'center',
          }}
      >
        <TabbedShowLayout
            sx={{
                width: "100%",
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
              <Tab label="Informations"
                    sx={{
                        width: "100%",
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}

              >
                <Box>
                    <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                    >
                          <Typography variant="h6" gutterBottom component="div"
                              color="#3f51b5"
                              fontWeight={600}

                          >
                              Indentité du bénéficiaire
                          </Typography>
                    </Box>

                    <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                    >
                        <Box
                            flex={0.20}
                            mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField disabled
                                  source="name"
                                  render={(record) => record.firstName + " " + record.lastName}
                                  color={'primary'} fontSize="1rem"


                              />

                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField 
                                  source="birthDate"
                                  render={(record) => formatDate(new Date(record.birthDate))}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField
                                  source="gender"
                                  render={(record) => {
                                    if(record.gender === "FEMALE") return "Femme"
                                    else return "Homme"
                                  }}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="email"
                                  render={(record) => record.email}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="emaphoneNumberil"
                                  render={(record) => record.phoneNumber}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                    </Box>
                    <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                          paddingTop={2}

                    >
                        <Box
                            flex={0.20}
                            mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField disabled
                                  source="name"
                                  render={(record) => record.address}
                                  color={'primary'} fontSize="1rem"


                              />

                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField 
                                  source="city"
                                  render={(record) => record.city}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField
                                  source="state"
                                  render={(record) => { return record.state }}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="zip"
                                  render={(record) => record.zip}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="addressComplement"
                                  render={(record) => record.addressComplement}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                    </Box>
                      <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          paddingTop={5}
                      >
                          <Typography variant="h6" gutterBottom component="div"
                              color="#3f51b5"
                              fontWeight={600}

                          >
                              Bien être du bénéficiaire
                          </Typography>
                      </Box>
                      <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                          }}
                      >
                          <Box
                              flex={0.20}
                          >
                              <FunctionField
                                  source="mobility"
                                  render={(record) =>{
                                    if(!record.condition) return "Non renseigné";
                                      return record.condition.mobility === 'ALT' ? 'Alité' : 'Non alité'
                                  }

                                   
                                }
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                          <Box
                              flex={0.20}
                          >
                              <FunctionField
                                  source="category"
                                  render={(record) =>{
                                      return categoriesPrestation.find((category) => category.id === record.category).name


                                  }
                                    }
                                    
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>

                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="particularConditions"
                                  render={(record) =>
                                    {
                                        if(!record.condition) return "";
                                        return record.condition.particularConditions
                                    }
                                    }
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>

                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="specialIndications"

                                  render={(record) => {
                                    if(!record.condition) return "";
                                    return record.condition.specialIndications
                                  }
                                   
                                }
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>

                    </Box>
                    <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                          paddingTop={2}

                    >
                        <Box
                            flex={0.20}
                        >
                              <FunctionField disabled
                                  source="name"
                                  render={(record) => record.address}
                                  color={'primary'} fontSize="1rem"


                              />

                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField 
                                  source="city"
                                  render={(record) => record.city}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                        <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                        >
                              <FunctionField
                                  source="state"
                                  render={(record) => { return record.state }}
                                  color={'primary'} fontSize="1rem"


                              />
                        </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="zip"
                                  render={(record) => record.zip}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                          <Box
                              flex={0.20}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                              <FunctionField
                                  source="addressComplement"
                                  render={(record) => record.addressComplement}
                                  color={'primary'} fontSize="1rem"


                              />
                          </Box>
                    </Box>
                      <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          paddingTop={5}
                      >
                          <Typography variant="h6" gutterBottom component="div"
                              color="#3f51b5"
                              fontWeight={600}

                          >
                              Contacts associés au bénéficiaire
                          </Typography>
                      </Box> 

                       <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                          }}
                      >
                          <Box
                              flex={1}
                              mr={{ xs: 0, sm: '1em' }}
                          >
                            
                              <ArrayField source="emergencyContacts">
                                    <Datagrid
                                    header={
                                        <DatagridHeader/>
                                    }
                                      isRowSelectable={(record) => false}
                                     >
                                      <FunctionField source="lastName" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.lastName
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="firstName" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.firstName
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="phoneNumber" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.phoneNumber
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="email" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.email
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="role" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.role
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="relationship" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return record.relationship
                                        }
                                        }
                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                      <FunctionField source="professionalRelationship" 
                                        render={(record) => {
                                            if(!record) return "";
                                            return  record.professionalRelationship === true ?   "Oui" : "Non";
                                        }
                                        }

                                          color={'primary'} 
                                          fontSize="1rem"
                                      />
                                    </Datagrid>
    
                                  
                              </ArrayField>
                          </Box>

                       </Box>
                      <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          paddingTop={5}
                      >
                          <Typography variant="h6" gutterBottom component="div"
                              color="#3f51b5"
                              fontWeight={600}

                          >
                             Utilisateurs Hoomiz Family 
                          </Typography>
                      </Box> 
                      <Box
                          flex={1}
                          mr={{ xs: 0, sm: '1em' }}
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                          }}
                      >
                          <Box
                              flex={1}
                              mr={{ xs: 0, sm: '1em' }}
                              paddingBottom={5}

                          >

                              <ReferenceArrayField 
                              label="users" 
                              reference="users" 
                              source="users" 
                              >
                                  <SingleFieldList
                                       
                                  >
                                      <FunctionField source="firstName"
                                        render={(record) => {
                                            if(!record) return "";
                                            return `${record.firstName} ${record.lastName}  / ${record.email} / ${record.mobilePhone}`
                                        }
                                        }
                                            color={'primary'}
                                            fontSize="1rem"
                                            paddingTop={5}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                boxShadow: 3,
                                                borderRadius: 1,
                                                padding: 2,

                                            }
                                            }
                                      />
                                  </SingleFieldList>
                            </ReferenceArrayField>

                          </Box>

                      </Box>
                </Box>
              </Tab>
              <Tab label="contrats"
                  sx={{
                      width: "100%",
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}

                  path="contracts"
              >
                  <CareRecipientContracts

                      recipientId={
                          record ? record.id : ""
                      } 
                   />
              </Tab>                
              <Tab label="Interventions"
                  sx={{
                      width: "100%",
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}

                  path="interventions"
              >
                    <CareRecipientInterventionList
                        recipientId={
                            record ? record.id : ""
                        }
                    />
              </Tab>
              <Tab label="Feuilles de presence"
                  sx={{
                      width: "100%",
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}

                  path="presencesheets"
              >
                    <CareRecipientPresenceSheets
                        recipientId={
                            record ? record.id : ""
                        }
                    />
              </Tab>   
        </TabbedShowLayout>
    </Show>
  ) 
}   