import * as React from 'react';
import { Chip, } from '@mui/material';
import { useRecordContext } from 'react-admin';
import styled from 'styled-components';

const CustomChips = styled(Chip)`
    background-color: ${props => {
    if (props.role === 'admin') return '#ff3369';
    if (props.role === 'userCareProvider') return '#37419A';
    if (props.role === 'userCareRecipient') return '#2196F3';
} };
    color: white;
    margin: 5px;
`;

const UserRoleField = () => {
    const record = useRecordContext();
    const role = record ? record.role : null;
    const getRoleMeaning = (role) => {
        switch (role) {
            case 'admin':
                return 'Administrateur';
            case 'userCareProvider':
                return 'Auxiliaire de vie';
            case 'userCareRecipient':
                return 'Client';
            default:
                return '';
        }
    }
    return record ? (
        <CustomChips
            label={getRoleMeaning(role)}
            role={role}
        />
    ) : null;
}

export default UserRoleField;