import { Container, Button } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { Edit,ArrayInput, AutocompleteInput, BooleanInput, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, useRecordContext, Confirm, useDataProvider } from "react-admin"
import { days, hours, minutes } from "../../common/utils"
import { formatUserFullName } from "../../utils/Stringhelps"
import { useMutation } from 'react-query';
import ApiConstants from "../../api/ApiConstants"
import { API_URL } from "../../utils/constants"
import { addDays, formatDateInput } from "../../utils/DateFormat"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import React from "react"

const validateForm = (values) => {

}

const transform = (data) => {
    delete data.interventions
    return {
        ...data,
    }

}

const contractTypes = [
    {
        id: "RECURRENT", name: "récurrent",

    },
    {
        id: "ONE_TIME", name: "Pontuel",
    }
]

const typeOfPrices = [
    {
        id: "TAUX_HORAIRE", name: "Taux horaire",

    },
    {
        id: "FORFAIT", name: "Forfait",
    }
]
const categories = [
    {
        id: "CONFORT", name: "Confort",

    }, {
        id: "ACCOMPAGNEMENT", name: "Accompagnement",
    }, {
        id: "CONFORT_ACCOMPAGNEMENT", name: "Confort et accompagnement",
    }]

const contractStatus = [
    {
        id: "ACTIVE", name: "actif",
    }
    , {
        id: "INACTIVE", name: "inactif",
    }
]

const ActionTable = () => {
    const dataProvider = useDataProvider();
    const [ActionName, setActionName] = useState("");
    const [open, setOpen] = useState(false);
    const record  = useRecordContext();
    let message = {
        removeInterventionsForContract: "Voulez-vous vraiment supprimer toutes les prochines interventions de ce contrat ?",
        generateInterventionsForContract: "Voulez-vous vraiment générer toutes les prochines interventions de ce contrat ?",
    };

    const { mutate: deleteContractFutureInterviews } = useMutation(
        () => dataProvider.deleteOnApi(API_URL + ApiConstants.DELETE_CONTRACT_FUTURE_INTERVIEWS.replace("{contractId}", record.id), null),
        {
            onSuccess: () => {
                setOpen(false);
                setActionName("");
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );
    const { mutate: postGenerateInterventionsForContract } = useMutation(
        () => dataProvider.postOnApi(API_URL + ApiConstants.POST_GENRATE_CONTRACT_INTERVIEWS.replace("{contractId}", record.id), { limitDate: formatDateInput(addDays(new Date(), 90))}),
        {
            onSuccess: () => {
                setOpen(false);
                setActionName("");
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );
    const removeInterventionsForContract = () => {
        setActionName("removeInterventionsForContract");
        setOpen(true)

    }

    const generateInterventionsForContract = () => {
        setActionName("generateInterventionsForContract");
        setOpen(true)
    }


    const handleConfirm = () => {
        setOpen(false);
        if (ActionName === "removeInterventionsForContract") {
            deleteContractFutureInterviews();
        }
        if (ActionName === "generateInterventionsForContract") {
            postGenerateInterventionsForContract();
        }
    }
    const handleDialogClose = () => {
        setOpen(false);
    }


    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
        }}
        >
                <Box 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '100%',
                    }}

                >
                
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}
                  paddingTop={2}
                >                
                    
                    <Box flex={1} mr={{ xs: 0, sm: '1em' }} 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxWidth: '100%',
                        }}
                    >
                        <Button 
                            sx={{
                                color: 'white',
                                backgroundColor: '#d32f2f',
                                '&:hover': {
                                    backgroundColor: '#c62828',
                                },
                            }}
                         variant='contained'
                            onClick={removeInterventionsForContract}
                         > Supprimer les prochaines interventions </Button>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                        <Button
                            sx={{
                                color: 'white',
                            }
                            }
                            variant='contained'
                            onClick={generateInterventionsForContract}
                        > Generer les prochaines interventions </Button>
                    </Box>
                </Box>
                </Box>
                <Confirm  
                    content={message[ActionName]}                  
                    isOpen={open}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
        </Container>
    )
}

export const ContractEdit = () => {
    const navigate = useNavigate();

    const handleRecipentChange = async (value) => {
    }

    const [hoursStartingDefault, setHoursStartingDefault] = React.useState();
    const [minutesStartingDefault, setMinutesStartingDefault] = React.useState();

    const [hoursEndingDefault, setHoursEndingDefault] = React.useState();

    const [minutesEndingDefault, setMinutesEndingDefault] = React.useState();
    const changeSelectedHour = (value) => {
        if((value.target.value === "00" && minutesEndingDefault == "00") || (value.target.value === "00" && hoursEndingDefault == "00")){
            setHoursEndingDefault("23")
            setMinutesEndingDefault("59")
        }
        else if(value.target.name.includes("startingHour")){
        setHoursStartingDefault(value.target.value)
        }else if(value.target.name.includes("endingHour")){
            setHoursEndingDefault(value.target.value)
        }else if(value.target.name.includes("startingMinute")){
            setMinutesStartingDefault(value.target.value)
        }else if(value.target.name.includes("endingMinute")){
            setMinutesEndingDefault(value.target.value)
        }
    }
    return (
        <Container style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        }}
        >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: '1rem',
                        width: '80%',
                    }}
                >
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}


                >
                    Retour
                </Button>

                </Box>
           
            <Edit
                title={"Modifier le contrat"}
                transform={transform}
                validateForm={validateForm}
                sx={{ width: '80%' }}
                redirect="edit"
            >
                <SimpleForm
                    validateForm={validateForm}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>

                            <ReferenceInput
                                source="careRecipientProfile"
                                reference="care-recipient-profiles"
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText={(record) => `${formatUserFullName(record).toUpperCase()}`}
                                    key={record => record.id}
                                    onChange={handleRecipentChange}
                                    filterToQuery={searchText => ({ q: searchText })}
                                    defaultValue={(record) => record.careRecipientProfile}

                                />

                            </ReferenceInput>

                        </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <ReferenceInput source="careProviderProfile" fullWidth
                                reference="care-provider-profiles"

                            >
                                <AutocompleteInput
                                    optionText={(record) => `${formatUserFullName(record.user).toUpperCase()}`}
                                    key={record => record.id}
                                    filterToQuery={searchText => ({ q: searchText })}
                                    defaultValue={(record) => record.careProviderProfile}

                                />

                            </ReferenceInput>
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="startingDate" fullWidth required

                            />
                        </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <DateInput source="endingDate" fullWidth required />
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="bedTimeHelp" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="toiletAid" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithMeals" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithHousework" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithMobility" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="helpWithShopping" />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <BooleanInput source="laundryCare" />
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="category" fullWidth required
                                choices={categories}
                            />                  </Box>

                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="contractType" fullWidth
                                choices={contractTypes}
                                defaultValue="RECURRENT"
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <SelectInput source="typeOfPrice" fullWidth required
                                choices={typeOfPrices}
                                defaultValue="TAUX_HORAIRE"
                            />                  </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '1em' }}>
                            <NumberInput source="price" fullWidth required
                                defaultValue="24.50"
                            />                  </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <ArrayInput source="schedules" fullWidth>
                            <SimpleFormIterator inline>
                                <SelectInput source="day" fullWidth
                                    choices={days}
                                    required
                                />
                                <SelectInput source="startingHour" fullWidth
                                    choices={hours}
                                    onChange={changeSelectedHour}
                                    defaultValue={hoursStartingDefault} 
                                />
                                <SelectInput source="startingMinute" fullWidth
                                    choices={minutes}
                                    onChange={changeSelectedHour}
                                    defaultValue={minutesStartingDefault}

                                />
                                <SelectInput source="endingHour" fullWidth
                                    choices={hours}
                                    onChange={changeSelectedHour}
                                    defaultValue={hoursEndingDefault}
                                />
                                <SelectInput source="endingMinute" fullWidth
                                    choices={minutes}
                                    onChange={changeSelectedHour}
                                    defaultValue={minutesEndingDefault}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <SelectInput source="status" fullWidth
                            choices={contractStatus}
                            defaultValue="ACTIVE"
                        />

                   </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                    <SelectInput source="renewalContract" fullWidth
                            choices={[{ id: 'true', name: 'Oui' }, { id: 'false', name: 'Non' }]}

                            />
                     </Box>
                </SimpleForm>
                <ActionTable />

            </Edit>

        </Container>
    )

}