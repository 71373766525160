import { TableRow } from "@mui/material";
import React from "react";
import { List, Datagrid, TextField, DateField, SearchInput, SelectInput, TopToolbar, SortButton, CreateButton,  FilterButton } from "react-admin";
import TableCellCustom from "../../common/components/TableCellCustom";
import { CustomeTableHead } from "../../common/style/style";
import ActivetedBooleanField from "./ActivatedBooleanField";
import EmailField from "./EmailField";
import FullNameField from "./FullNameField";
import UserRoleField from "./UserRoleField";

const DatagridHeader = ({ children }) => (
    <CustomeTableHead
    >
        <TableRow
            color={'#37419A'}
        >
            <TableCellCustom />
            <TableCellCustom name={"Nom"}/>
            <TableCellCustom name={"Date de naissance"} />
            <TableCellCustom name={"Role"} />
            <TableCellCustom name={"Email"} />
            <TableCellCustom name={"Téléphone"} />
            <TableCellCustom name={"Activé"} />
            <TableCellCustom name={"Date de creation"} />
        </TableRow>
    </CustomeTableHead>
);

const UserFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput source="role" choices={[
        { id: 'admin', name: 'Administrateur' },
        { id: 'userCareProvider', name: 'Auxiliaire de vie' },
        { id: 'userCareRecipient', name: 'Client' },
    ]} />
];

const ListActions = () => (
    <TopToolbar sx={{display: 'flex',alignItems: 'center'}}>
        <FilterButton />
        <SortButton fields={['createdAt', 'mobilePhone',"firstName","lastName"]} />
        <CreateButton />
    </TopToolbar>
);

export const UserList = () => {
    
    return (
        <List
            filterDefaultValues={{ q: '' }}
            actions={<ListActions />}
            filters={UserFilters}
            exporter={false}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            rowClick="edit"

        >
            <Datagrid rowClick="edit" header={<DatagridHeader
            />} >
                <FullNameField />
                <DateField source="birthDate"
                    color={'#37419A'}
                    locales="fr-FR"
                />
                <UserRoleField />
                <EmailField />
                <TextField source="mobilePhone"
                    color={'#37419A'}
                />
                <ActivetedBooleanField  />
                <DateField source="createdAt"
                    color={'#37419A'}
                    locales="fr-FR"

                />
            </Datagrid>
        </List>
    );
}


