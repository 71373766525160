import { JobCreate } from "./JobCreate";
import { JobEdit } from "./JobEdit";
import { JobList } from "./JobList";
import { JobShow } from "./JobShow";
import AnnouncementIcon from '@mui/icons-material/Announcement';

export const Jobs =  {
        list: JobList,
        create: JobCreate,
        edit: JobEdit,
        show: JobShow,
        icon: AnnouncementIcon
    };