import { Box, Chip, Typography, Button } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react"
import { Show, TextField, DateField, FunctionField, useRecordContext, TabbedShowLayout, Tab, ArrayField, ArrayInput, SimpleFormIterator, List, Datagrid, useCreatePath, Link } from 'react-admin';
import { LineMarkedMap } from "../../common/components/MapComp";
import { diffTime, formatDateWithTime,minutestoHourMin} from "../../utils/DateFormat";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { translateMonth } from "../../utils/DateFormat";
import { formatDate } from "../../utils/DateFormat";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { translateStatus } from "../../utils/Stringhelps";
import TableCellCustom from "../../common/components/TableCellCustom";
import { CustomeTableHead } from "../../common/style/style";
import { NumbersOfInterventions } from "../presencesheet/numbersOfInterventions"
import { RefreshIntervention } from "./refreshIntervention";


export const PresenceSheetShow = () => {
    const navigate = useNavigate();
    const ActionGroup = (props) => {
        const { record } = props;
        const createPath = useCreatePath();
        return (
            <Box>
                <Link to={createPath({ resource: 'interventions', type: 'show', id: record.interventionId })}
                >
                    <VisibilityIcon
                        variant="body2"
                        color="text.secondary"
                        style={{ fontWeight: 500, fontSize: "1.5rem" }}
                        cursor="pointer"

                    />
                </Link>

            </Box>
        )
    }

    const DatagridHeader = () => {

        return (
            <CustomeTableHead>
                <TableCellCustom />
                <TableCellCustom name="Date d'arrivée"
                />
                <TableCellCustom name="Date de départ" />
                <TableCellCustom name="Durée de l'intervention" />
                <TableCellCustom name='Commentaire' />
                <TableCellCustom name='Voir' />
    
            </CustomeTableHead>
        )
    }


    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    width: '90%',
                    margin: 'auto',
                    marginTop: '20px',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Retour
                </Button>
            </Box>
            <Show
                title="Feuille de présence"
                sx={{
                    width: '90%',
                    margin: 'auto',
                    marginBottom: '20px',
                    backgroundColor: '#fff',

                }}
            >
                 <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                    padding: '20px',
                }}
            >           
                <Box 
                 sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
                
                >  

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <FunctionField
                                source="monthNumber"
                                render={record => `${translateMonth(record.monthNumber).toUpperCase()}`}
                                color={'primary'} fontSize="2rem"
                                marginRight={"30px"}

                            />
                            <FunctionField
                                source="yearNumber"
                                render={(record) => record.yearNumber}
                                color={'primary'} fontSize="2rem"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <FunctionField
                                source="totalMinutes"
                                render={record => ` ${minutestoHourMin(record.totalMinutes)}` }
                                color={'primary'} fontSize="2rem"
                                marginRight={"30px"}

                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <RefreshIntervention />

                            <FunctionField
                                source="status"
                                render={record => translateStatus(record.status) }
                                color={'primary'} fontSize="2rem"
                                marginRight={"30px"}

                            />

                        </Box>
                    </Box>  
                    <NumbersOfInterventions/>

                <Box marginTop={2} marginBottom={2}>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Indentité de l'auxiliaire
                            </Typography>
                        </Box>

                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="firstName"
                                    render={(record) => `${record.careProviderId.userId?.firstName} ${record.careProviderId.userId?.lastName}`}
                                    color={'primary'} fontSize="1rem" fontWeight={600}
                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="birthDate"
                                    render={(record) => formatDate(new Date(record.careProviderId.userId?.birthDate))}
                                    color={'primary'} fontSize="1rem"
                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="gender"
                                    render={(record) => {
                                        if (record.careProviderId.userId?.gender === "FEMALE") return "Femme"
                                        else return "Homme"
                                    }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="email"
                                    render={(record) => record.careProviderId.userId?.email}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="emaphoneNumberil"
                                    render={(record) => record.careProviderId.userId?.mobilePhone}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            paddingTop={2}

                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="name"
                                    render={(record) => record.careProviderId.address}
                                    color={'primary'} fontSize="1rem"


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="city"
                                    render={(record) => record.careProviderId.city}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="state"
                                    render={(record) => { return record.careProviderId.state }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="zip"
                                    render={(record) => record.careProviderId.zip}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="addressComplement"
                                    render={(record) => record.careProviderId.anotations}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        </Box>
                        <Box marginTop={2} marginBottom={2}>

                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Indentité du bénéficiaire
                            </Typography>
                        </Box>

                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="firstName"
                                    render={(record) => `${record.careRecipientId.firstName} ${record.careRecipientId.lastName}`}
                                    color={'primary'} fontSize="1rem" fontWeights={600}


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="birthDate"
                                    render={(record) => formatDate(new Date(record.careRecipientId.birthDate))}
                                    color={'primary'} fontSize="1rem"
                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="gender"
                                    render={(record) => {
                                        if (record.careRecipientId.gender === "FEMALE") return "Femme"
                                        else return "Homme"
                                    }}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="email"
                                    render={(record) => record.careRecipientId.email}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="emaphoneNumberil"
                                    render={(record) => record.careRecipientId.phoneNumber}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            paddingTop={2}

                        >
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField disabled
                                    source="name"
                                    render={(record) => record.careRecipientId.address}
                                    color={'primary'} fontSize="1rem"


                                />

                            </Box>
                            <Box
                                flex={0.20}
                                mr={{ xs: 0, sm: '1em' }}
                            >
                                <FunctionField
                                    source="city"
                                    render={(record) => record.careRecipientId.city}
                                    color={'primary'} fontSize="1rem"


                                />
                            </Box>
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                                paddingTop={2}

                            >
                                <Box
                                    flex={0.20}
                                    mr={{ xs: 0, sm: '1em' }}
                                >
                                    <FunctionField
                                        source="state"
                                        render={(record) => record.careRecipientId.state}
                                        color={'primary'} fontSize="1rem"


                                    />
                                </Box>
                                <Box
                                    flex={0.20}
                                    mr={{ xs: 0, sm: '1em' }}
                                >
                                    <FunctionField
                                        source="zip"
                                        render={(record) => record.careRecipientId.zip}
                                        color={'primary'} fontSize="1rem"


                                    />
                                </Box>
                                <Box
                                    flex={0.20}
                                    mr={{ xs: 0, sm: '1em' }}
                                >
                                    <FunctionField
                                        source="addressComplement"
                                        render={(record) => record.careRecipientId.addressComplement}
                                        color={'primary'} fontSize="1rem"


                                    />
                                </Box>
                            </Box>
                        </Box>
                        </Box>
                        <Box marginTop={2} marginBottom={2}>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}

                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Détail de la feuille de présence
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >

                      
                        <Box
                            mr={{ xs: 10, sm: '1em' }}
                        >
                            <Typography variant="h" color={'primary'} fontSize="1rem" marginRight={"10px"}     >
                            Signée par le bénéficiaire :
                            </Typography>
                            <FunctionField
                                render={(record) => record.recipientHasSigned ? "Oui" : "Non"}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '1em' }}
                        >
                            <Typography variant="h" color={'primary'} fontSize="1rem" marginRight={"10px"}  marginLeft={"50px"}   >
                            Commentaire du bénéficiaire :
                            </Typography>
                            <FunctionField
                                render={(record) => record.careRecipientComment ? `${record.careRecipientComment}` : "Pas de commentaire"}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '1em' }}
                        >
                            <Typography variant="h" color={'primary'} fontSize="1rem" marginRight={"10px"}  marginLeft={"50px"}   >
                            Signée par l'auxiliaire :
                            </Typography>
                            <FunctionField
                                render={(record) => record.careProviderHasSigned ? "Oui" : "Non"}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        <Box
                            mr={{ xs: 10, sm: '1em' }}
                        >
                            <Typography variant="h" color={'primary'} fontSize="1rem" marginRight={"10px"}  marginLeft={"50px"}   >
                            Commentaire de l'auxiliaire :
                            </Typography>
                            <FunctionField
                                render={(record) => record.careProviderComment ? `${record.careProviderComment}` : "Pas de commentaire"}
                                color={'primary'} fontSize="1rem"
                            />
                        </Box>
                        </Box>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                        >
                            <Typography variant="h6" gutterBottom component="div"
                                color="#3f51b5"
                                fontWeight={600}

                            >
                                Feuille de présences
                            </Typography>
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '1em' }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                flex={1}
                                mr={{ xs: 0, sm: '1em' }}
                            >

                                <ArrayField source="presences">
                                    <Datagrid
                                        header={
                                            <DatagridHeader isRowSelectable={false}/>
                                        }
                                        isRowSelectable={(record) => false}
                                    >
                                        <FunctionField source="checkInDate"
                                            render={(record) => {
                                                if (!record.checkInDate) return "";
                                                return `${formatDateWithTime(record.checkInDate)}`
                                            }
                                            }
                                            color={'primary'}
                                            fontSize="1rem"
                                        />
                                        <FunctionField source="checkOutDate"
                                            render={(record) => {
                                                if (!record.checkOutDate) return "";
                                                return `${formatDateWithTime(record.checkOutDate)}`
                                            }
                                            }
                                            color={'primary'}
                                            fontSize="1rem"
                                        />
                                        <FunctionField source="interventionLength"
                                            render={(record) => {
                                                if (!record.interventionLength) return "";
                                                return `${record.interventionLength.hours} H ${record.interventionLength.minutes} min`
                                            }
                                            }
                                            color={'primary'}
                                            fontSize="1rem"
                                        />
                                        <FunctionField source="comment"
                                            render={(record) => {
                                                if (!record.comment) return "";
                                                return `${record.comment}`
                                            }
                                            }
                                            color={'primary'}
                                            fontSize="1rem"
                                        />
                                        <FunctionField source="Voir" render={record => {

                                            return <ActionGroup record={record} />

                                        }}
                                            color={'#37419A'}
                                            style={{ fontWeight: 500, fontSize: "1rem" }}

                                        />
                                    </Datagrid>
                                    
                                </ArrayField>
                                </Box>
                            </Box>
                        </Box>
            </Show>
        </Box>

    );
}
