
import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useFormContext } from "react-hook-form";

import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { TextInput, useRecordContext } from 'react-admin';

export const PlaceAutoCompleteField = ({ address_key, zipCode_key, country_key, city_key, lat_key, lng_key, place_key,...props }) => {
    const { setValue } = useFormContext();
    
    const record = useRecordContext();

    const [address, setAddress] = useState('');
    
    const [placeObject, setPlaceObject] = useState({});

    const handleChange = (address) => {
        setAddress(address);
    };
    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                getLatLng(results[0])
                setAddress(address);
                try {
                    setPlaceObject({
                        address: results[0].formatted_address,
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                        city: results[0].address_components[2].long_name,
                        zipCode: results[0].address_components[6].long_name,
                        country: results[0].address_components[5].long_name,
                        place_id: results[0].place_id,
                    })

                } catch (error) {
                    console.log(error);
                }
            })
            .catch((error) => console.error('Error', error));
    };

    useEffect(() => {
        if (record){
            setAddress(record[address_key]);
            handleSelect(record[address_key]);
        }
    }, [record, address_key]);

    useEffect(() => {
        setValue(address_key, placeObject.address);
        setValue(zipCode_key, placeObject.zipCode);
        setValue(country_key, placeObject.country);
        setValue(city_key, placeObject.city);
        setValue(lat_key, placeObject.lat);
        setValue(lng_key, placeObject.lng);
        setValue(place_key, placeObject.place_id);
    }, [placeObject, address_key, zipCode_key, country_key, city_key, lat_key, lng_key, place_key, setValue])
    const searchOptions = {
        componentRestrictions: { country: ['fr'] },
    }
    return (
        <PlacesAutocomplete

            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <Box>
                    <TextInput
                        {...getInputProps({
                            placeholder: 'Adresse ...',
                            className: 'location-search-input',
                            source: 'address',
                            fullWidth: true,
                            autoComplete: "new-password",
                        })}
                        value={address}
                        source={address_key || ''}

                    />
                    {suggestions.length ? <Card
                        style={{
                            zIndex: 100,
                            position: 'absolute',
                            marginTop: '0.2rem',
                            width: '30%',
                            justifyItems: 'center',
                        }}
                    >
                        {loading && <Box>Loading...</Box>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa', cursor: 'pointer', padding: '0.5rem', display: 'flex', borderBottom: '1px solid #e0e0e0',
                                }
                                : {
                                    backgroundColor: '#ffffff', cursor: 'pointer', padding: '0.5rem', display: 'flex', borderBottom: '1px solid #e0e0e0',
                                };
                            return (
                                <Box
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                    key={suggestion.description}
                                >
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '0.3rem',
                                        }}
                                    >{suggestion.description}</Box>
                                </Box>
                            );
                        })}
                    </Card> : null
                    }
                </Box>
            )}
        </PlacesAutocomplete>
    );
}