import { List, TextField, Datagrid, FunctionField } from "react-admin"
import TableCellCustom from "../../common/components/TableCellCustom";
import { CustomeTableHead } from "../../common/style/style";
import { formatDate } from "../../utils/DateFormat";

export const JobList = () => {

    const DatagridHeader = () => {
        
        return (
            <CustomeTableHead>
                <TableCellCustom />
                <TableCellCustom name="Date de creation" />
                <TableCellCustom name="code" />
                <TableCellCustom name='Date de debut' />
                <TableCellCustom name='Beneficiaire' />
                <TableCellCustom name='Email' />
                <TableCellCustom name='Telephone' />
                <TableCellCustom name='addresse' />
                <TableCellCustom name='catégorie' />
                <TableCellCustom name='statut' />
            </CustomeTableHead>
        )
    }

    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}

        >
            <Datagrid
                rowClick="show"
                header={<DatagridHeader />}
            >
                <FunctionField source="createdAt" render={record => {
                    const d = new Date(record.createdAt);
                    return formatDate(d);
                }}
                    color={'#37419A'}
                />
                <TextField source="jobCode" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="jobStartDate" render={record => {
                    const d = new Date(record.jobStartDate);
                    return formatDate(d);
                }}
                    color={'#37419A'}
                />
                <TextField source="recipientFullName" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="recipientEmail" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="recipientPhone" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="recipientAddress" color={'#37419A'} style={{ fontWeight: 500 }} />
                <TextField source="jobCategory" color={'#37419A'} style={{ fontWeight: 500 }} />
                <FunctionField source="jobStatus" render={record => {
                    if (record.jobStatus === "DRAFT") {
                        return "Brouillon"
                    }
                    if (record.jobStatus === "LISTED") {
                        return "Publié"
                    }
                    if (record.jobStatus === "ACCEPTED") {
                        return "Accepté"
                    }
                    if (record.jobStatus === "REFUSED") {
                        return "Refusé"
                    }
                    if (record.jobStatus === "COMPLETED") {
                        return "Terminé"
                    }
                    if (record.jobStatus === "CANCELED") {
                        return "Annulé"
                    }
                }}
                    color={'#37419A'}
                />
            
            </Datagrid>

        </List>
    );
};