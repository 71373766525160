import moment from "moment"

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export const formatDateInput =(date) => {
    date = new Date(date);
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

export const formatDateWithTime = (date) => {
    // turn date into dd/mm/yyyy hh:mm
    if(!date) return '';
    date = new Date(date);
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/') + ' ' + [
        padTo2Digits(moment.utc(date).format("HH")),
        padTo2Digits(date.getMinutes()),
    ].join(':');


}
export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export const formatWorkExperience = (workExperienceSince) => {
    const date = new Date(workExperienceSince);
    const year = date.getFullYear();
    const month = date.getMonth();
    const time = new Date().getFullYear() - year + " ans et " + (new Date().getMonth() - month) + " mois";
    return time;
}

export const formatWorkExperienceRange = (workExperienceRange) => {

    if(!workExperienceRange) return '';
    if (workExperienceRange === "0-3") return "Moins de 3 ans";
    if (workExperienceRange === "3-10") return "Entre 3 et 10 ans";
    if (workExperienceRange === "10-") return "Plus de 10 ans";


}


export const diffTime = (date1, date2) => {
    // turn date into dd/mm/yyyy hh:mm and return the difference in minutes
    date1 = new Date(date1);
    date2 = new Date(date2);
    let  diffInMinutes = Math.abs(date1 - date2) / 1000 / 60
    // convert to hours and minutes
   let  hours = Math.floor(diffInMinutes / 60);
   let  minutes = Math.floor(diffInMinutes % 60);
   if(!isNaN(hours) && !isNaN(minutes)){
    return turnTwodigits(hours) + " h " + turnTwodigits(minutes) + " min";
   }else{
    return`-`
   }
}

export const signedDiffTime = (date1, date2) => {
    // turn date into dd/mm/yyyy hh:mm and return the difference in minutes
    date1 = new Date(date1);
    date2 = new Date(date2);
    let  diffInMinutes = (date1 - date2) / 1000 / 60
    // convert to hours and minutes
   let  hours = Math.floor(diffInMinutes / 60);
   let  minutes = Math.abs(Math.floor(diffInMinutes % 60));
    return turnTwodigits(hours) + " h " + turnTwodigits(minutes) + " min";
}

export const signedDiffTimeReturnObject = (date1, date2) => {
    const duration = {
        hours: 0,
        minutes: 0,
    }
    let durationInSeconds = 0;
    if (!(date1 && date2)){
        return duration
    }

        durationInSeconds = (new Date(date1).getTime() - new Date(date2).getTime() ) / 1000;           
        duration.hours = Math.floor(durationInSeconds / 3600);
        duration.minutes = Math.round((durationInSeconds - duration.hours * 3600) / 60);
        duration.minutes = Math.round(duration.minutes / 30) * 30;
        if (duration.minutes === 60) {
            duration.hours += 1;
            duration.minutes = 0;
        }

    return duration

}

export const turnTwodigits  = (value) =>
{
    if (Math.abs(value) < 10 ) return  `0`+value;
    return value;
}

export const getAgeFromDate = (date) => {
    if(!date) return '';
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const formatDate = (date) => {
    // turn date to dd/mm/yyyy
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
} 

export const timeOfIntervention = (date1,date2) => {
    var diff = {}                           // Initialisation du retour
    var tmp = new Date(date2) - new Date(date1);
     
    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes
 
    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes
 
    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures
     
    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;
     
    return diff;
}

export const makeEffectiveTime = (record) => {
    if(record.status === "completed" ){
        const hours = record.effectiveTime?.hours;
        const minutes = record.effectiveTime?.minutes;
        const d2 = new Date(record.checkInDate);
        const d1 = new Date(record.checkOutDate);
        if (hours  || minutes ) {
         if(record.checkInDate || record.checkOutDate){
         return `${diffTime(d2, d1) == "-" ? "" : diffTime(d2, d1) } (${hours}H${ minutes < 9 ? `0${minutes}`: `${minutes}`}min)`;
         }
         else{
         return `(${hours}H${minutes}min)`;
         }
     
     } else {
         const d2 = new Date(record.checkInDate);
         const d1 = new Date(record.checkOutDate);
         if(record.checkInDate || record.checkOutDate){
         return diffTime(d2, d1);
         }
         else{
         return `-`;    
         }
     }
     }else{
         return`-`;
     }
}
export const printEffectiveTime = (effectiveTime) => {
    if(effectiveTime){
        let hours = effectiveTime.hours;
        let minutes = effectiveTime.minutes;
        if (hours  || minutes ) {
            // double digit minutes and hours
             minutes = effectiveTime.minutes < 9 ? `0${effectiveTime.minutes}`: `${effectiveTime.minutes}`;
             hours = effectiveTime.hours < 9 ? `0${effectiveTime.hours}`: `${effectiveTime.hours}`;

         return `${hours}H${minutes}min`;
     } else {
         return `-`;
     }
     }else{
         return`-`;
     }
}

export const translateMonth = (month) => {
    const mois = [
        "janvier", "février", "mars", "avril", "mai", "juin",
        "juillet", "août", "septembre", "octobre", "novembre", "décembre"
      ];
    
      if (month < 1 || month > 12) {
        return "Le numéro de mois doit être compris entre 1 et 12.";
      } else {
        return mois[month - 1];
      }
    
}

export const  minutestoHourMin = (minutes)  => {
    var heures = Math.floor(minutes / 60);
    var minutesRestantes = minutes % 60;
    var heureStr = heures.toString();
    var minutesStr = minutesRestantes.toString().padStart(2, '0');
    if(isNaN(heures) || isNaN(minutesRestantes)){
        return`00 h 00 min `
    }else{
    return heureStr + ' h ' + minutesStr + ' min';
    }
  }