import { GOOGLE_MAP_KEY } from "./constants";

export const loadGMaps = (callback) => {
    const existingScript = document.getElementById('googlePlaces');
    if (existingScript) {
        existingScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`);
        existingScript.onload = () => {
            if (callback) callback();
        };
    }

}
    ;

export const locationToLatLng = (location) => {
    if (location.coordinates) {
            return {
                lat: location.coordinates[1],
                lng: location.coordinates[0]
            };
        }
        return null;
};

export const haversineDistance = (locationOne, locationTwo) => {
    locationOne.lng = locationOne.coordinates[0];
    locationOne.lat = locationOne.coordinates[1];
    locationTwo.lng = locationTwo.coordinates[0];
    locationTwo.lat = locationTwo.coordinates[1];
    const R = 6371.0710; 
    const rlat1 = locationOne.lat * (Math.PI / 180); 
    const rlat2 = locationTwo.lat * (Math.PI / 180); 
    const difflat = rlat2 - rlat1;
    const difflon = (locationTwo.lng - locationOne.lng) * (Math.PI / 180); 
    const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return Math.round(d * 100) / 100;
}
