import { InterventionCreate } from "./InterventionCreate";
import { InterventionEdit } from "./InterventionEdit";
import { InterventionList } from "./InterventionList";
import { InterventionShow } from "./InterventionShow";
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';

export const Interventions = {
    show: InterventionShow,
    list: InterventionList,
    create: InterventionCreate,
    edit: InterventionEdit,
    icon: WheelchairPickupIcon
};