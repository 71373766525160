import { useEffect, useState } from "react"
import { Box } from "@mui/system"
import {  Rating, Typography } from "@mui/material"
import {  FunctionField, Show, Tab, TabbedShowLayout, Title, useRecordContext } from "react-admin"
import { useMutation } from 'react-query';
import { useDataProvider } from 'react-admin';
import { API_URL } from "../../utils/constants"
import ApiConstants from "../../api/ApiConstants"
import Refresh from '@mui/icons-material/Refresh';
import {   Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';




export const RefreshIntervention = ({careRecipientId,careProviderId,month,years}) => {
const record = useRecordContext()
const dataProvider = useDataProvider();
const [interventionsPendingLength, setInterventionsPendingLength] = useState(null);
const [interventionsDoneLength, setInterventionsDoneLength] = useState(null);
const [interventionInProgressLength, setInterventionInProgressLength] = useState(null);
const [interventionsCancelledLength, setinterventionsCancelledLength] = useState(null);
const navigate = useNavigate();

const { mutate: getStatusInterventions } = useMutation(

    () => dataProvider.postOnApi(API_URL +  ApiConstants.RERFRESH_PRESENCE_SHEET , {careRecipientId:record.careRecipientId.id,careProviderId:record.careProviderId.id,monthNumber:record.monthNumber, yearNumber:record.yearNumber} ),
    {
        onSuccess: (data) => {
            window.location.reload();
        },
        onError: (error) => {
            console.log(error);
        },
    }
);
useEffect(() => {
},[record]);

return(
    <Box marginTop={1} >
    <Box
        flex={1}
        mr={{ xs: 0, sm: '1em' }}
    >
       <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    width: '90%',
                    margin: 'auto',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Refresh />}
                    onClick={() => getStatusInterventions() }
                >
                    Refresh
                </Button>
            </Box>
    </Box>
    </Box>
)
}