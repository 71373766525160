import * as React from 'react';
import { TableCell, Typography } from '@mui/material';


const TableCellCustom = (props) => {
    return (<TableCell {...props}><Typography color={'#37419A'} 
    style={{
        fontSize: "0.7rem",
        fontWeight: 500,
    }}
    >{props.name}</Typography></TableCell>)
}

export default TableCellCustom;