import {  Error } from "react-admin";
import { API_URL } from "../utils/constants";
import { LOGIN_URL } from "../utils/endpoints";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${API_URL}${LOGIN_URL}`, {
            method: "POST",
            body: JSON.stringify({ email: username, password: password }),
            headers: new Headers({ "Content-Type": "application/json",
            "Acccept": "application/json" }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ tokens,user }) => {
                const { access, refresh } = tokens;
                localStorage.setItem("username", username);
                localStorage.setItem('token', JSON.stringify(access));
                localStorage.setItem('refresh', JSON.stringify(refresh));
                localStorage.setItem('user', JSON.stringify(user));
            });
    },
    logout: () => {
        console.log("logout");
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('refresh');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    getPermissions: () => Promise.reject('Unknown method'),
    checkAuth: () => localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('refresh');
            localStorage.removeItem('user');
            return Promise.reject(status);
        }
        return Promise.resolve();
    },
    
};

export default authProvider;