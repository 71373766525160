import { lightTheme } from './layout/themes';
import { Admin, Resource } from 'react-admin';
import { Dashboard } from './pages/dashboard';
import { Users } from './pages/users';
import { CareProviders } from './pages/careprovider';
import { CareRecepients } from './pages/carerecipient';
import { Interventions } from './pages/intervention';
import authProvider from './authprovider/AuthProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { Login } from './layout';
import { DataProvider } from './dataprovider';
import { customFrenchMessages } from './i18n/fr';
import { useEffect } from 'react';
import { loadGMaps } from './utils/loadMaps';
import { Contracts } from './pages/contracts';
import { Jobs } from './pages/jobs';
import { Presencesheet } from './pages/presencesheet';

const i18nProvider = polyglotI18nProvider(locale => {
  // force French
  if (true) {
    return customFrenchMessages;
  }

  return englishMessages;
}, 'fr')
const App = () => {

  useEffect(() => {
    loadGMaps(() => console.log('Google Maps loaded'));
  }, []);

  return (
    <Admin
      dataProvider={DataProvider}
      dashboard={Dashboard}
      theme={lightTheme}
      loginPage={Login}
      disableTelemetry={true}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
    >
      <Resource name="users"
        {...Users}
        options={{ label: 'Utilisateurs' }}
      />
      <Resource name="care-provider-profiles"
        {...CareProviders}
        options={{ label: 'Auxiliaires' }}
      />
      <Resource name="care-recipient-profiles"
        {...CareRecepients}
        options={{ label: 'Bénéficiaires' }}
      />
      <Resource name="jobs"
        {...Jobs}
        options={{ label: 'Demandes' }}
      />
      <Resource name="interventions"
        {...Interventions}
        options={{ label: 'Interventions' }}
      />
      <Resource name="presence-sheets"
        {...Presencesheet}
        options={{ label: 'Presences' }}
      />
      <Resource name="contracts"
        {...Contracts}
        options={{ label: 'Contrats' }}
      />
      
    </Admin >
  )
}
  ;


export default App;
