import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import { useDataProvider } from 'react-admin';
import * as React from "react";
import { useMutation } from 'react-query';
import ApiConstants from "../../api/ApiConstants"
import { API_URL } from "../../utils/constants"

export const NotificationField = ({ open, visible,setAction,action,id }) => {
    const dataProvider = useDataProvider();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
        borderRadius:2

    };

    const handleChange = (event) => {
        setAction(event.target.value)
      };
    
      const { mutate: postNotification } = useMutation(
        () => dataProvider.postOnApi(API_URL + ApiConstants.NOTIFICATION_ALERT_INTERVENTION.replace("{interventionId}", id),{labelNotication: action}),
        {
            onSuccess: () => {
                visible(false);
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    return (
        <Modal
            open={open}
            onClose={() => visible(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Voulez vous notifier l'auxiliaire ?
                </Typography>
                <TextField
                    label="Message Notification"
                    placeholder="Entrez un message"
                    variant="outlined"
                    multiline={true}
                    value={action}
                    fullWidth
                    onChange={handleChange}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        paddingTop:3
                    }}
                >
                    <Button
                        sx={{
                            color: 'white',
                        }
                        }
                        variant='contained'
                        onClick={() => postNotification()}
                    > Confirmer l'envoie</Button>
                    <Button
                        sx={{
                            color: 'white',
                            backgroundColor: '#d32f2f',
                            '&:hover': {
                                backgroundColor: '#c62828',
                            },
                        }
                        }
                        variant='contained'
                        onClick={() => visible(false)}
                    > Annuler l'envoie</Button>
                </Box>
            </Box>

        </Modal>




    )
}