import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Name } from '../../common/style/style';
import { formatUserFullName } from '../../utils/Stringhelps';


const UserField = () => {
    const record = useRecordContext();
    const getFullName = () => {
        if (!record.user) {
            return '';
        }
        return `${formatUserFullName(record.user)}`;
    };

    const getEmail = () => {
        if (!record.user) {
            return '';
        }
        return `${record.user.email}`;
    };

    const getMobilePhone = () => {
        if (!record.user) {
            return '';
        }
        return `${record.user.mobilePhone}`;
    };
    
        
    return record ? (
        <>
            <Name
                variant="p"
            >
                {getFullName()}
            </Name>

            <Name
                variant="p"
            >
                {getEmail()}
            </Name>

            <Name
                variant="p"
            >
                {getMobilePhone()}
            </Name>
        </>
       
    ) : null;
}

export default UserField;