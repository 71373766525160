
import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Name } from '../../common/style/style';
import { formatUserFullName } from '../../utils/Stringhelps';




export const UserListField = ({ source }) => {

    const record = useRecordContext();
    const users = record[source];
    return (
        <>
        {
            users.map((user) => {
                return (
                    <Name
                        variant="p"
                    >
                        {formatUserFullName(user)}
                    </Name>
                )
            })
        }
        </>
    );
};