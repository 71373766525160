const ApiConstants = {
    GET_UNLINK_USERS: 'users/unlinked/{userRole}',
    GET_PROVIDER_NUMBERS:'kpi/providers/numbers',
    GET_INTERVENTIONS_NUMBERS:'kpi/interventions/numbers',
    GET_RECIPIENT_NUMBERS:'kpi/recipients/numbers',
    GET_PROVIDER_JOBS: 'care-provider-profiles/jobs/matching/{jobId}',
    DELETE_CONTRACT_FUTURE_INTERVIEWS: 'contracts/{contractId}/delete-future-interventions',
    POST_GENRATE_CONTRACT_INTERVIEWS: 'contracts/{contractId}/generate-interventions',
    GET_PROVIDER_AVAILABILITY: 'care-provider-availabilities/{availabilityId}',
    UPDATE_PROVIDER_AVAILABILITY: 'care-provider-availabilities/{availabilityId}',
    GET_PROVIDER_AVAILABILITY_BY_PROVIDER_ID: 'care-provider-availabilities/careProvider/{providerId}',
    NOTIFICATION_ALERT_INTERVENTION:'providers/careprovider/interventionAlert/{interventionId}',
    CHECK_IN_INTERVENTION:'providers/interventions/interventionCheckIn/checkInAdmin',
    CHECK_OUT_INTERVENTION:'providers/interventions/interventionCheckOut/checkOutAdmin',
    GET_NUMBERS_STATUS_INTERVENTION:'kpi/interventions/interventionStatus/status',
    INTERVENTION_LATE:'providers/interventions/interventionLate/late',
    RERFRESH_PRESENCE_SHEET:'presence-sheets/refreshPresenceSheet/refresh'
};

export default ApiConstants;